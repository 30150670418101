// @flow

export const AUTH_TOKEN = 'loyalman_auth_token';
export const USERNAME = 'loyalman_username';
export const USERID = 'loyalman_userid';
export const INF_NOTIFICATIONS = 'loyalman_inf_notifications';
export const LOGOUT_MESSAGE = 'loyalman_logout_message';
export const API_URL_COOKIE = 'loyalman_url';

export const cookiesSetting = { path: '/' };
