import { pipe } from 'rambda';

// export const getDefaultApiUrl = () =>
//   `${window.location.protocol}//${window.location.hostname}:${API_PORT || process.env.API_PORT}`;

export const getDefaultEnvApiUrl = () => `${process.env.API_URL}:${process.env.API_PORT}`;

const prependProtocol = url => (url.startsWith('http') ? url : `http://${url}`);

const trimSlash = url => url.replace(/\/+$/g, '');

export const normalizeUrl = apiUrl =>
  pipe(
    prependProtocol,
    trimSlash
  )(apiUrl);
