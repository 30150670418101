import React from 'react';

const SvgList = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M3.125 8.091c-.52 0-.964.185-1.328.557A1.859 1.859 0 0 0 1.25 10c0 .53.182.982.547 1.353.364.37.807.556 1.328.556.52 0 .964-.185 1.328-.556C4.819 10.982 5 10.53 5 10c0-.53-.182-.98-.547-1.352a1.792 1.792 0 0 0-1.328-.557zm0-5.091c-.52 0-.964.186-1.328.557a1.86 1.86 0 0 0-.547 1.352c0 .53.182.982.547 1.352a1.79 1.79 0 0 0 1.328.557c.52 0 .964-.186 1.328-.557C4.819 5.891 5 5.44 5 4.91c0-.53-.182-.98-.547-1.352A1.794 1.794 0 0 0 3.125 3zm0 10.182c-.52 0-.964.186-1.328.557a1.858 1.858 0 0 0-.547 1.352c0 .531.182.981.547 1.353.364.37.807.556 1.328.556.52 0 .964-.185 1.328-.556.365-.372.547-.822.547-1.353 0-.53-.182-.98-.547-1.352a1.793 1.793 0 0 0-1.328-.557zm15.532-9.45a.297.297 0 0 0-.22-.095H6.563a.298.298 0 0 0-.22.095.307.307 0 0 0-.092.222v1.91c0 .086.031.16.093.223a.3.3 0 0 0 .22.095h11.875a.297.297 0 0 0 .22-.095.306.306 0 0 0 .092-.223v-1.91a.308.308 0 0 0-.093-.223zm-.22 4.996H6.563a.299.299 0 0 0-.22.094.312.312 0 0 0-.092.224v1.909a.31.31 0 0 0 .093.224.299.299 0 0 0 .22.094h11.875a.297.297 0 0 0 .22-.094.307.307 0 0 0 .092-.224v-1.91c0-.085-.03-.16-.093-.223a.298.298 0 0 0-.22-.094zm0 5.09H6.563a.298.298 0 0 0-.22.095.31.31 0 0 0-.092.224v1.909c0 .086.031.16.093.224a.3.3 0 0 0 .22.094h11.875a.298.298 0 0 0 .22-.094.308.308 0 0 0 .092-.224v-1.91c0-.086-.03-.16-.093-.223a.3.3 0 0 0-.22-.095z"
    />
  </svg>
);

export default SvgList;
