import React from 'react';

const SvgFastForward = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M11.5 10c0-.1-.1-.3-.2-.4L4.1 2.4c-.1-.1-.2-.2-.3-.2-.1 0-.3.1-.4.2l-.7.8c-.1.1-.2.2-.2.3 0 .1.1.3.2.4L8.8 10l-6.1 6.1c-.1.1-.2.2-.2.4 0 .1.1.3.2.4l.8.8c.1.1.2.2.4.2.1 0 .3-.1.4-.2l7.2-7.2V10zm5.8-.4l-7.2-7.2c-.1-.1-.2-.2-.3-.2-.1 0-.3.1-.4.2l-.8.8c-.1.1-.1.2-.1.3 0 .1.1.3.2.4l6.1 6.1-6.1 6.1c-.1.1-.2.2-.2.4 0 .1.1.3.2.4l.8.8c.1.1.2.2.4.2.1 0 .3-.1.4-.2l7.2-7.2c.1-.1.2-.2.2-.4-.2-.2-.3-.4-.4-.5z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFastForward;
