import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import QueryClientContext from 'contexts/queryClientContext';
import { pipe, isEmpty, map, pathOr, find } from 'rambda';
import React from 'react';
import { withCookies } from 'react-cookie';
import {
  AUTH_TOKEN,
  USERNAME,
  USERID,
  INF_NOTIFICATIONS,
  cookiesSetting,
  LOGOUT_MESSAGE,
  API_URL_COOKIE
} from 'common/constants';
import { homePath } from 'app/routerPaths';
import { getDefaultEnvApiUrl } from '../utils/apiUrl';

const ErrorMessagesTranslations = [
  { fromBE: 'Signature has expired', sk: 'Boli ste odhlásený kvôli expirácii tokenu' },
  { fromBE: 'Please, enter valid credentials', sk: 'Nesprávne prihlasovacie údaje' },
  { fromBE: 'Unauthorized', sk: 'Nepovolený prístup' }
];

const formulateErrorMessage = message => {
  const outputMessage = find(x => x.fromBE === message, ErrorMessagesTranslations);
  return outputMessage ? outputMessage.sk : message;
};

const logoutUser = cookies => {
  cookies.remove(AUTH_TOKEN);
  cookies.remove(USERNAME);
  cookies.remove(USERID);
  cookies.remove(INF_NOTIFICATIONS);
  cookies.remove(API_URL_COOKIE);
  window.location.href = homePath;
};

const prependProtocol = url => (url.startsWith('http') ? url : `http://${url}`);

const trimSlash = url => url.replace(/\/+$/g, '');

const normalizeUrl = pipe(
  prependProtocol,
  trimSlash
);

const getClient = cookies => {
  const token = cookies.get(AUTH_TOKEN);
  const apiUrl = cookies.get(API_URL_COOKIE) || getDefaultEnvApiUrl() || '';

  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ networkError, graphQLErrors }) => {
        if (networkError) {
          if (networkError.statusCode === 401) {
            const errorMessage = pathOr('', 'response.statusText', networkError);
            cookies.set(LOGOUT_MESSAGE, formulateErrorMessage(errorMessage), cookiesSetting);
            logoutUser(cookies);
          }
        }

        if (!isEmpty(graphQLErrors)) {
          map(er => {
            if (er.code === 'auth-error') {
              cookies.set(LOGOUT_MESSAGE, formulateErrorMessage(er.message), cookiesSetting);
              logoutUser(cookies);
            }
          }, graphQLErrors);
        }
      }),
      new HttpLink({
        uri: `${normalizeUrl(apiUrl)}/graphql/`,
        headers: {
          Authorization: token ? `JWT ${token}` : ''
        }
      })
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network', // used by Query components
        errorPolicy: 'none'
      },
      query: {
        fetchPolicy: 'no-cache', // manual query (via client), cache-and-network is not supportd
        errorPolicy: 'none'
      },
      mutate: {
        errorPolicy: 'none'
      }
    },
    cache: new InMemoryCache({
      // TODO: pri upgrade na apollo 3 je dataIdFromObject deprecated. Treba asi radsej prejst na type policies:
      //  https://www.apollographql.com/docs/react/caching/cache-configuration/
      //
      // Teoreticky by toto mohol byt validny config pre nas problem (rovnake id niektorych itemGroups, ked mame itemGroups z roznych zdrojov):
      // typePolicies: {
      //   ExternalGroupType: {
      //     keyFields: ["id", "source", ["record"]],
      //   },
      // },
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'ExternalGroupType':
            return `ExternalGroupType:${responseObject.id}:${responseObject.source.record}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      }
    })
  });
};

export default withCookies(({ children, cookies }) => (
  <QueryClientContext.Provider value={getClient(cookies)}>{children}</QueryClientContext.Provider>
));
