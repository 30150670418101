import React from 'react';

const SvgImg = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M18.2 15.8H1.8V4.2h16.5v11.6zM3.3 5.7v8.6h13.5V5.7H3.3zm9.4 3c-.2-.2-.3-.5-.3-.8 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.3.1-.6-.1-.8-.3zm2.5 4.7h-11v-1.1l2.2-4.1 3.2 3.4 1.2-2c2 1.7 3.4 3 4.4 3.8z"
      fill="currentColor"
    />
  </svg>
);

export default SvgImg;
