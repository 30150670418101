// @flow

import Text from 'components/Text';
import TextInput from 'components/TextInput';
import * as React from 'react';
import View from 'components/View';
import AutocompleteVendor from 'react-autocomplete';
import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { pathOr } from 'rambda';

type Props = {
  getItemValue: any => string,
  format: any => string,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchItems: string => Promise<[]>,
  // eslint-disable-next-line react/no-unused-prop-types
  onSelect?: () => string,
  placeholder?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  defaultValue?: string,
  icon?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  clearOnSelect?: boolean
};

// Autocomplete must have non-stateless cmp
// eslint-disable-next-line react/prefer-stateless-function
class Item extends React.Component {
  style = ({ highlight }, { components: { Autocomplete } }) => ({
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderBottom: '1px solid',
    cursor: 'default',
    ...Autocomplete.Item.default,
    ...(highlight ? Autocomplete.Item.highlight : null)
  });

  render() {
    const { format, item, highlight, ...events } = this.props;

    return (
      <View style={this.style} highlight={highlight} nativeProps={events}>
        <Text color="inherit">{format(item)}</Text>
      </View>
    );
  }
}

// Autocomplete must have non-stateless comp
// eslint-disable-next-line react/prefer-stateless-function,react/no-multi-comp
class Menu extends React.Component {
  style = (props, { borderRadius, components: { Autocomplete } }) => ({
    position: 'absolute',
    width: '100%',
    top: 49, // fixme
    // top: 41,
    flexDirection: 'column',
    paddingVertical: 5,
    borderRadius: borderRadius.normal,
    zIndex: 2,
    maxHeight: 200,
    overflowY: 'auto',
    ...Autocomplete.Menu
  });

  render() {
    const { children, ...events } = this.props;

    if (!children.length) return null;

    return (
      <View
        style={this.style}
        nativeProps={{
          ...events,
          onClick: e => {
            e.preventDefault();
            e.stopPropagation();
          }
        }}>
        {children}
      </View>
    );
  }
}

const wrapperStyle = { display: 'flex', flex: 1, flexDirection: 'column', position: 'relative' };

const Autocomplete = ({
  placeholder,
  icon,
  getItemValue,
  format,
  onChange,
  onSelectPrivate,
  value,
  items,
  style,
  renderInput = Autocomplete.defaultInput
}: Props) => (
  <AutocompleteVendor
    wrapperStyle={{ ...wrapperStyle, ...style }}
    getItemValue={getItemValue}
    renderInput={props => renderInput({ ...props, placeholder, icon })}
    renderItem={(item, highlight) => (
      <Item item={item} highlight={highlight} format={format} key={getItemValue(item)} />
    )}
    renderMenu={children => <Menu>{children}</Menu>}
    value={value}
    items={items}
    onChange={onChange}
    onSelect={onSelectPrivate}
  />
);

Autocomplete.defaultProps = {
  getItemValue: x => `${x}`
};

Autocomplete.defaultInput = ({ ref, icon, ...props }) => (
  <TextInput nativeRef={ref} {...props} iconRight={icon} />
);

export default compose(
  withState('items', 'setItems', []),
  withState('value', 'setValue', ''),
  withHandlers({
    onChange: ({ setItems, setValue, fetchItems }: Props) => (e, value) => {
      setValue(value);

      fetchItems(value).then(result => {
        setItems(result);
      });
    },
    onSelectPrivate: ({ setValue, setItems, onSelect, format, clearOnSelect }: Props) => (
      value,
      item
    ) => {
      if (clearOnSelect) {
        setItems([]);
        setValue('');
      } else {
        setValue(format(item));
      }

      if (onSelect) {
        onSelect(value, format(item), item);
      }
    }
  }),
  lifecycle({
    componentWillUpdate(nextProps) {
      const { defaultValue, setValue } = this.props;
      if (defaultValue !== nextProps.defaultValue) {
        setValue(nextProps.defaultValue || '');
      }
    },
    componentDidMount() {
      const { defaultValue, setValue, fetchItems } = this.props;
      if (defaultValue) {
        fetchItems(defaultValue).then(result => {
          if (result && result.length === 1) {
            const name = pathOr('', 'node.name', result[0]);
            setValue(name);
          }
        });
      }
    }
  })
)(Autocomplete);
