const currencyMarks = {
  EUR: '€',
  USD: '$'
};

const currencyMarksFromText = {
  Euro: '€',
  Dollar: '$'
};

export const getCurrencyMark = currency =>
  currency && currencyMarks[currency] ? currencyMarks[currency] : currency;

export const getCurrencyMarkFromText = currencyText =>
  currencyText && currencyMarksFromText[currencyText]
    ? currencyMarksFromText[currencyText]
    : currencyText;
