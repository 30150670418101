import * as React from 'react';
import Button from '../Button';
import View from '../View';

const styles = {
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 20px 20px 20px'
  },
  button: {
    flexGrow: 1,
    borderWidth: '1px',
    fontSize: '13px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px !important'
  }
};

const ShortcutsPanel = ({ saveAndCloseCalendar }) => (
  <View style={styles.box}>
    <Button
      scale={-1}
      label="Potvrdiť"
      style={styles.button}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        saveAndCloseCalendar(e);
      }}
      type="button"
    />
  </View>
);

export default ShortcutsPanel;
