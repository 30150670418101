// @flow
import * as React from 'react';
import { Style } from '../types';
import Stylo from './Stylo';

export type Props = {
  style?: Style,
  isVertical?: boolean,
  isExpanded?: boolean
};

const defaultStyle = ({ isVertical, isExpanded }: Props) => ({
  ...(isVertical && { flexDirection: 'column' }),
  ...(isExpanded && { flexGrow: 1 })
});

const View = ({ style, ...props }: Props) => <Stylo {...props} style={[defaultStyle, style]} />;

export default View;
