import React from 'react';

const SvgOpenPopup = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M14.643 4.793H2.635A.633.633 0 0 0 2 5.425v11.943c0 .35.284.632.635.632h12.008a.634.634 0 0 0 .636-.632V5.425a.634.634 0 0 0-.636-.632zm-.635 11.943H3.27V6.056h10.738v10.68zM17.365 2H5.355a.634.634 0 0 0-.635.632v1.335c0 .35.285.632.636.632a.634.634 0 0 0 .636-.632v-.703h10.737v10.68h-.654a.634.634 0 0 0-.636.632c0 .348.284.631.636.631h1.29a.634.634 0 0 0 .635-.631V2.632A.634.634 0 0 0 17.365 2z"
    />
  </svg>
);

export default SvgOpenPopup;
