import React from 'react';

const SvgPercent = () => (
  <svg viewBox="0 0 50 52" width="1em" height="1em">
    <path
      d="M10.9 17.9c0 1.9.6 3.5 1.8 4.6 1.2 1.1 2.8 1.7 4.8 1.7s3.5-.6 4.7-1.7c1.2-1.1 1.8-2.6 1.8-4.5v-1.5c0-2-.6-3.5-1.8-4.6-1.2-1.1-2.8-1.7-4.8-1.7s-3.5.6-4.7 1.7c-1.2 1.2-1.8 2.7-1.8 4.5v1.5zm4-1.4c0-.9.2-1.6.7-2.1.5-.5 1.1-.8 1.8-.8.8 0 1.4.3 1.9.8s.7 1.2.7 2V18c0 .9-.2 1.6-.7 2.1-.5.5-1.1.8-1.8.8-.8 0-1.4-.3-1.9-.8s-.7-1.2-.7-2v-1.6zm10.5 18.4c0 1.9.6 3.4 1.8 4.6 1.2 1.1 2.8 1.7 4.8 1.7s3.6-.6 4.8-1.7c1.2-1.1 1.8-2.6 1.8-4.5v-1.5c0-2-.6-3.5-1.8-4.7-1.2-1.1-2.8-1.7-4.8-1.7-1.9 0-3.5.6-4.7 1.7-1.2 1.1-1.8 2.6-1.8 4.5v1.6zm3.9-1.4c0-.9.2-1.6.7-2.1.5-.5 1.1-.8 1.9-.8.8 0 1.4.3 1.9.8s.7 1.2.7 2V35c0 1.9-.8 2.8-2.5 2.8-.7 0-1.4-.3-1.9-.8s-.7-1.2-.7-2v-1.5zm4.1-18.6l-2.9-1.6L15.7 37l2.9 1.6 14.8-23.7z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPercent;
