// @flow

import Helmet from 'react-helmet';
import View from 'components/View';
import * as React from 'react';

type Props = {
  title: string,
  children: any,
  isDetailPage?: boolean
};

const styles = {
  defaultPage: ({ isDetailPage }, { components: { Page } }) => ({
    height: '100%',
    position: 'relative',
    left: isDetailPage ? -178 : 0,
    width: isDetailPage ? 'calc(100% + 178px)' : undefined,
    ...Page
  }),
  focusPage: (props, { components: { FocusPage } }) => ({
    height: '100%',
    ...FocusPage.page
  })
};

const GenericPage = ({ style, title, isDetailPage, children }) => (
  <View style={style} isDetailPage={isDetailPage}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </View>
);

const Page = (props: Props) => <GenericPage style={styles.defaultPage} {...props} />;

const FocusPage = (props: Props) => <GenericPage style={styles.focusPage} {...props} />;

export { FocusPage };

export default Page;
