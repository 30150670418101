import React from 'react';

const SvgSearch = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M2.237 8.355a6.154 6.154 0 0 1 3.085-5.294 6.021 6.021 0 0 1 3.06-.816h.05a6.093 6.093 0 0 1 5.311 3.085c1.088 1.895 1.054 4.266-.034 6.136l3.842 3.85a.674.674 0 0 1 .212.5.746.746 0 0 1-.212.528l-1.198 1.198a.726.726 0 0 1-.519.212.693.693 0 0 1-.51-.212L11.5 13.709a6.224 6.224 0 0 1-3.102.842h-.05a6.155 6.155 0 0 1-5.303-3.11 5.896 5.896 0 0 1-.808-3.085zm1.564.018c0 2.507 2.04 4.58 4.564 4.606h.034c2.507 0 4.555-2.031 4.58-4.555.025-2.524-2.04-4.59-4.564-4.615h-.034c-2.506 0-4.546 2.023-4.58 4.564z"
    />
  </svg>
);

export default SvgSearch;
