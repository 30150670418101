// @flow
import Text from 'components/Text';
import View from 'components/View';
import React from 'react';
import { SortIndicator } from 'react-virtualized';
import CheckboxInput from 'components/Checkbox';

const styles = {
  box: ({ align, isSortable, indent }, { baseSize }) => ({
    paddingRight: baseSize * 3.5,
    paddingLeft: baseSize * (indent === 'half' ? 1.75 : 3.5),
    justifyContent: align === 'right' ? 'flex-end' : undefined,
    alignItems: 'center',
    height: '100%',
    cursor: isSortable ? 'pointer' : 'default'
  }),
  label: (
    props,
    {
      components: {
        Table: { Header }
      }
    }
  ) => ({
    ...Header.Text,
    '&+svg': Header.SortIndicator
  }),
  checkbox: { justifyContent: 'center', alignItems: 'center', height: '100%' }
};

const HeaderCell = ({ label, showSortIndicator, sortDirection, align, isSortable, indent }) => (
  <View style={styles.box} align={align} isSortable={isSortable} indent={indent}>
    <Text weight="bold" transform="uppercase" style={styles.label} scale={-0.5}>
      {label}
    </Text>

    {isSortable && showSortIndicator ? <SortIndicator sortDirection={sortDirection} /> : null}
  </View>
);

const Left = ({ dataKey, label, sortBy, sortDirection, disableSort, indent }) => (
  <HeaderCell
    label={label}
    sortDirection={sortDirection}
    showSortIndicator={sortBy === dataKey}
    isSortable={!disableSort}
    indent={indent}
  />
);

const Right = ({ dataKey, label, sortBy, sortDirection, disableSort }) => (
  <HeaderCell
    align="right"
    label={label}
    sortDirection={sortDirection}
    showSortIndicator={sortBy === dataKey}
    isSortable={!disableSort}
  />
);

const Checkbox = ({ dataKey, areSelectedAllHandlers, onHeaderCellClickHandler, columnData }) => (
  <View style={styles.checkbox}>
    <CheckboxInput
      accent="secondary"
      checked={areSelectedAllHandlers}
      onChange={event => onHeaderCellClickHandler({ event, dataKey, columnData })}
    />
  </View>
);

const HeaderWithValue = ({ dataKey, label, sortBy, sortDirection, disableSort, columnData }) => (
  <HeaderCell
    align="right"
    label={columnData && columnData.headerValuer ? `${label} ${columnData.headerValuer}` : label}
    sortDirection={sortDirection}
    showSortIndicator={sortBy === dataKey}
    isSortable={!disableSort}
  />
);

const Empty = () => <View />;

export default { Left, Right, Checkbox, Empty, HeaderWithValue };
