import { contains, findIndex, map, reduce, without, pathOr } from 'rambda';
import { assocPath } from 'ramda';
import uri from 'urijs';

export const findSelectedRowIndexes = (id, data) => {
  if (!id) return {};

  if (Array.isArray(id)) {
    return reduce(
      (acc, currentId) => ({
        ...acc,
        ...findSelectedRowIndexes(currentId, data)
      }),
      {},
      id
    );
  }

  const foundIndex = findIndex(obj => (obj.node ? obj.node.id === id : obj.id === id), data);

  return foundIndex !== -1 ? { [foundIndex]: true } : {};
};

export const flatEdges = edges => map(({ node }) => node, edges);

export const flatEdgesWithSubEdges = (edges, subEdges) =>
  map(({ node }) => {
    let output = node;
    map(subEdge => {
      output = assocPath([subEdge], flatEdges(pathOr([], `${subEdge}.edges`, output)), output);
    }, subEdges);
    return output;
  }, edges);

export const flatPureSubEdges = (node, subEdges) => {
  if (!node) return null;
  let output = node;
  map(subEdge => {
    output = assocPath([subEdge], flatEdges(pathOr([], `${subEdge}.edges`, output)), output);
  }, subEdges);
  return output;
};

export const loadMoreRows = ({ fetchMore, variables, name }) => () =>
  fetchMore({
    variables,
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const { __typename, edges: prevEdges = [] } = previousResult[name] || {};
      const { pageInfo, edges: newEdges = [] } = fetchMoreResult[name] || {};

      return newEdges.length
        ? {
            [name]: {
              __typename,
              edges: [...prevEdges, ...newEdges],
              pageInfo
            }
          }
        : previousResult;
    }
  });

// if the parameters should be treated as OR - they must be given in form -> "value1,value2,value3"
export const forORFiltering = variable =>
  Array.isArray(variable) ? variable.toString() : variable;

export const toggleAllRowHandlers = ({ idGetter, redirectToPath, history, multi = [], data }) => ({
  columnData
}) => {
  const isSelectedAllRows = multi.length === columnData.dataLength;
  const newMulti = isSelectedAllRows ? [] : map(idGetter, data);

  history.push(
    `${redirectToPath(newMulti)}?${uri()
      .setQuery('multi', newMulti)
      .query()}`
  );
};

export const selectMultipleRowHandlers = ({
  idGetter,
  redirectToPath,
  multi = [],
  history,
  data
}) => ({ index }) => {
  const multiArr = Array.isArray(multi) ? multi : [multi];

  const id = idGetter(data[index]);
  const newMulti = contains(id, multiArr) ? without([id], multiArr) : [...multiArr, id];

  history.push(
    `${redirectToPath(newMulti)}?${uri()
      .setQuery('multi', newMulti)
      .query()}`
  );
};
