import React from 'react';

const SvgCard = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M1.5 14.909c0 .602.491 1.09 1.094 1.09h14.812c.604 0 1.094-.488 1.094-1.09V8.363h-17v6.546zm10.984-4.364c.422 0 .803.164 1.094.425.29-.261.672-.425 1.094-.425.905 0 1.64.733 1.64 1.637a1.634 1.634 0 1 1-2.734 1.213 1.637 1.637 0 0 1-2.735-1.213 1.64 1.64 0 0 1 1.641-1.637zm-8.25 1.091h2.782a.547.547 0 0 1 0 1.092H4.234a.546.546 0 1 1 0-1.092zM17.406 4H2.594C1.99 4 1.5 4.49 1.5 5.09v1.092h17V5.09c0-.601-.49-1.09-1.094-1.09z"
    />
  </svg>
);

export default SvgCard;
