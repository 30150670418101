import React from 'react';

const SvgCheck = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M8.7 16.8l-6.6-6.7 1.7-1.3 3.8 3c.9-1.1 2-2.2 3.7-3.8 1.7-1.6 3.9-3.3 6.1-4.7l.4.9c-2.8 2.5-5.6 6.2-7.3 9-.7 1.3-1.4 2.5-1.8 3.6z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheck;
