import React from 'react';

const SvgSheet = () => (
  <svg viewBox="0 0 50 52" width="1em" height="1em">
    <path
      d="M45.4 3.6H4.6c-.6 0-1.1.6-1.1 1.2S4 5.9 4.6 5.9h2.5v41.3c0 .4.2.8.6 1 .4.2.8.2 1.2-.1l4.9-3.3 5 3.3c.4.3.9.3 1.3 0l4.9-3.3 5 3.3c.4.3.9.3 1.3 0l4.9-3.3 5 3.3c.2.1.4.2.6.2.2 0 .4 0 .5-.1.4-.2.6-.6.6-1V5.9h2.6c.6 0 1.1-.5 1.1-1.1-.1-.6-.6-1.2-1.2-1.2zm-8.7 38.9c-.4-.3-.9-.3-1.3 0l-4.9 3.3-5-3.3c-.4-.3-.9-.3-1.3 0l-4.9 3.3-5-3.3c-.4-.3-.9-.3-1.3 0L9.4 45V5.9h31.2V45l-3.9-2.5zM26.5 19.6H14.6c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h11.9c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zm-3 6.3c.6 0 1.1-.5 1.1-1.1s-.5-1.1-1.1-1.1h-8.9c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h8.9zm11.9-6.3h-3c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h3c.6 0 1.1-.5 1.1-1.1.1-.6-.5-1.1-1.1-1.1zm0 4h-3c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h3c.6 0 1.1-.5 1.1-1.1s-.5-1.1-1.1-1.1zm-8.9-13.2H14.6c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h11.9c.6 0 1.1-.5 1.1-1.1 0-.5-.5-1.1-1.1-1.1zm-3 6.3c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1h-8.9c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h8.9zm11.9-6.3h-3c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h3c.6 0 1.1-.5 1.1-1.1.1-.5-.5-1.1-1.1-1.1zm0 4h-3c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h3c.6 0 1.1-.5 1.1-1.1.1-.6-.5-1.1-1.1-1.1zm-8.9 14.5H14.6c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h11.9c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zm-3 4h-8.9c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h8.9c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zm11.9-4h-3c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h3c.6 0 1.1-.5 1.1-1.1.1-.6-.5-1.1-1.1-1.1zm0 4h-3c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h3c.6 0 1.1-.5 1.1-1.1.1-.6-.5-1.1-1.1-1.1z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSheet;
