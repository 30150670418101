// @flow

import Page from 'components/Page';
import * as React from 'react';
import gql from 'graphql-tag';
import withQueryService from 'components/hoc/withQueryService';
import { path, pathOr } from 'rambda';
import { withCookies } from 'react-cookie';
import Heading from '../../components/Heading';
import View from '../../components/View';
import type { Style, Theme } from '../../types';
import ScrollView from '../../components/ScrollView';
import BasicInfoWidget from '../MainPage/BasicInfoWidget';
import PriceActionsWidget from '../MainPage/PriceActionsWidget';
import PrepaidProductsWidget from '../MainPage/PrepaidProductsWidget';
import MyCardsWidget from '../MainPage/MyCardsWidget';
import { commonStyles } from '../components/Common';
import { getCurrencyMarkFromText } from '../utils/currencyMarks';

const query = gql`
  query($id: String) {
    users(record: $id) {
      edges {
        node {
          id: record
          name
          surname
          profilePic
          userWallets {
            edges {
              node {
                id: record
                balance
                wallet {
                  name
                  walletType
                  currency {
                    id: record
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
    sales(first: 100) {
      edges {
        node {
          name
          saleTo
        }
      }
    }
    companies(first: 1) {
      edges {
        node {
          record
          name
          currency {
            name
          }
        }
      }
    }
    cards(first: 100) {
      edges {
        node {
          record
          status
          code
          user {
            name
            surname
          }
          holder {
            name
            surname
          }
          userDiscountPackages {
            package {
              name
            }
          }
        }
      }
    }
    products(first: 100) {
      edges {
        node {
          name
          productPrepaymentsUsers {
            expiresAt
            balance
          }
        }
      }
    }
  }
`;

const styles = {
  page: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.page,
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    width: '100%',
    height: '100%'
  }),
  header: {
    width: '100%',
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '10px 10px 30px 10px'
  },
  scrollView: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 970px)': {
      flexWrap: 'wrap'
    }
  },
  dashboardColumn: {
    flexDirection: 'column'
  }
};

const WebPage = withCookies(({ QueryService, cookies }) => (
  <Page title="Web" style={styles.page}>
    <View style={styles.header}>
      <Heading style={commonStyles.primaryHeader} scale={4}>
        Moje výhody
      </Heading>
      <Heading style={commonStyles.secondaryHeader} scale={1}>
        Zlatá karta, Zlatý kredit
      </Heading>
    </View>
    <QueryService
      variables={{ id: pathOr(null, 'cookies.loyalman_userid', cookies) }}
      query={query}>
      {({ loading, error, data, refetch }) => {
        if (loading) return null;
        if (error) return null;

        const companiesSet = path('companies.edges', data) ? data.companies.edges : null;
        const company = companiesSet && companiesSet[0] ? companiesSet[0].node : null;
        const companyCurrencyText = company && company.currency && company.currency.name;
        const currencyMark = getCurrencyMarkFromText(companyCurrencyText);

        const usersSet = pathOr(null, 'users.edges.0.node', data);
        const userWalletsSet = pathOr([], 'userWallets.edges', usersSet);

        const cardsSet = path('cards.edges', data) ? data.cards.edges : null;
        const salesSet = path('sales.edges', data) ? data.sales.edges : null;
        const companyName = company && company.name && company.name;

        const productsSet = path('products.edges', data) ? data.products.edges : null;

        return (
          <ScrollView style={styles.scrollView} suppressScrollX>
            <View style={styles.dashboardColumn}>
              {userWalletsSet &&
                userWalletsSet.length > 0 && (
                  <BasicInfoWidget userWalletsSet={userWalletsSet} currency={currencyMark} />
                )}
              {/* <Spot text={temporaryData.spot.text} action={temporaryData.spot.action} /> */}
              {salesSet &&
                salesSet.length > 0 && (
                  <PriceActionsWidget rows={4} salesSet={salesSet} companyName={companyName} />
                )}
            </View>
            <View style={styles.dashboardColumn}>
              {productsSet &&
                productsSet.length > 0 && <PrepaidProductsWidget productsSet={productsSet} />}
              {cardsSet &&
                cardsSet.length > 0 && <MyCardsWidget cardsSet={cardsSet} refetch={refetch} />}
            </View>
          </ScrollView>
        );
      }}
    </QueryService>
  </Page>
));

export default withQueryService(WebPage);
