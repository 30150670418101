// @flow

import React from 'react';
import FloatAnchor from 'react-float-anchor';
import throttle from 'lodash.throttle';
import { elHasParent } from '../../../utils/dom';
import Popover from '../Popover';
import InactiveBg from '../InactiveBg';
import { commonStyles } from '../Common';

const commonStyle = {
  popover: {
    marginTop: 10,
    boxShadow: 'none'
  }
};

const floatOptionsDefaults = { position: 'bottom', hAlign: 'center' };

const withPopover = ({
  hideOnScroll = true,
  contentStyle = {},
  floatOptions = {}
} = {}) => BaseComponent =>
  class extends React.Component {
    state = {
      isActive: false
    };

    componentDidMount() {
      window.addEventListener('click', this.onGlobalClick);

      if (hideOnScroll) {
        window.addEventListener('scroll', this.onScroll, true);
      }
    }

    componentWillUnmount() {
      window.removeEventListener('click', this.onGlobalClick);

      if (hideOnScroll) {
        window.removeEventListener('scroll', this.onScroll, true);
      }
    }

    // eslint-disable-next-line react/sort-comp
    close = e => {
      if (e) {
        const { target } = e;
        if (target.closest('.filters-prevent-hide')) return;
      }

      const { isActive } = this.state;

      if (isActive) {
        this.setState({ isActive: false });
      }
    };

    onGlobalClick = ({ target }) => {
      const { isActive } = this.state;

      if (isActive) {
        if (!elHasParent(target, this.popover)) {
          this.setState({ isActive: false });
        }
      } else if (elHasParent(target, this.trigger)) {
        this.setState({ isActive: true });
      }
    };

    onScroll = throttle(this.close, 100);

    render() {
      const { Content, showTip, ...props } = this.props;
      const { isActive } = this.state;
      return (
        <FloatAnchor
          options={floatOptions || floatOptionsDefaults}
          zIndex={1}
          anchor={
            <div
              ref={c => {
                this.trigger = c;
              }}>
              <BaseComponent {...props} isActive={isActive} />
            </div>
          }
          float={
            isActive ? (
              <div
                ref={c => {
                  this.popover = c;
                }}>
                <InactiveBg onClose={this.close} style={commonStyles.inactiveBg} />
                <Popover
                  showTip={showTip}
                  Content={Content}
                  style={commonStyle.popover}
                  contentStyle={contentStyle}
                  onClose={this.close}
                />
              </div>
            ) : null
          }
        />
      );
    }
  };

export default withPopover;
