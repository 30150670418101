// @flow
import Text from 'components/Text';
import React from 'react';
import type { Theme } from 'types';

type Props = {
  status: 1 | 2 | 3
};

const defaultStyle = ({ status }, { components: { Status } }: Theme) => ({
  ...Status[status],
  fontSize: 30
});

const Status = ({ status, style }: Props) => (
  <Text style={[defaultStyle, style]} status={status}>
    •
  </Text>
);

export default Status;
