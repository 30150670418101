import React from 'react';

const SvgBarCode = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M3.41 3.5h-.99c-.094 0-.17.085-.17.19v12.62c0 .105.076.19.17.19h.99c.094 0 .17-.085.17-.19V3.69c0-.105-.076-.19-.17-.19zm1.725 0h-.679c-.094 0-.17.085-.17.19v12.62c0 .105.077.19.17.19h.679c.094 0 .17-.085.17-.19V3.69c0-.105-.076-.19-.17-.19zm12.445 0h-.679c-.093 0-.17.085-.17.19v12.62c0 .105.077.19.17.19h.68c.093 0 .169-.085.169-.19V3.69c0-.105-.076-.19-.17-.19zm-1.555 0h-.463c-.093 0-.17.085-.17.19v12.62c0 .105.077.19.17.19h.463c.093 0 .17-.085.17-.19V3.69c0-.105-.077-.19-.17-.19zm-2.972 0h-2.678c-.094 0-.17.085-.17.19v12.62c0 .105.076.19.17.19h2.678c.094 0 .17-.085.17-.19V3.69c0-.105-.076-.19-.17-.19zm-3.647 0h-.424c-.094 0-.17.085-.17.19v12.62c0 .105.076.19.17.19h.424c.093 0 .17-.085.17-.19V3.69c0-.105-.077-.19-.17-.19zm5.321 0h-.262c-.093 0-.17.085-.17.19v12.62c0 .105.077.19.17.19h.262c.094 0 .17-.085.17-.19V3.69c0-.105-.076-.19-.17-.19zm-6.96 0h-1.67c-.094 0-.17.085-.17.19v12.62c0 .105.076.19.17.19h1.67c.093 0 .169-.085.169-.19V3.69c0-.105-.076-.19-.17-.19zm5.892 0h-.062c-.093 0-.17.085-.17.19v12.62c0 .105.077.19.17.19h.062c.094 0 .17-.085.17-.19V3.69c0-.105-.077-.19-.17-.19z"
    />
  </svg>
);

export default SvgBarCode;
