// @flow
import 'focus-visible';
import { getPageDefaultColor, hexToRgbColor } from '../utils/colors';

const baseSize = 4;

const baseLineHeightHeadings = 20;
const baseLineHeightText = 18;

export const focus = '&.focus-visible'; // polyfill for :focus-visible

const addThemeDesigns = theme => {
  if (theme) {
    // eslint-disable-next-line no-param-reassign
    theme.shadows = {
      subtle: `0 0 51px rgba(0, 0, 0, 0.11)`,
      // inset shadow for right side panel
      subtleInsetHack: `51px 0 51px -51px rgba(0, 0, 0, 0.11) inset`,
      normal: `0 0 51px rgba(0, 0, 0, 0.21)`, // for popover
      strong: `0 0 51px rgba(0, 0, 0, 0.31)`,
      strongest: `0 0 34px rgba(0, 0, 0, 0.46)`, // for menu bar
      normalAround: `0 0 10px 2px rgba(231, 231, 231, 1)` // for webmodule
    };
    // eslint-disable-next-line no-param-reassign
    theme.components = {
      Autocomplete: {
        Item: {
          default: {
            color: theme.colors[1][-10],
            borderColor: theme.colors[0][15],
            backgroundColor: theme.colors[0][0]
          },
          highlight: {
            color: theme.colors[0][0],
            backgroundColor: `rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6)`
          }
        },
        Menu: {
          boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6)`,
          backgroundColor: theme.colors[0][0]
        }
      },
      Button: {
        primary: {
          background: `linear-gradient(to right, ${theme.colors[3][0]}, ${theme.colors[3][-10]})`,
          color: theme.colors[0][0],
          ':hover': {
            filter: 'contrast(120%)'
          },
          ':active': {
            filter: 'contrast(140%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[3][0])}, 0.6)`
          }
        },
        secondary: {
          background: `linear-gradient(to right, ${theme.colors[1][-10]},
        ${theme.colors[1][-20]})`,
          color: theme.colors[0][0],
          ':hover': {
            filter: 'contrast(140%)'
          },
          ':active': {
            filter: 'contrast(180%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6)`
          }
        },
        warning: {
          background: `linear-gradient(to right, ${theme.colors[4][0]}, ${theme.colors[4][-10]})`,
          color: theme.colors[0][0],
          ':hover': {
            filter: 'contrast(130%)'
          },
          ':active': {
            filter: 'contrast(160%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[4][0])}, 0.6)`
          }
        },
        danger: {
          background: `linear-gradient(to right, ${theme.colors[2][-10]}, ${theme.colors[2][-20]})`,
          color: theme.colors[0][0],
          ':hover': {
            filter: 'contrast(130%)'
          },
          ':active': {
            filter: 'contrast(170%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[2][-10])}, 0.6)`
          }
        },
        plain: {
          background: theme.colors[0][10],
          color: theme.colors[1][-10],
          ':hover': {
            filter: 'contrast(130%)'
          },
          ':active': {
            filter: 'contrast(170%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          }
        },
        transparent: {
          background: 'transparent',
          color: theme.colors[0][0],
          ':hover': {
            filter: 'contrast(130%)'
          },
          ':active': {
            filter: 'contrast(170%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          }
        }
      },
      CardState: {
        backgroundColor: theme.colors[1][-10],
        color: theme.colors[0][0]
      },
      DetailedTable: {
        backgroundColor: theme.colors[0][10]
      },
      DetailBottomBar: {
        backgroundColor: theme.colors[0][10]
      },
      DetailBottomBarError: {
        backgroundColor: theme.colors[2][-20],
        color: theme.colors[0][10]
      },
      DetailBottomBarItem: {
        normal: {
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6) inset
        !important`
          }
        },
        confirm: {
          background: `linear-gradient(to left, ${theme.colors[3][0]}, ${theme.colors[3][-10]}) ${
            theme.colors[3][-10]
          }`,
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6) inset
        !important`
          }
        }
      },
      DetailContent: {
        backgroundColor: theme.colors[0][0],
        boxShadow: theme.shadows.subtle
      },
      DetailHelp: {
        color: theme.colors[1][-30]
      },
      DetailTopBar: {
        background: `linear-gradient(to right, ${theme.colors[3][-10]}, ${theme.colors[3][0]}) ${
          theme.colors[3][0]
        }`
      },
      DetailTopBarItem: {
        normal: {
          color: theme.colors[0][0],
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6) inset`
          }
        },
        active: {
          color: theme.colors[3][0],
          backgroundColor: `${theme.colors[0][0]} !important` // easy fix for ClickableOpacity override
        },
        back: {
          color: theme.colors[0][0],
          backgroundColor: theme.colors[1][-30],
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][0])}, 0.4) inset`
          },
          ':active': {
            backgroundColor: theme.colors[1][-10]
          }
        }
      },
      Divider: {
        backgroundColor: theme.colors[0][15]
      },
      ErrorCorner: {
        borderColor: `transparent ${theme.colors[2][0]} transparent transparent`
      },
      FileInput: {
        color: theme.colors[1][-10],
        borderColor: theme.colors[1][-10],
        [focus]: {
          boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.5)`
        },
        ':empty': {
          color: `rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
        }
      },
      FilterButton: {
        regular: {
          background: theme.colors[0][0],
          color: theme.colors[1][-10],
          borderColor: theme.colors[1][-10],
          ':hover': {
            filter: 'contrast(94%)'
          },
          ':active': {
            filter: 'contrast(96%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          }
        },
        active: {
          background: theme.colors[1][-10],
          color: theme.colors[0][0],
          borderColor: theme.colors[1][-10],
          ':hover': {
            filter: 'contrast(94%)'
          },
          ':active': {
            filter: 'contrast(96%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          }
        },
        selected: {
          background: `linear-gradient(to right, ${theme.colors[3][0]}, ${theme.colors[3][-10]})`,
          color: theme.colors[0][0],
          borderColor: theme.colors[3][1]
        }
      },
      FocusBody: {
        backgroundColor: theme.colors[0][0],
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)'
      },
      FocusPage: {
        page: {
          backgroundColor: theme.colors[1][-30]
        },
        text: {
          color: theme.colors[0][0]
        },
        subText: {
          color: theme.colors[0][30]
        }
      },
      GroupedList: {
        Header: {
          color: theme.colors[0][30],
          backgroundColor: theme.colors[0][10]
        },
        Item: {
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3) inset`
          }
        }
      },
      Link: {
        color: theme.colors[1][-10],
        ':hover': {
          color: theme.colors[0][30]
        },
        ':active': {
          color: theme.colors[1][-30]
        },
        [focus]: {
          boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.7)`
        }
      },
      Login: {
        error: {
          color: theme.colors[2][0]
        }
      },
      Popover: {
        body: {
          backgroundColor: 'transparent',
          boxShadow: theme.shadows.normal
        },
        tip: {
          borderLeftColor: 'transparent',
          borderRightColor: 'transparent',
          borderBottomColor: theme.colors[0][0]
        },
        content: {
          backgroundColor: theme.colors[0][0]
        },
        closeButton: {
          backgroundColor: theme.colors[2][1],
          color: theme.colors[0][0]
        }
      },
      MenuItem: {
        regular: {
          backgroundColor: theme.colors[1][-20],
          color: theme.colors[0][0],
          ':hover': {
            filter: 'contrast(140%)'
          },
          ':active': {
            filter: 'contrast(180%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.6)`
          }
        },
        active: {
          backgroundColor: theme.colors[0][10],
          color: theme.colors[1][-10],
          ':hover': {
            filter: 'contrast(130%)'
          },
          ':active': {
            filter: 'contrast(170%)'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          }
        }
      },
      Counter: {
        backgroundColor: theme.colors[0][10],
        color: theme.colors[1][-10]
      },
      Checkbox: {
        primary: {
          backgroundColor: theme.colors[0][10],
          borderColor: theme.colors[0][25],
          ':checked': {
            color: theme.colors[0][0],
            backgroundColor: theme.colors[3][0],
            borderColor: theme.colors[3][0]
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[3][0])}, 0.4)`
          }
        },
        secondary: {
          backgroundColor: theme.colors[0][10],
          borderColor: theme.colors[0][25],
          ':checked': {
            color: theme.colors[1][-20],
            backgroundColor: theme.colors[0][20]
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          }
        },
        ':disabled': {
          opacity: 0.5
        }
      },
      DangerousMessage: {
        backgroundColor: theme.colors[2][-10],
        color: theme.colors[0][0]
      },
      Jumbotron: {
        backgroundColor: theme.colors[0][0]
      },
      JumbotronHeading: {
        background: `linear-gradient(to right, ${theme.colors[2][-15]}, ${theme.colors[2][-10]})`,
        color: theme.colors[0][0]
      },
      MainPage: {
        page: {
          backgroundColor: theme.colors[0][0]
        },
        popup: {
          tableHeader: {
            backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`
          }
        },
        primaryHeader: {
          color: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        primarySubHeader: {
          color: `rgba(${hexToRgbColor(theme.defaultColor)},0.75)`
        },
        secondaryHeader: {
          color: theme.colors[2][1]
        },
        tertiaryHeader: {
          color: theme.colors[2][1]
        },
        basicInfoWidgetDivider: {
          backgroundColor: theme.colors[0][5]
        },
        prepaidProductsWidgetDivider: {
          backgroundColor: theme.colors[0][5]
        },
        links: {
          color: `rgba(${hexToRgbColor(theme.defaultColor)})`,
          fontSize: 12,
          cursor: 'pointer',
          ':hover': {
            opacity: '0.7'
          }
        },
        linksDivider: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        inactiveBg: {
          backgroundColor: `rgba(${hexToRgbColor(theme.colors[0][0])},0.75)`
        },
        spot: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        spotButton: {
          backgroundColor: theme.colors[0][0],
          color: `rgba(${hexToRgbColor(theme.defaultColor)})`,
          cursor: 'pointer',
          ':hover': {
            opacity: '0.7'
          }
        },
        actualPriceActionsItemValue: {
          color: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        newCardButton: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`,
          color: theme.colors[0][0],
          cursor: 'pointer',
          ':hover': {
            opacity: '0.7'
          }
        }
      },
      MainPagePopupContent: {
        backgroundColor: `rgba(${hexToRgbColor(theme.colors[0][0])})`,
        boxShadow: theme.shadows.normal
      },
      MainPagePopupContentHeader: {
        body: {
          backgroundColor: `transparent`
        },
        titles: {
          backgroundColor: 'transparent'
        },
        titleText: {
          color: `rgba(${hexToRgbColor(theme.colors[2][1])})`
        },
        titleSubText: {
          color: `rgba(${hexToRgbColor(theme.colors[2][1])},0.75)`
        },
        closeButton: {
          backgroundColor: `rgba(${hexToRgbColor(theme.colors[2][1])})`,
          color: `rgba(${hexToRgbColor(theme.colors[0][0])})`
        }
      },
      MyTransactionsContent: {
        backgroundColor: `rgba(${hexToRgbColor(theme.colors[0][0])})`
      },
      MyTransactionsTableHeader: {
        backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`,
        color: `rgba(${hexToRgbColor(theme.colors[0][0])})`
      },
      myTransactionsTableHeaderText: {
        color: `rgba(${hexToRgbColor(theme.colors[0][0])})`
      },
      ProfileHeader: {
        backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`,
        color: `rgba(${hexToRgbColor(theme.colors[0][0])})`
      },
      ProfileHeaderText: {
        color: `rgba(${hexToRgbColor(theme.colors[0][0])})`
      },
      Page: {
        backgroundColor: theme.colors[0][10]
      },
      Radio: {
        Jumbo: {
          default: {
            backgroundColor: theme.colors[0][10],
            color: theme.colors[1][-10],
            borderColor: theme.colors[0][25]
          },
          checked: {
            backgroundColor: theme.colors[3][0],
            color: theme.colors[0][0],
            borderColor: 'transparent'
          }
        }
      },
      SaleAltHeader: {
        color: theme.colors[0][30]
      },
      SectionHeading: {
        color: theme.colors[2][-10]
      },
      Select: {
        color: theme.colors[1][-10],
        [focus]: {
          boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.5)`
        },
        ':disabled': {
          color: `rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.5)`
        }
      },
      Sidebar: {
        sidebar: {
          backgroundColor: `rgba(${hexToRgbColor(theme.colors[0][0])})`
        },
        userPhotoPart: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        cardPart: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)},0.75)`
        },
        linksPart: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        menuPart: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)})`
        },
        bottomPart: {
          backgroundColor: `rgba(${hexToRgbColor(theme.defaultColor)},0.75)`
        },
        userName: {
          color: theme.colors[0][0]
        },
        texts: {
          color: theme.colors[0][0]
        },
        divider: {
          backgroundColor: `rgba(${hexToRgbColor(theme.colors[0][0])},0.25)`
        },
        ordersLink: {
          color: theme.colors[0][0]
        },
        menuLink: {
          color: theme.colors[0][0]
        },
        bottomPartLink: {
          color: `rgba(${hexToRgbColor(theme.colors[0][0])},0.75)`
        }
      },
      Status: {
        1: {
          color: theme.colors[1][-10]
        },
        2: {
          color: theme.colors[2][-10]
        },
        3: {
          color: theme.colors[3][-10]
        }
      },
      Switch: {
        on: {
          backgroundColor: theme.colors[3][0]
        },
        off: {
          backgroundColor: theme.colors[2][-10]
        }
      },
      Table: {
        Header: {
          Text: {
            color: theme.colors[0][30]
          },
          SortIndicator: {
            fill: theme.colors[1][-30]
          }
        },
        Body: {
          Row: {
            backgroundColor: theme.colors[0][0],
            color: theme.colors[1][-10],
            [focus]: {
              boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(
                theme.colors[1][-10]
              )}, 0.3) inset`
            }
          },
          AlternateRow: {
            backgroundColor: theme.colors[0][10]
          },
          BorderedRow: {
            borderColor: theme.colors[0][10]
          },
          SelectedRow: {
            backgroundColor: theme.colors[1][-10],
            borderColor: theme.colors[1][-20],
            color: theme.colors[0][0],
            [focus]: {
              boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][0])}, 0.3) inset`
            }
          }
        }
      },
      TableFilters: {
        backgroundColor: theme.colors[0][20],
        '&:before': {
          background: `linear-gradient(to right, ${theme.colors[0][20]}, rgba(223, 224, 226, 0))`
        },
        '&:after': {
          background: `linear-gradient(to left, ${theme.colors[0][20]}, rgba(223, 224, 226, 0))`
        }
      },
      TagInput: {
        Wrapper: {
          color: theme.colors[1][-10],
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.5)`
          }
        },
        Tag: {
          backgroundColor: theme.colors[0][15]
        }
      },
      Text: {
        color: theme.colors[1][-10]
      },
      TextInput: {
        base: {
          color: theme.colors[1][-10],
          '::placeholder': {
            color: `rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.3)`
          },
          ':disabled': {
            color: `rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.5)`
          },
          ':disabled::placeholder': {
            color: 'transparent'
          },
          [focus]: {
            boxShadow: `0 0 0 ${baseSize}px rgba(${hexToRgbColor(theme.colors[1][-10])}, 0.5)`
          }
          // bad for auth (imho)
          // ':-webkit-autofill': {
          //   '-webkit-text-fill-color': `${theme.colors[1][-10]} !important`,
          //   transition: 'background-color 5000s ease-in-out 0s'
          // }
        }
      },
      Tag: {
        primary: {
          background: theme.colors[3][0],
          color: theme.colors[0][0]
        },
        secondary: {
          background: theme.colors[1][-10],
          color: theme.colors[0][0]
        },
        plain: {
          background: theme.colors[0][10],
          color: theme.colors[1][-10]
        }
      },
      TopTitle: {
        backgroundColor: theme.colors[0][0]
      },
      ValidationError: {
        color: theme.colors[2][0]
      }
    };
  }
};

const defaultTheme = {
  baseSize,
  defaultColor: getPageDefaultColor(),
  typo: {
    'PT Sans Caption': {
      rhythm: baseLineHeightHeadings,
      fontSize: {
        '-1': 10,
        '-0.5': 12,
        0: 13,
        1: 15,
        1.5: 16,
        2: 18,
        3: 21,
        4: 31
      },
      lineHeight: {
        '-1': `${baseLineHeightHeadings / 1.25}px`,
        '-0.5': `${baseLineHeightHeadings}px`,
        0: `${baseLineHeightHeadings}px`,
        1: `${baseLineHeightHeadings * 1.25}px`,
        1.5: `${baseLineHeightHeadings * 1.5}px`,
        2: `${baseLineHeightHeadings * 1.75}px`,
        3: `${baseLineHeightHeadings * 1.75}px`
      },
      weight: {
        regular: 400,
        bold: 700
      }
    },
    Roboto: {
      rhythm: baseLineHeightText,
      fontSize: {
        '-2.5': 8,
        '-2': 9,
        '-1.5': 10,
        '-1': 11,
        '-0.5': 12,
        0: 13,
        0.5: 14,
        1: 15,
        2: 18,
        3: 20,
        4: 23
      },
      lineHeight: {
        '-1.5': `${baseLineHeightText * 0.75}px`,
        '-1': `14px`,
        '-0.5': `17px`,
        0: `${baseLineHeightText}px`,
        0.5: `21px`,
        1: `${baseLineHeightText * 1.25}px`,
        2: `${baseLineHeightText * 1.55}px`,
        4: `${baseLineHeightText * 1.75}px`,
        8: `${baseLineHeightText * 3}px`,
        10: `${baseLineHeightText * 4}px` // icon need this
      },
      weight: {
        regular: 400,
        bold: 700
      }
    },
    monospace: {
      rhythm: baseLineHeightText,
      fontSize: {
        0: 13
      },
      lineHeight: {
        0: `${baseLineHeightText}px`
      },
      weight: {
        regular: 'normal',
        bold: 'bold'
      }
    }
  },
  borderRadius: {
    normal: 5,
    bigger: 7
  },
  colors: {
    0: {
      0: '#ffffff',
      5: '#E7E7E7',
      10: '#f7f7f7',
      15: '#eee',
      20: '#dfe0e2',
      25: '#a8a8a8',
      30: '#909090'
    },
    1: {
      '-30': '#707070',
      '-20': '#6c6c6c',
      '-10': '#595959',
      0: '#000000'
    },
    2: {
      '-20': '#f25c5c',
      '-15': '#ea4c4c',
      '-10': '#e34444',
      0: '#dd4242',
      1: '#464C50'
    },
    3: {
      '-20': '#7bc67f',
      '-10': '#6bba6f',
      0: '#53a857',
      1: '#3b8140'
    },
    4: {
      '-10': '#ffa845',
      0: '#f6891e'
    },
    5: {
      '-20': '#C3DAEF',
      '-15': '#91C1E4',
      '-10': '#4d99d5',
      0: '#3F8DD1'
    }
  },

  shadows: {},

  components: {}
};

addThemeDesigns(defaultTheme);

export default defaultTheme;

export const coloredTheme = color => {
  defaultTheme.defaultColor = color;

  addThemeDesigns(defaultTheme);
  return defaultTheme;
};
