// @flow

import * as React from 'react';
import View from '../../components/View';
import Text from '../../components/Text';
import Button from '../../components/Button';
import TransactionsTable from './TransactionsTable';
import ProfileData from './ProfileData';

type Props = {
  title: string,
  subtitle?: string,
  params?: {}
};

const commonStyles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3000,
    minWidth: '300px',
    flexGrow: 1
  },
  content: (props, { components: { MainPagePopupContent } }) => ({
    ...MainPagePopupContent,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '300px',
    minHeight: '100px',
    flexGrow: 1
  }),
  header: (props, { components: { MainPagePopupContentHeader } }) => ({
    ...MainPagePopupContentHeader.body,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px'
  }),
  titles: (props, { components: { MainPagePopupContentHeader } }) => ({
    ...MainPagePopupContentHeader.titles,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  }),
  titleText: (props, { components: { MainPagePopupContentHeader } }) => ({
    ...MainPagePopupContentHeader.titleText,
    fontSize: 18
  }),
  titleSubText: (props, { baseSize, components: { MainPagePopupContentHeader } }) => ({
    ...MainPagePopupContentHeader.titleSubText,
    marginTop: -baseSize
  }),
  closeButtonWrapper: {
    alignSelf: 'flex-start'
  },
  closeButton: (props, { components: { MainPagePopupContentHeader } }) => ({
    ...MainPagePopupContentHeader.closeButton
  })
};

const styles = {
  myTransactionsTableWrapper: {
    minWidth: '750px',
    padding: '25px',
    display: 'flex',
    flex: '1 0'
  },
  myTransactionsTableHeader: (props, { components: { MyTransactionsTableHeader } }) => ({
    ...MyTransactionsTableHeader,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '15px'
  }),
  myTransactionsTableHeaderText: (props, { components: { myTransactionsTableHeaderText } }) => ({
    ...myTransactionsTableHeaderText
  }),
  profileDataWrapper: {
    width: '500px',
    // padding: '25px',
    display: 'flex',
    flex: '1 0'
  },
  profileHeader: (props, { components: { ProfileHeader } }) => ({
    ...ProfileHeader,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '15px'
  }),
  profileHeaderText: (props, { components: { ProfileHeaderText } }) => ({
    ...ProfileHeaderText
  })
};

const Header = ({ onClose, title, subTitle }) => (
  <View isVertical style={commonStyles.header}>
    <View style={commonStyles.titles}>
      <View>
        <Text style={commonStyles.titleText} scale={1} fontFamily="PT Sans Caption" weight="bold">
          {title}
        </Text>
      </View>
      <Text style={commonStyles.titleSubText} scale={-0.5} weight="bold">
        {subTitle}
      </Text>
    </View>
    {onClose ? (
      <View style={commonStyles.closeButtonWrapper}>
        <Button
          icon="checkbox"
          accent="plain"
          scale={-0.5}
          onClick={onClose}
          style={commonStyles.closeButton}
        />
      </View>
    ) : null}
  </View>
);

const GenericContent = ({ title, subtitle, children, onClose }) => (
  <View style={commonStyles.body}>
    <Header onClose={onClose} title={title} subTitle={subtitle} />
    <View style={commonStyles.content}>{children}</View>
  </View>
);

const MyTransactionContent = (props: Props) => {
  const { params } = props;
  return (
    <GenericContent {...props}>
      <View style={styles.myTransactionsTableHeader}>
        <Text transform="uppercase" weight="bold" style={styles.myTransactionsTableHeaderText}>
          {params && params.userWalletName}
        </Text>
      </View>
      <View style={styles.myTransactionsTableWrapper}>
        <TransactionsTable userWalletId={params && params.userWalletId} />
      </View>
    </GenericContent>
  );
};

const MyDrawingPossibilitiesContent = (props: Props) => (
  <GenericContent {...props}>
    <div style={{ backgroundColor: 'blue' }}>CERPANIE</div>
  </GenericContent>
);

const MyProfileContent = (props: Props) => (
  <GenericContent {...props}>
    <View style={styles.profileHeader}>
      <Text transform="uppercase" weight="bold" style={styles.profileHeaderText}>
        {'Základné údaje'}
      </Text>
    </View>
    <View style={styles.profileDataWrapper}>
      <ProfileData {...props} />
    </View>
  </GenericContent>
);

export { MyTransactionContent, MyDrawingPossibilitiesContent, MyProfileContent };
