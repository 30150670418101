import * as React from 'react';
import TimeInput from '../TimeInput';
import View from '../View';

const styles = {
  box: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
    marginBottom: 20
  }
};

class TimePanel extends React.Component {
  constructor(props) {
    super(props);

    const { startTime = '00:00', endTime = '23:59' } = this.props;

    this.state = { startTime, endTime };
  }

  changeTimes = times => {
    this.setState(times);
  };

  propagateValues = () => {
    const { commitChanges } = this.props;

    if (commitChanges) {
      commitChanges(this.state);
    }
  };

  getTimes = () => this.state;

  render() {
    const { startTime, endTime } = this.state;
    return (
      <View style={styles.box}>
        <TimeInput
          value={startTime}
          onChange={(event, value) => this.changeTimes({ startTime: value })}
          onBlur={this.propagateValues}
        />
        <TimeInput
          value={endTime}
          onChange={(event, value) => this.changeTimes({ endTime: value })}
          onBlur={this.propagateValues}
        />
      </View>
    );
  }
}

export default TimePanel;
