// @flow
import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { Style } from 'types';
import View from './View';

type Props = {
  children: any,
  style: Style,
  suppressScrollX?: boolean,
  suppressScrollY?: boolean
};

const ScrollView = ({ children, style, suppressScrollX, suppressScrollY }: Props) => (
  <PerfectScrollbar option={{ suppressScrollX, suppressScrollY }}>
    <View style={style}>{children}</View>
  </PerfectScrollbar>
);

export default ScrollView;
