// @flow

import FormRow from 'components/FormRow';
import withQueryClient from 'components/hoc/withQueryClientService';
import { TextInputForm } from 'components/TextInput';
import View from 'components/View';
import { Form, withFormik } from 'formik';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { withCookies } from 'react-cookie';
import { path } from 'rambda';
import { DateForm } from '../../components/Date';
import { CheckboxForm } from '../components/Checkbox';
import Button from '../../components/Button';
import { cookiesSetting, INF_NOTIFICATIONS, USERID } from '../../common/constants';
import { serverInternalError, serverValidationErrors } from '../../utils/forms';
import CountriesSearch from '../../components/CountriesSearch';

const initialValues = {
  name: '',
  surname: '',
  bornAt: '',
  countryId: '',
  email: '',
  phoneNumber: '',
  address: '',
  infMail: [],
  infNotifications: [],
  password: '',
  passwordConfirmation: ''
};

const query = gql`
  query($userId: String!) {
    users(record: $userId) {
      edges {
        node {
          id: record
          status
          name
          surname
          bornAt
          countryId
          email
          phoneNumber
          address
          infMail
        }
      }
    }
  }
`;

const mutation = gql`
  mutation($user: UserInput!) {
    user(data: $user) {
      user {
        id: record
      }
    }
  }
`;

const onSubmit = (
  {
    tools: { client },
    id,
    status,
    name,
    surname,
    bornAt,
    countryId,
    email,
    phoneNumber,
    address,
    infMail,
    infNotifications,
    onClose,
    password
  },
  { setSubmitting, setStatus, props: { cookies } }
) => {
  const variables = {
    user: {
      record: id,
      status,
      name,
      surname,
      bornAt: bornAt && bornAt.date ? bornAt.date.format('YYYY-MM-DD') : null,
      countryId,
      email,
      phoneNumber,
      address,
      infMail: infMail.includes('infMail'),
      password
    }
  };

  const infNotificationsBooleanValue = infNotifications.includes('infNotifications');

  client
    .mutate({
      mutation,
      variables
    })
    .then(() => {
      onClose();
      setTimeout(() => {
        const infNotificationsValue = infNotificationsBooleanValue || false;
        cookies.set(INF_NOTIFICATIONS, infNotificationsValue, cookiesSetting);
      }, 1000);
    })
    .catch(e => {
      setSubmitting(false); // because isSubmitting needs this
      const errors = serverValidationErrors(e) || {};
      errors.__submitError = serverInternalError(e) || 'Formulár sa nepodarilo uložiť';

      setStatus(errors);
    });
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Povinný údaj'),
  surname: Yup.string().required('Povinný údaj'),
  email: Yup.string().required('Povinný údaj'),
  phoneNumber: Yup.string().required('Povinný údaj'),
  address: Yup.string().required('Povinný údaj'),
  countryId: Yup.string().required('Povinný údaj'),
  passwordConfirmation: Yup.string().test('passwords-match', 'Heslá sa musia zhodovať', function(
    value
  ) {
    return this.parent.password === value;
  })
});

const styles = {
  rowStyle: {
    display: 'flex',
    width: '420px',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
};

const ProfileData = ({ setStatus, client, cookies, values, setValues }) => {
  const [showCountries, setShowCountries] = useState(false);

  const loadProfileData = () => {
    const variables = { userId: cookies.get(USERID) };

    client
      .query({ query, variables })
      .then(({ data }) => {
        const user = path('users.edges.0.node', data);

        const loadedData = {
          ...user,
          bornAt: user.bornAt || '',
          countryId: user.countryId || '',
          infMail: user.infMail ? ['infMail'] : [],
          // infNotifications: user.infNotifications ? ['infNotifications'] : []
          infNotifications: cookies.get(INF_NOTIFICATIONS) === 'true' ? ['infNotifications'] : []
        };
        setValues({ ...values, ...loadedData });
        setShowCountries(true);
        setStatus({ loading: false });
      })
      .catch(err => setStatus({ error: err.message }));
  };

  useEffect(() => {
    loadProfileData();
  }, []);

  return (
    <Form>
      <View
        style={{
          maxHeight: '80vh',
          overflowY: 'auto'
        }}>
        <View
          isExpanded
          isVertical
          style={{
            paddingHorizontal: 40,
            paddingVertical: 10,
            alignItems: 'center',
            width: '100%'
          }}>
          <FormRow>
            <View style={styles.rowStyle}>
              <TextInputForm
                name="name"
                label="Meno"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start' }}
                style={{ width: '200px' }}
                isVertical
              />
              <View style={{ width: '20px' }} />
              <TextInputForm
                name="surname"
                label="Priezvisko"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start' }}
                style={{ width: '200px' }}
                isVertical
              />
            </View>
          </FormRow>
          <FormRow>
            <View style={styles.rowStyle}>
              <View style={{ width: 200 }}>
                <DateForm name="bornAt" isVertical label="Dátum narodenia" width={200} />
              </View>
              <View style={{ width: '20px' }} />
              {showCountries && (
                <View style={{ width: '200px' }}>
                  <CountriesSearch values={values} />
                </View>
              )}
            </View>
          </FormRow>
          <FormRow>
            <View style={styles.rowStyle}>
              <TextInputForm
                name="email"
                label="Email"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start' }}
                style={{ width: '200px' }}
                isVertical
              />
              <View style={{ width: '20px' }} />
              <TextInputForm
                name="phoneNumber"
                label="Telefón"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start' }}
                style={{ width: '200px' }}
                isVertical
              />
            </View>
          </FormRow>
          <FormRow>
            <View style={styles.rowStyle}>
              <TextInputForm
                name="address"
                label="Adresa"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}
                style={{ width: '420px' }}
                isVertical
              />
            </View>
          </FormRow>
          <FormRow>
            <View style={styles.rowStyle}>
              <TextInputForm
                name="password"
                type="password"
                label="Heslo"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start' }}
                style={{ width: '200px' }}
                isVertical
              />
              <View style={{ width: '20px' }} />
              <TextInputForm
                name="passwordConfirmation"
                type="password"
                label="Potvrdenie Hesla"
                styleBox={{ flexDirection: 'column', alignItems: 'flex-start' }}
                style={{ width: '200px' }}
                isVertical
              />
            </View>
          </FormRow>
          <FormRow>
            <View style={styles.rowStyle}>
              <CheckboxForm
                name="infMail"
                value="infMail"
                label="Súhlasím so zasielaním našich akcií na email"
              />
            </View>
          </FormRow>
          <FormRow>
            <View style={styles.rowStyle}>
              <CheckboxForm
                name="infNotifications"
                value="infNotifications"
                label="Súhlasím so zasielaním e-mailových notifikácii na udalosti súvisiace mojim účtom"
              />
            </View>
          </FormRow>
          <FormRow>
            <Button label="Uložiť" icon="check" scale={-0.5} accent="secondary" />
          </FormRow>
        </View>
      </View>
    </Form>
  );
};

export default compose(
  withQueryClient,
  withRouter,
  withCookies,
  withFormik({
    mapPropsToValues: ({ client, history: { push }, onClose }) => ({
      tools: {
        client,
        push
      },
      ...initialValues,
      onClose
    }),
    handleSubmit: onSubmit,
    validationSchema
  })
)(ProfileData);
