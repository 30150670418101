// @flow

import Text, { getLineHeight } from 'components/Text';
import * as React from 'react';
import type { Style } from 'types';

type Props = {
  children: string,
  style?: Style
};

const styles = {
  forInput: ({ width = 150, marginTop }) => (props, theme) => ({
    width,
    marginTop,
    alignSelf: 'flex-start',
    flexShrink: 0,
    paddingRight: 10,
    lineHeight: getLineHeight({ scale: 1 }, theme)
  })
};

const Label = ({ children, style }: Props) => (
  <Text transform="uppercase" scale={-0.5} weight="bold" style={style} showEllipsis>
    {children}
  </Text>
);

const LabelForInput = ({ children, width, noMarginTop }) => (
  <Label style={styles.forInput({ width, marginTop: noMarginTop ? 0 : 10 })}>{children}</Label>
);

export { LabelForInput };

export default Label;
