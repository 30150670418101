// @flow

const defaultColor = '#3F8DD1';

export const getPageDefaultColor = () => defaultColor;

export const hexToRgbColor = hex =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));
