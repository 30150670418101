import snakeCase from 'lodash.snakecase';
import withQueryParams from 'react-router-query-params';
import { withProps, compose } from 'recompose';
import uri from 'urijs';

const sortableTableProps = ({ defaultSortBy }) =>
  compose(
    withQueryParams(),
    withProps(({ history, location, queryParams: { sortBy = defaultSortBy } }) => ({
      orderBy: (sortBy[0] === '-' ? '-' : '') + snakeCase(sortBy), // for graphql query
      tableSorting: {
        sort: ({ sortBy: newSortBy, sortDirection }) => {
          const param = `${sortDirection === 'DESC' ? '-' : ''}${newSortBy}`;
          history.push(
            `${location.pathname}?${uri()
              .setQuery('sortBy', param)
              .query()}`
          );
        },
        sortBy: sortBy[0] === '-' ? sortBy.slice(1) : sortBy,
        sortDirection: sortBy[0] === '-' ? 'DESC' : 'ASC'
      }
    }))
  );

export default sortableTableProps;
