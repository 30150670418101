import React from 'react';

const SvgInfo = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M10 2.25c-4.273 0-7.75 3.476-7.75 7.75s3.477 7.75 7.75 7.75 7.75-3.477 7.75-7.75S14.273 2.25 10 2.25zm0 14.546c-3.748 0-6.796-3.049-6.796-6.796S6.252 3.204 10 3.204 16.796 6.252 16.796 10 13.748 16.796 10 16.796zm.124-10.684a.798.798 0 1 0 0 1.595.798.798 0 0 0 0-1.595zm-.21 2.896a.477.477 0 0 0-.477.477v4.054a.477.477 0 0 0 .953 0V9.485a.477.477 0 0 0-.476-.476z"
    />
  </svg>
);

export default SvgInfo;
