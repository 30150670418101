import React from 'react';
import { Provider as Fela, ThemeProvider } from 'react-fela';
import createRenderer from './configureFela';

const renderer = createRenderer();

export default ({ defaultTheme, children }) => (
  <Fela renderer={renderer}>
    <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
  </Fela>
);
