// @flow
import React from 'react';
import Stylo from './Stylo';

type Props = {
  cursor?: string,
  onClick?: React.MouseEventHandler,
  disabled?: boolean,
  children: React.ReactNode,
  tabIndex?: number,
  type?: 'submit' | 'button'
};

const style = ({ cursor }) => ({
  cursor,
  userSelect: 'none',
  color: 'inherit',
  backgroundColor: 'transparent'
});

export const ClickableOpacity = ({
  cursor,
  onClick,
  disabled,
  children,
  tabIndex,
  type,
  ...restProps
}: Props) => (
  <Stylo
    {...restProps}
    as="button"
    cursor={cursor}
    style={style}
    disabled={disabled}
    nativeProps={{ type, onClick, disabled, tabIndex: disabled ? -1 : tabIndex }}>
    {children}
  </Stylo>
);

ClickableOpacity.defaultProps = {
  cursor: 'pointer',
  tabIndex: 0
};

export default ClickableOpacity;
