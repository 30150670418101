import React from 'react';

const SvgBonus = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M7.8 12.6l2.9.9v.1c-.1.2-.2.3-.5.4-1 .1-2.5 0-2.6 0-.2 0-.4.2-.5.4 0 .3.2.5.4.5.1 0 1.7.1 2.7-.1 1-.2 1.3-1.1 1.3-1.7 0-.2-.1-.3-.3-.4l-3.2-1c-1.6-.5-3.3.4-3.4.5l-3.2 2c-.2.1-.3.4-.1.6.1.1.2.2.4.2.1 0 .2 0 .2-.1l3.2-2c.1 0 1.5-.7 2.7-.3zm10.9-.8c-.3-.5-1-1.3-2.2-.7-1.1.5-3.5 1.7-3.5 1.7-.2.1-.3.4-.2.6.1.2.4.3.6.2 0 0 2.4-1.2 3.5-1.7.3-.1.6-.1.8 0l-5.9 4.6c-.4.3-1 .5-1.5.4L5 16c-.1 0-.3 0-.3.1l-1.3 1.2c-.2.2-.2.5-.1.6.1.1.2.2.3.2.1 0 .2 0 .3-.1L5 17l5.1.9c.7.1 1.5-.1 2.1-.5l6.3-4.9c.2-.2.3-.5.2-.7zm-8.1-.5c.7 0 1.3-.3 1.8-.8 1-1 1-2.7 0-3.8-.5-.5-1.1-.8-1.8-.8s-1.3.3-1.8.8c-1 1-1 2.7 0 3.8.5.5 1.1.8 1.8.8zM9.4 7.4c.3-.3.7-.5 1.2-.5.4 0 .9.2 1.2.5.7.7.7 1.8 0 2.5-.3.3-.7.5-1.2.5-.4 0-.9-.2-1.2-.5-.6-.7-.6-1.8 0-2.5zm4.2-1.3c.5 0 1-.2 1.4-.6.8-.8.8-2.1 0-3-.4-.4-.9-.6-1.4-.6-.5 0-1 .2-1.4.6-.8.8-.8 2.1 0 3 .4.3.9.6 1.4.6zm-.8-3c.2-.2.5-.3.8-.3.3 0 .6.1.8.3.4.5.4 1.2 0 1.7-.2.2-.5.3-.8.3-.3 0-.6-.1-.8-.3-.4-.5-.4-1.2 0-1.7z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBonus;
