// @flow

import React from 'react';

type InactiveBgProps = {
  onClose?: () => void
};

const style = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

const className = 'inactive-bg';

const close = onClose => e => {
  e.stopPropagation();
  const { target } = e;
  const clickedOnBg = target.classList.contains(className) || target.closest(className);

  if (clickedOnBg && onClose) {
    onClose();
  }
};

const InactiveBg = ({ onClose }: InactiveBgProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  <div style={style} className={className} onClick={close(onClose)} />
);

export default InactiveBg;
