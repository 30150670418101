// @flow

import { Arrows } from 'components/SectionHeading';
import Text from 'components/Text';
import * as React from 'react';
import View from 'components/View';
import Button from './Button';

const styles = {
  header: (props, { components: { FocusPage } }) => ({
    ...FocusPage.page,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingBottom: 20
  }),
  client: (props, { components: { FocusPage } }) => ({
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    ...FocusPage.text
  }),
  clientText: {
    textTransform: 'uppercase',
    color: 'inherit'
  },
  clientSubText: (props, { baseSize, components: { FocusPage } }) => ({
    ...FocusPage.subText,
    marginTop: -baseSize
  })
};

const FocusHeader = ({ onClose, title, subTitle, fastBackward, fastForward }) => (
  <View isVertical style={styles.header}>
    <View style={styles.client}>
      <View>
        {fastBackward && <Arrows direction="backward" />}
        <Text style={styles.clientText} scale={1} fontFamily="PT Sans Caption" weight="bold">
          {title}
        </Text>
        {fastForward && <Arrows direction="forward" />}
      </View>
      <Text style={styles.clientSubText} scale={-0.5} weight="bold">
        {subTitle}
      </Text>
    </View>
    {onClose ? <Button icon="checkbox" accent="plain" scale={-0.5} onClick={onClose} /> : null}
  </View>
);

export default FocusHeader;
