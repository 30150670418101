// @flow

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Date.css';
import { FastField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import ValidationError from 'components/ValidationError';
import ErrorMessage from 'components/ErrorMessage';
import { stripUnit } from '../../utils/units';
import InactiveBg from '../InactiveBg';
import ShortcutsPanel from './ShortcutsPanel';
import TimePanel from './TimePanel';
import { getLineHeight } from '../Text';
import TextInput from '../TextInput';
import View from '../View';
import { LabelForInput } from '../Label';
import type { Theme } from '../../types';
import SimpleSelect from '../SimpleSelect';
import Icon from '../Icon';

type InnerProps = {
  withTime?: boolean,
  withClear?: boolean
};

type OuterProps = {
  initialDate: typeof moment,
  initialTime: string
};

type Props = InnerProps & OuterProps;

const initialDateState = (date = null, time = '00:00') => ({
  date: date ? moment(date) : null,
  time
});

const stateHandlers = {
  saveAndCloseCalendar: (date, timePanelRef, withTime, saveAndCloseCalendarCallback) => {
    if (saveAndCloseCalendarCallback) {
      if (withTime) {
        const { time } = timePanelRef.getTimes();
        saveAndCloseCalendarCallback({ date, time });
      } else {
        saveAndCloseCalendarCallback({ date });
      }
    }

    return null;
  },
  commitTimes: time => ({
    time
  }),
  saveTimePanelRef: timePanelRef => ({
    timePanelRef
  })
};

const styles = {
  box: {
    position: 'relative',
    flexGrow: 1
  },
  dateInputs: ({ isSeparate }) => ({
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: isSeparate ? 'repeat(2, 1fr)' : '100%',
    gridColumnGap: 10
  }),
  zIndex: {
    // keep above inactiveBg
    zIndex: 500
  },
  pickerWrapper: (props, theme) => ({
    position: 'absolute',
    top: stripUnit('px', getLineHeight({ scale: 0.5 }, theme)) + theme.baseSize * 5 + 15,
    zIndex: 501
  }),
  label: (props, { components: { Date } }: Theme) => ({
    color: Date.label.color
  }),
  clearIcon: {
    position: 'absolute',
    bottom: 10,
    right: 35,
    ':hover': {
      cursor: 'pointer'
    }
  }
};

const renderYears = () => {
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = moment().year() - 100; i <= moment().year() + 10; i++) {
    years.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return years;
};

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <View
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 0px',
      height: '20px'
    }}>
    <View style={{ flexDirection: 'row', width: '110px' }}>
      <SimpleSelect
        key="month-picker"
        value={month.month()}
        onChange={e => onMonthSelect(month, e.target.value)}
        style={{
          backgroundColor: 'transparent',
          fontSize: '14px',
          color: 'inherit',
          border: 'none',
          padding: '5px 8px'
        }}>
        {moment.months().map((label, value) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </SimpleSelect>
    </View>
    <View style={{ flexDirection: 'row', width: '80px' }}>
      <SimpleSelect
        key="year-picker"
        value={month.year()}
        onChange={e => onYearSelect(month, e.target.value)}
        style={{
          backgroundColor: 'transparent',
          fontSize: '14px',
          color: 'inherit',
          border: 'none',
          padding: '5px 8px'
        }}>
        {renderYears()}
      </SimpleSelect>
    </View>
  </View>
);

const Date = ({
  label,
  withTime,
  withClear,
  saveAndCloseCalendarCallback,
  initialDate,
  initialTime,
  bold = false,
  isVertical = false,
  hasError,
  disabled,
  focused,
  onFocusChange,
  onDateChange,
  width,
  styleLabel,
  forceInitialEmpty
}: Props) => {
  const [dateState, setDateState] = useState(
    forceInitialEmpty ? initialDateState(null, null) : initialDateState(initialDate, initialTime)
  );
  const [focusedInputState, setFocusedInputState] = useState(false);
  const [timePanelRefState, setTimePanelRefState] = useState(null);
  const dateRef = useRef('dateRef');

  // HACK: komponentu sa neda zvonku povedat, aby zobrazil konkretny range
  // (napr. som v roku 2005 a navolim "minuly mesiac")
  // a tak ho pri explicitnom vybere rozsahu cez tlacitka proste remountneme
  const [componentKeyHack, setComponentKeyHack] = useState(0);

  const [formattedDate, setFormattedDate] = useState('');

  let dateFormatted = dateState.date ? moment(dateState.date).format('L') : '';

  if (withTime) {
    if (dateFormatted) {
      dateFormatted += ` ${dateState.time}`;
    }
  }

  const onDateTextChange = e => {
    if (e.target.value) {
      const date = e.target.value.replace(/ /g, '');
      if (date && moment(date, 'DD.MM.YYYY').isValid()) {
        setDateState({ ...dateState, date: moment(date, 'DD.MM.YYYY') });
      } else {
        setFormattedDate(dateFormatted);
      }
    }
  };

  const clearValue = () => {
    setDateState(initialDateState(null, null));
    saveAndCloseCalendarCallback({
      date: null,
      time: null
    });
  };

  const getCalculatedPickerLeft = () => {
    let left = 0;
    const pickerLeft = dateRef.current && dateRef.current.getBoundingClientRect().left;
    const windowWidth = window.innerWidth;
    if (windowWidth - pickerLeft < 353) {
      left = -(353 - (windowWidth - pickerLeft));
    }
    return left;
  };

  const getCalculatedPickerTop = () => {
    let top = isVertical ? 75 : 35;
    const modalHeight = withTime ? 430 : 379;
    const pickerTop = dateRef.current && dateRef.current.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    if (windowHeight - (pickerTop + top) < modalHeight) {
      top = -(modalHeight - (windowHeight - pickerTop));
    }
    return top;
  };

  useEffect(
    () => {
      setDateState(
        forceInitialEmpty
          ? initialDateState(null, null)
          : initialDateState(initialDate, initialTime)
      );
    },
    [initialDate, initialTime]
  );

  useEffect(
    () => {
      let formatted = dateState.date ? moment(dateState.date).format('L') : '';

      if (withTime) {
        if (formatted) {
          formatted += ` ${dateState.time}`;
        }
      }

      if (onDateChange) {
        onDateChange(dateState.date);
      }

      setFormattedDate(formatted);
    },
    [dateState]
  );

  const datePickerPlaceholder = 'dátum';

  return (
    <View style={[styles.box, { width }]}>
      <span ref={dateRef}>
        <View style={styles.dateInputs}>
          <View
            style={[
              styles.zIndex,
              {
                position: 'relative',
                flexDirection: isVertical ? 'column' : 'row',
                alignItems: 'flex-start'
              }
            ]}>
            {label ? (
              <LabelForInput style={[styles.label, styleLabel]} bold={bold}>
                {label}
              </LabelForInput>
            ) : null}
            <TextInput
              value={formattedDate}
              onBlur={onDateTextChange}
              onChange={e => {
                setFormattedDate(e.target.value);
              }}
              onFocus={() => setFocusedInputState(true)}
              placeholder={datePickerPlaceholder}
              iconRight="calendar"
              hasError={hasError}
              style={width}
              width={width}
              disabled={disabled}
            />
            {withClear &&
              dateState.date && (
                <View
                  style={styles.clearIcon}
                  nativeProps={{
                    onClick: e => {
                      e.stopPropagation();
                      clearValue();
                    }
                  }}>
                  <Icon name="checkbox" />
                </View>
              )}
          </View>
        </View>
      </span>
      {focusedInputState && (
        <>
          <InactiveBg
            onClose={() => {
              setFocusedInputState(
                stateHandlers.saveAndCloseCalendar(
                  dateState.date,
                  timePanelRefState,
                  withTime,
                  saveAndCloseCalendarCallback
                )
              );
            }}
          />
          <View
            style={[
              styles.pickerWrapper,
              { left: getCalculatedPickerLeft(), top: getCalculatedPickerTop() }
            ]}>
            <DayPickerSingleDateController
              key={componentKeyHack}
              date={dateState.date}
              onDateChange={date => {
                setDateState({ ...dateState, date });
              }}
              focused={focused || focusedInputState}
              onFocusChange={({ focused: nFocused }) => {
                if (onFocusChange) {
                  onFocusChange(nFocused);
                }
                // FIX for auto hide picker after click on day
                // setFocusedInputState(nFocused);
              }}
              // onFocusChange={focusedInput => focusedInput && setFocusedInputState(focusedInput)}
              renderMonthElement={renderMonthElement}
              renderCalendarInfo={() => (
                <>
                  {/* great perf problems if values are saved in this component state */}
                  {withTime ? (
                    <TimePanel
                      ref={timePanelRef => {
                        setTimePanelRefState(timePanelRef);
                      }}
                      commitChanges={time => setDateState({ ...dateState, ...time })}
                      time={dateState.time}
                    />
                  ) : null}
                  {!onFocusChange && (
                    <ShortcutsPanel
                      onDatesChange={date => {
                        setDateState({ ...dateState, date });
                        setComponentKeyHack(componentKeyHack + 1);
                      }}
                      saveAndCloseCalendar={() =>
                        setFocusedInputState(
                          stateHandlers.saveAndCloseCalendar(
                            dateState.date,
                            timePanelRefState,
                            withTime,
                            saveAndCloseCalendarCallback
                          )
                        )
                      }
                    />
                  )}
                </>
              )}
              numberOfMonths={1}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
            />
          </View>
        </>
      )}
    </View>
  );
};

const inForm = SomeDateInput =>
  function({ name, onSubmit, disabled, ...props }) {
    return (
      <FastField name={name}>
        {({
          field: { value = {} },
          form: { isSubmitting, status = {}, errors, touched, setFieldValue, setFieldTouched }
        }) => (
          <View isVertical>
            <SomeDateInput
              {...props}
              initialTime={value.time}
              initialDate={value.date || value}
              disabled={disabled || isSubmitting}
              hasError={(errors[name] && touched[name]) || status[name]}
              saveAndCloseCalendarCallback={date => {
                setFieldValue(name, date);
                setFieldTouched(name, true);

                if (onSubmit) {
                  onSubmit();
                }
              }}
            />
            <ErrorMessage name={name} component={ValidationError} />
          </View>
        )}
      </FastField>
    );
  };

const DateForm = inForm(Date);

export { DateForm };

export default Date;
