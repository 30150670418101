import * as React from 'react';
import View from 'components/View';

const styles = {
  upperTableWrapper: {
    flexShrink: 0,
    width: '100%'
  },
  tableWrapper: ({ rowsCount, maxVisibleRows }, { baseSize, typo }) => {
    const rowHeight = baseSize * 5.5 + typo.Roboto.rhythm;
    const heightHeight = 40;

    const rowsHeight = rowsCount * rowHeight;
    const maxVisibleHeight = (maxVisibleRows || rowsCount) * rowHeight;

    return {
      flex: '1 0',
      height: Math.min(heightHeight + maxVisibleHeight, heightHeight + rowsHeight)
    };
  }
};

const MaxHeightTableSizer = ({ maxVisibleRows, totalRowsCount, children }) => (
  <View style={styles.upperTableWrapper}>
    <View style={styles.tableWrapper} rowsCount={totalRowsCount} maxVisibleRows={maxVisibleRows}>
      {children}
    </View>
  </View>
);

export default MaxHeightTableSizer;
