import React from 'react';

const SvgPackage = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M10 5.686v-.003l2.062-.86V4.82l3.088 1.357 3.1-1.14L10 2 1.75 5.035 10 8.072l3.088-1.136L10 5.686zm-8.25 9.468L9.484 18V8.894L1.75 6.048v9.106zm2.062-1.805l2.063.758v1.08l-2.063-.758v-1.08zM15.156 9.72l-2.062.76V7.945l-2.578.949V18l7.734-2.846V6.048l-3.094 1.137V9.72z"
    />
  </svg>
);

export default SvgPackage;
