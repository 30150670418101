import { homePath, webPagePath } from 'WebModule/app/routerPaths';
import View from 'components/View';
import { PrivateRoute, PublicRoute } from 'router/PrivateRoute';
import React from 'react';
import { Route, Switch } from 'react-router';
import WebPage from 'WebModule/WebPage';
import LoginPage from 'WebModule/LoginPage';
import App from './App';

export default () => (
  <>
    <Switch>
      <PublicRoute redirect={webPagePath} exact path={homePath} component={LoginPage} />
      <PrivateRoute redirect={webPagePath}>
        <App>
          <View isExpanded isVertical>
            <Switch>
              {/* use keys to avoid transactions artefacts */}
              <Route path={webPagePath} render={WebPage} key="web" />
            </Switch>
          </View>
        </App>
      </PrivateRoute>
    </Switch>
  </>
);
