// @flow

import sortableTableProps from 'components/hoc/sortableTableProps';
import withQueryService from 'components/hoc/withQueryService';
import cellRenderers, { isPrimary, withStatus } from 'components/tables/CellRenderers';
import { emptyTableEnhancer, withInfiniteLoading } from 'components/tables/enhancers';
import headerRenderers from 'components/tables/HeaderRenderers';
import TableVendor from 'components/tables/Table';
import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { compose, pathOr } from 'rambda';
import { loadMoreRows } from 'utils/table';
import MaxHeightTableSizer from '../../components/tables/MaxHeightTableSizer';
import { getCurrencyMark } from '../utils/currencyMarks';

const statuses = {
  ADD: 3,
  CANCEL: 2
};

const columns = [
  {
    cellDataGetter: () => {},
    cellRenderer: withStatus('node', ({ operationType }) => statuses[operationType])(
      cellRenderers.TextLeft
    ),
    dataKey: 'operationType',
    label: '',
    width: 30,
    disableSort: true
  },
  {
    cellDataGetter: ({
      rowData: {
        node: { amount, transactionType }
      }
    }) => ({ price: amount, type: transactionType }),
    cellRenderer: isPrimary(cellRenderers.Operation),
    dataKey: 'amount',
    label: 'Operácia',
    minWidth: 100,
    width: 120
  },
  {
    cellDataGetter: ({
      rowData: {
        node: { currency }
      }
    }) => (currency ? currency.code : null),
    dataKey: 'currency',
    label: 'Mena',
    minWidth: 60,
    width: 60,
    disableSort: true
  },
  {
    cellDataGetter: ({
      rowData: {
        node: { bill }
      }
    }) => bill,
    dataKey: 'bill',
    label: `Stav po transakcii v `,
    minWidth: 200,
    widthRatio: 0.1,
    headerRenderer: headerRenderers.HeaderWithValue
  },
  {
    cellDataGetter: ({
      rowData: {
        node: { creator }
      }
    }) => pathOr('', 'company.name', creator),
    dataKey: 'creator',
    label: 'Prevádzka',
    minWidth: 150,
    widthRatio: 0.2,
    cellRenderer: cellRenderers.TextLeft
  },
  {
    cellDataGetter: ({
      rowData: {
        node: { createdAt }
      }
    }) => moment(createdAt).format('L LT'),
    cellRenderer: cellRenderers.TextRight,
    headerRenderer: headerRenderers.Right,
    dataKey: 'createdAt',
    label: 'Dátum a čas transakcie',
    minWidth: 150,
    width: 150
  }
];

const DataTable = compose(
  emptyTableEnhancer({ message: 'Transakcie sa nenašli', icon: 'transactions' }),
  withInfiniteLoading
)(TableVendor);

const query = gql`
  query Transactions(
    $userWalletId: String
    $after: String
    $search: String
    $orderBy: String
    $dateFrom: Date
    $dateTo: Date
    $walletName: String
    $operationName: String #    $transactionType: String
  ) {
    transactions(
      first: 20
      userWalletId: $userWalletId
      after: $after
      any: $search
      orderBy: [$orderBy]
      createdGt: $dateFrom
      createdLt: $dateTo
      walletName: $walletName
      operationName: $operationName #      transactionType: $transactionType,
    ) {
      edges {
        node {
          id: record
          amount
          currency {
            code
          }
          transactionType
          operationType
          user {
            name
            surname
          }
          userWallet {
            wallet {
              name
            }
          }
          bill
          creator {
            name
            surname
            company {
              name
            }
          }
          createdAt
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`;

const TransactionsTable = ({
  QueryService,
  userWalletId,
  queryParams: {
    search = '',
    date = '',
    transactionType = '',
    walletName = '',
    operationName = ''
  },
  tableSorting,
  orderBy
}) => {
  const [dateFrom, dateTo] = date ? `${date}`.split('_') : [null, null];
  return (
    <QueryService
      query={query}
      variables={{
        userWalletId,
        search,
        orderBy,
        dateFrom,
        dateTo,
        transactionType,
        walletName,
        operationName
      }}>
      {({ fetchMore, error, data, variables, loading }) => {
        if (error) return null;

        const rows = pathOr([], 'transactions.edges', data);

        const headerCurrency = pathOr(undefined, 'transactions.edges.0.node.currency.code', data);
        if (headerCurrency) {
          columns[3].columnData = { headerValuer: getCurrencyMark(headerCurrency) };
        }

        // target position 1.5 from window height, 155px(popup header height + table header + padding), 40 table row height
        const maxVisibleRows = (window.innerHeight / 1.5 - 155) / 40;

        return (
          <MaxHeightTableSizer totalRowsCount={rows.length} maxVisibleRows={maxVisibleRows}>
            <DataTable
              loading={loading}
              loadMoreRows={loadMoreRows({
                fetchMore,
                variables: {
                  ...variables,
                  after: pathOr('', 'transactions.pageInfo.endCursor', data)
                },
                name: 'transactions'
              })}
              data={rows}
              columns={columns}
              {...tableSorting}
            />
          </MaxHeightTableSizer>
        );
      }}
    </QueryService>
  );
};

export default compose(
  withQueryService,
  sortableTableProps({ defaultSortBy: '-createdAt' })
)(TransactionsTable);
