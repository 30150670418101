import React from 'react';

const SvgBan = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M10 .5C4.8.5.5 4.8.5 10s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S15.2.5 10 .5zm0 16.6c-3.9 0-7.1-3.2-7.1-7.1 0-1.5.5-2.9 1.3-4.1l9.9 9.9c-1.2.9-2.6 1.3-4.1 1.3zm5.8-3L5.9 4.2c1.2-.9 2.6-1.3 4.1-1.3 3.9 0 7.1 3.2 7.1 7.1 0 1.5-.4 2.9-1.3 4.1z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBan;
