// @flow
import Icon from 'components/Icon';
import Status from 'components/Status';
import Tag from 'components/Tag';
import TagsGroup from 'components/TagsGroup';
import Text from 'components/Text';
import View from 'components/View';
import CheckboxInput from 'components/Checkbox';
import React from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import { formatCurrency } from 'utils/numbers';

const styles = {
  textBox: ({ align, isFallback, indent }, { baseSize }) => ({
    justifyContent: align,
    alignItems: 'center',
    height: '100%',
    paddingRight: baseSize * 3.5,
    paddingLeft: baseSize * (indent === 'half' ? 1.75 : 3.5),
    opacity: isFallback ? 0.4 : 1
  }),
  text: { display: 'block', width: '100%', color: 'inherit' },
  iconsBox: (props, { baseSize }) => ({
    alignItems: 'center',
    height: '100%',
    paddingHorizontal: baseSize * 3.5
  }),
  hiddenOverflow: { overflow: 'hidden' },
  icon: (p, { baseSize }) => ({
    marginRight: baseSize
  }),
  status: (props, { baseSize }) => ({
    marginRight: baseSize * 2
  }),
  checkbox: { height: '100%', alignItems: 'center', justifyContent: 'center' },
  operationSign: {
    marginRight: 10
  },
  dragHandle: ({ isMoving }) => ({
    height: '100%',
    visibility: isMoving ? 'hidden' : 'visible'
  })
};

const TextPlain = ({ cellData }) => <View style={styles.textBox}>{cellData}</View>;

const TextGeneric = ({ cellData, align, isPrimary, isFallback, status, indent }) => (
  <View style={styles.textBox} isFallback={isFallback} indent={indent}>
    {status ? <Status status={status} style={styles.status} /> : null}
    <Text
      style={styles.text}
      align={align}
      weight={isPrimary ? 'bold' : undefined}
      showEllipsis
      title={cellData}>
      {cellData}
    </Text>
  </View>
);

const TextLeft = props => <TextGeneric {...props} align="left" />;

const TextRight = props => <TextGeneric {...props} align="right" />;

const Icons = ({ cellData = [] }) => (
  <View style={styles.iconsBox}>
    <View style={styles.hiddenOverflow}>
      {cellData.map(icon => (
        <View style={styles.icon} key={icon}>
          <Icon name={icon} />
        </View>
      ))}
    </View>
  </View>
);

const Checkbox = ({ isHandlerSelected, onRowCellClickHandler, rowIndex }) => (
  <View style={styles.checkbox}>
    <CheckboxInput
      checked={isHandlerSelected || false} // if checked is undefined, react will cry
      onChange={event => onRowCellClickHandler({ event, index: rowIndex })}
      onClick={event => event.stopPropagation()} // required if wrapped in a link
    />
  </View>
);

const Tags = ({ align = 'left' }) => ({ cellData, isRowSelected }) => (
  <View style={styles.textBox} align={align}>
    <View style={styles.hiddenOverflow}>
      <TagsGroup>
        {cellData.map(tag => (
          <Tag key={tag} accent={isRowSelected ? 'plain' : 'secondary'}>
            {tag}
          </Tag>
        ))}
      </TagsGroup>
    </View>
  </View>
);

const operationIcons = {
  1: 'plus',
  0: 'minus'
};

const Operation = ({ isPrimary, cellData = {} }) => {
  const { price = 0, currency, type } = cellData;
  const isPositive = price >= 0;
  const icon = `${type}`.toLowerCase() === 'discount' ? 'sale' : operationIcons[+isPositive];
  const iconScale = icon === 'sale' ? 0 : -1;

  return (
    <View style={styles.textBox} nativeProps={{ title: formatCurrency(price, { currency }) }}>
      <Icon name={icon} style={styles.operationSign} scale={iconScale} />
      <Text style={styles.text} weight={isPrimary ? 'bold' : undefined} showEllipsis align="right">
        {type.includes('PREPAID_PRODUCT') ? price : formatCurrency(Math.abs(price), { currency })}
      </Text>
    </View>
  );
};

const IconDragHandle = sortableHandle(() => (
  <Icon name="kebab-menu" scale={1} style={{ alignSelf: 'center', cursor: 'grab' }} />
));

const DragHandle = ({ isMoving }) => (
  <View className="on-hover drag-handle" isMoving={isMoving} style={styles.dragHandle}>
    <IconDragHandle />
  </View>
);

const Code = ({ cellData }) => (
  <View style={styles.textBox}>
    <Text fontFamily="monospace" weight="bold" style={styles.text} showEllipsis title={cellData}>
      {cellData}
    </Text>
  </View>
);

export const isPrimary = (CellRenderer, { indent } = {}) => props => (
  <CellRenderer {...props} isPrimary indent={indent} />
);

export const withStatus = (key, statusGetter) => CellRenderer => props => {
  const {
    rowData: { [key]: status }
  } = props;

  return (
    <CellRenderer {...props} status={statusGetter ? statusGetter(status) : status} isPrimary />
  );
};

export const hasFallback = fallbackValue => CellRenderer => ({ cellData, ...props }) => (
  <CellRenderer {...props} cellData={cellData || fallbackValue} isFallback={!cellData} />
);

export default {
  Icons,
  Checkbox,
  TextLeft,
  TextRight,
  TextPlain,
  Tags,
  Operation,
  DragHandle,
  Code
};
