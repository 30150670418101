import React from 'react';

const SvgWallet = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M12.2 3.1l-.4-.7c-.2-.5-.8-.7-1.3-.4L4.4 5h.9l6.9-1.9zm3.4 1.8l-.3-1.1c-.1-.4-.5-.7-.9-.7h-.3l-1.6.4-4.9 1.4h8zm-5.4 8v-2.1c0-.4.2-.8.5-1.1.3-.3.6-.4 1-.5h5.9V6.6c0-.5-.4-1-1-1H2.5c-.3 0-.6.1-.8.4-.1.1-.1.2-.2.3v11c0 .5.4 1 1 1h14.1c.5 0 1-.4 1-1v-2.7h-5.7c-.9-.1-1.7-.8-1.7-1.7zm8-2.8c-.1-.1-.2-.2-.3-.2-.1 0-.2-.1-.3-.1h-5.7c-.5 0-1 .4-1 1v2.1c0 .5.4 1 1 1h5.7c.1 0 .2 0 .3-.1.1 0 .2-.1.3-.2.2-.2.3-.4.3-.7v-2.1c0-.3-.1-.5-.3-.7zM13.8 12c0 .3-.2.5-.5.5H13c-.3 0-.5-.2-.5-.5v-.3c0-.2.1-.3.2-.4.1-.1.2-.1.3-.1h.3c.3 0 .5.2.5.5v.3z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWallet;
