// @flow
import './resetTables.css';
import React from 'react';
import { Column, Table as TableVendor } from 'react-virtualized';
import cellRenderers from './CellRenderers';
import { defaultTableEnhancer } from './enhancers';
import headerRenderers from './HeaderRenderers';
import DefaultHeaderRowRenderer from './HeaderRowRenderer';
import DefaultRowRenderer from './RowRenderer';

type Props = {
  data: Object[],
  sortBy?: string,
  sortDirection?: 'ASC' | 'DESC',
  headerRowRenderer?: Function,
  columns: Array,
  onRowCellClickHandler?: Function,
  onHeaderCellClickHandler?: Function,
  onRowClick?: Function,
  rowsAlternation: 'background' | 'border'
};

const Table = ({
  actions,
  columns = [],
  data,
  headerRowRenderer: HeaderRowRenderer = DefaultHeaderRowRenderer,
  height,
  gridClassName,
  isMoving,
  onRowClick,
  onRowCellClickHandler,
  onRowsRendered,
  onHeaderCellClickHandler,
  passRef,
  rowsAlternation = 'background',
  rowRenderer: RowRenderer = DefaultRowRenderer,
  selectedRowIndexes,
  selectedRowHandlersIndexes,
  sort,
  sortBy,
  sortDirection = 'ASC',
  width
}: Props) => (
  <TableVendor
    ref={passRef}
    width={width}
    height={height}
    gridClassName={gridClassName}
    headerHeight={HeaderRowRenderer.rowHeight}
    headerRowRenderer={HeaderRowRenderer}
    rowCount={data.length}
    rowHeight={RowRenderer.rowHeight}
    rowRenderer={props => (
      <RowRenderer
        {...props}
        actions={actions}
        isSelectable={!!onRowClick}
        isRowSelected={!!onRowClick && selectedRowIndexes && selectedRowIndexes[props.index]}
        isHandlerSelected={
          !!onRowClick && selectedRowHandlersIndexes && selectedRowHandlersIndexes[props.index]
        }
        rowsAlternation={rowsAlternation}
        isMoving={isMoving}
      />
    )}
    rowGetter={({ index }) => data[index]}
    onRowClick={onRowClick}
    onRowsRendered={onRowsRendered}
    sort={sort}
    sortBy={sortBy}
    sortDirection={sortDirection}>
    {columns
      .filter(column => column.visible !== false)
      .map(
        ({
          width: customWidth,
          widthRatio,
          cellRenderer = cellRenderers.TextLeft,
          headerRenderer = headerRenderers.Left,
          ...rest
        }) => (
          <Column
            key={rest.dataKey}
            cellRenderer={props =>
              cellRenderer({
                ...props,
                isMoving,
                onRowCellClickHandler,
                isRowSelected: selectedRowIndexes && selectedRowIndexes[props.rowIndex],
                isHandlerSelected:
                  selectedRowHandlersIndexes && selectedRowHandlersIndexes[props.rowIndex]
              })
            }
            headerRenderer={props =>
              headerRenderer({
                ...props,
                onHeaderCellClickHandler,
                areSelectedAllRows:
                  selectedRowIndexes && Object.values(selectedRowIndexes).length === data.length,
                areSelectedAllHandlers:
                  data.length &&
                  selectedRowHandlersIndexes &&
                  Object.values(selectedRowHandlersIndexes).length === data.length
              })
            }
            width={widthRatio ? width * widthRatio : customWidth}
            {...rest}
          />
        )
      )}
  </TableVendor>
);

export { Table as PureTable };

export default defaultTableEnhancer(Table);
