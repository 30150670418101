// @flow

import * as React from 'react';
import QueryServiceContext from 'contexts/queryServiceContext';

export default BaseComponent => props => (
  <QueryServiceContext.Consumer>
    {QueryServiceImpl => <BaseComponent {...props} QueryService={QueryServiceImpl} />}
  </QueryServiceContext.Consumer>
);
