import React from 'react';

const SvgCalendar = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M5.2 4.9V2.3c0-.4.3-.7.7-.7.4 0 .7.3.7.7v2.6c0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7zm8.6 0V2.3c0-.4.3-.7.7-.7s.7.3.7.7v2.6c0 .4-.3.7-.7.7s-.7-.3-.7-.7zm4.8 13.4H1.7V2.9H5v.8c-.7.6-.7 1.5-.1 2.1.3.3.6.4 1 .4.8 0 1.5-.6 1.5-1.4 0-.4-.2-.8-.5-1.1v-.8h6.7v.8c-.3.3-.5.6-.5 1.1 0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.4-.2-.8-.5-1.1v-.8h3.1l.1 15.4zM3.3 7.1v9.6h13.8V7.1H3.3zm5.3 3.7H6.9V9h1.7v1.8zm2.5 0H9.3V9H11v1.8zm2.4 0h-1.7V9h1.7v1.8zm2.5 0h-1.7V9H16v1.8zm-9.8 2.3H4.5v-1.7h1.7v1.7zm2.4 0H6.9v-1.7h1.7v1.7zm2.5 0H9.3v-1.7H11v1.7zm2.4 0h-1.7v-1.7h1.7v1.7zm2.5 0h-1.7v-1.7H16v1.7zm-9.8 2.3H4.5v-1.7h1.7v1.7zm2.4 0H6.9v-1.7h1.7v1.7zm2.5 0H9.3v-1.7H11v1.7zm2.4 0h-1.7v-1.7h1.7v1.7z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCalendar;
