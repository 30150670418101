// @flow

import View from 'components/View';
import React from 'react';
import type { Theme } from 'types';

const styles = {
  box: ({ withShadow }, { shadows, components: { DetailBottomBar } }: Theme) => ({
    ...DetailBottomBar,
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: withShadow ? shadows.subtle : undefined,
    zIndex: withShadow ? 1 : 0
  })
};

const LoginBottomBar = ({ withShadow, children }: Props) => (
  <View style={styles.box} withShadow={withShadow}>
    {children}
  </View>
);

export default LoginBottomBar;
