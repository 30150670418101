// @flow
import React from 'react';
import { withRouter } from 'react-router';

// also injects router props
const withIsWideInfo = BaseComponent =>
  withRouter(props => {
    const slugs = props.location.pathname.replace(/\/$/, '').split('/');
    const isMenuWide = slugs.length <= 3 || slugs[4] === 'focus';

    return <BaseComponent {...props} isMenuWide={isMenuWide} />;
  });

export default withIsWideInfo;
