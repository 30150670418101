// @flow

import * as React from 'react';
import QueryClientContext from 'contexts/queryClientContext';

export default BaseComponent => props => (
  <QueryClientContext.Consumer>
    {client => <BaseComponent {...props} client={client} />}
  </QueryClientContext.Consumer>
);
