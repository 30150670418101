// @flow

import Icon from 'components/Icon';
import View from 'components/View';
import React from 'react';
import Text from './Text';

export type Props = {
  fastForward?: string,
  fastBackward?: string,
  children: string
};

const fontFamily = 'PT Sans Caption';

const styles = {
  box: (props, { baseSize, components: { SectionHeading } }) => ({
    marginVertical: baseSize * 4,
    flexShrink: 0,
    ...SectionHeading
  }),
  arrow1: ({ direction }) => ({
    alignSelf: 'center',
    opacity: 0.3,
    marginBottom: 2,
    marginLeft: direction === 'forward' ? 10 : 0
  }),
  arrow2: {
    alignSelf: 'center',
    opacity: 0.6,
    marginBottom: 2,
    marginLeft: -6
  },
  arrow3: ({ direction }) => ({
    alignSelf: 'center',
    opacity: 1,
    marginBottom: 2,
    marginLeft: -6,
    marginRight: direction === 'backward' ? 10 : 0
  })
};

export const Arrows = ({ direction }) => (
  <>
    <Icon
      name={`fast-${direction}`}
      fontFamily={fontFamily}
      style={styles.arrow1}
      direction={direction}
    />
    <Icon name={`fast-${direction}`} fontFamily={fontFamily} style={styles.arrow2} />
    <Icon
      name={`fast-${direction}`}
      fontFamily={fontFamily}
      style={styles.arrow3}
      direction={direction}
    />
  </>
);

const SectionHeading = ({ children, fastBackward, fastForward }: Props) => (
  <View style={styles.box}>
    {fastBackward && <Arrows direction="backward" />}
    <Text color="inherit" scale={3} transform="uppercase" fontFamily={fontFamily} weight="bold">
      {children}
    </Text>
    {fastForward && <Arrows direction="forward" />}
  </View>
);

export default SectionHeading;
