// @flow
import ErrorMessage from 'components/ErrorMessage';
import InputPattern from 'components/InputPattern';
import type { BaseProps, Props } from 'components/InputPattern';
import Stylo from 'components/Stylo';
import ValidationError, { ValidationErrorWithLabel } from 'components/ValidationError';
import View from 'components/View';
import { Field } from 'formik';
import * as React from 'react';
import type { Theme } from 'types';
import { stripUnit } from 'utils/units';

const styles = {
  plainInput: ({ expandHeight, fontFamily, scale }, { typo }: Theme) => ({
    height: expandHeight ? '100%' : stripUnit('px', typo[fontFamily].lineHeight[scale]),
    boxShadow: 'none !important'
  }),
  borderInput: (
    { fontFamily, scale, hasError },
    { baseSize, typo, borderRadius, components }: Theme
  ) => ({
    border: '2px solid',
    borderRadius: borderRadius.normal,
    height: stripUnit('px', typo[fontFamily].lineHeight[scale]) + baseSize * 5,
    paddingLeft: baseSize * 4, // don't use paddingHorizontal (for overriding later)
    paddingRight: baseSize * 4,
    borderColor: hasError ? components.ValidationError.color : undefined,
    width: '100%'
  })
};

const TextInputBase = ({
  defaultValue,
  value,
  style,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  onKeyDown,
  onClick,
  type,
  placeholder,
  disabled,
  name,
  readOnly,
  accept,
  nativeRef,
  min,
  max,
  step,
  ...restProps
}: BaseProps) => (
  <Stylo
    as="input"
    weight="bold"
    style={style}
    nativeProps={{
      ref: nativeRef,
      accept,
      name,
      defaultValue,
      value,
      type,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onKeyDown,
      onClick,
      placeholder,
      disabled,
      readOnly,
      min,
      max,
      step
    }}
    readOnly={readOnly}
    type={type}
    {...restProps}
  />
);

const TextAreaBase = ({
  value,
  style,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  onKeyDown,
  onClick,
  disabled,
  name,
  readOnly,
  nativeRef,
  ...restProps
}: BaseProps) => (
  <Stylo
    as="textarea"
    style={style}
    nativeProps={{
      ref: nativeRef,
      name,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onKeyDown,
      onClick,
      disabled,
      readOnly,
      value
    }}
    isMultiline
    readOnly={readOnly}
    {...restProps}
  />
);

const TextInputWithIcon = ({ isMultiline, ...props }) => (
  <View as="label" isExpanded>
    <InputPattern
      {...props}
      isMultiline={isMultiline}
      Base={isMultiline ? TextAreaBase : TextInputBase}
    />
  </View>
);

const TextInputPlain = (props: Props) => <TextInputWithIcon {...props} style={styles.plainInput} />;

const TextInputWithIconAndBorder = ({ hasError, style, ...props }: Props) => (
  <TextInputWithIcon {...props} style={[styles.borderInput, style]} hasError={hasError} />
);

const clearStatusOnBlur = ({
  field: { name, onBlur },
  status: { [name]: fieldStatus, ...restStatus },
  setStatus
}) => e => {
  if (fieldStatus) {
    setStatus(restStatus);
  }

  if (onBlur) {
    onBlur(e);
  }
};

const inForm = SomeTextInput => ({ name, isVertical, disabled, ...props }) => (
  <Field name={name}>
    {({ field, form: { isSubmitting, errors, status = {}, setStatus, touched } }) => (
      <View isVertical isExpanded>
        <SomeTextInput
          {...field}
          {...props}
          isVertical
          onBlur={clearStatusOnBlur({ field, setStatus, status })}
          disabled={disabled || isSubmitting}
          hasError={(errors[field.name] && touched[field.name]) || status[field.name]}
        />
        <ErrorMessage
          name={name}
          component={props.label && !isVertical ? ValidationErrorWithLabel : ValidationError}
        />
      </View>
    )}
  </Field>
);

const TextInputForm = inForm(TextInputWithIconAndBorder);
const TextInputPlainForm = inForm(TextInputPlain);

export { styles, TextInputPlain, TextInputForm, TextInputPlainForm };

export default TextInputWithIconAndBorder;
