// @flow

const sign = '\u2212';

export const formatCurrency = (priceCents = 0, { currency, decimalPlaces = 2 }) => {
  const priceHuman = priceCents / 100;

  try {
    let formatterStyle = currency ? 'currency' : 'decimal';
    if (currency === 'BOD') {
      formatterStyle = 'decimal';
    }

    const formatter = new Intl.NumberFormat(navigator.language, {
      currency,
      style: formatterStyle,
      minimumFractionDigits: decimalPlaces
    });

    return `${priceHuman < 0 ? sign : ''}${formatter.format(Math.abs(priceHuman))} ${
      currency === 'BOD' ? 'b' : ''
    }`;
  } catch {
    // fallback
  }

  const priceFormatted = `${priceHuman < 0 ? sign : ''}${Math.abs(priceHuman)
    .toFixed(decimalPlaces)
    .toLocaleString()}`;

  return currency ? `${priceFormatted}${currency}` : priceFormatted;
};
