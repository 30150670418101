// @flow
import React from 'react';
import gql from 'graphql-tag';
import withQueryService from 'components/hoc/withQueryService';
import { path } from 'rambda';
import { Col, Row } from 'components/Layout';
import View from 'components/View';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'react-fela';
import Sidebar from '../MainPage/Sidebar';
import { coloredTheme } from '../themes/default';

const query = gql`
  query {
    companies(first: 1) {
      edges {
        node {
          color
        }
      }
    }
  }
`;

const App = ({ QueryService, children }) => (
  <View style={{ height: '100%' }}>
    <QueryService variables={{}} query={query}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;

        const companiesSet = path('companies.edges', data) ? data.companies.edges : null;
        const company = companiesSet && companiesSet[0] ? companiesSet[0].node : null;
        const color = company && company.color;
        return (
          <ThemeProvider overwrite theme={coloredTheme(color)}>
            <Row>
              <Helmet titleTemplate="%s | LoyalMan" defaultTitle="LoyalMan" />

              <Col size="menu-expanded">
                <Sidebar />
              </Col>

              {children}
            </Row>
          </ThemeProvider>
        );
      }}
    </QueryService>
  </View>
);

export default withQueryService(App);
