import React from 'react';

const SvgPdfWhite = () => (
  <svg
    version={1.1}
    id="Capa_1"
    x={0}
    y={0}
    viewBox="0 0 384 384"
    xmlSpace="preserve"
    width="1em"
    height="1em">
    <style>{`.st0{fill:#fff}`}</style>
    <path
      className="st0"
      d="M64 0v80H0v144h64v160h224l96-96V0H64zM16 208V96h240v112H16zm272 153.376V288h73.376L288 361.376zM368 272h-96v96H80V224h192V80H80V16h288v256z"
    />
    <path
      className="st0"
      d="M90.848 130.864c-.64-2.464-1.696-4.656-3.168-6.576-1.472-1.92-3.392-3.472-5.808-4.656-2.416-1.184-5.36-1.776-8.88-1.776H48.8V186.4h13.824v-27.36h7.392c3.264 0 6.24-.448 8.928-1.264s4.976-2.08 6.864-3.744c1.888-1.648 3.36-3.792 4.416-6.416 1.056-2.624 1.584-5.696 1.584-9.216 0-2.56-.336-5.088-.96-7.536zm-15.408 15.52c-1.68 1.664-3.92 2.512-6.672 2.512h-6.144v-20.848h5.76c3.456 0 5.92.912 7.392 2.688 1.472 1.792 2.208 4.416 2.208 7.872 0 3.52-.848 6.112-2.544 7.776zM153.36 137.44c-.576-4.112-1.664-7.6-3.312-10.512-1.632-2.928-3.92-5.152-6.864-6.72-2.96-1.568-6.816-2.368-11.632-2.368h-22.656v68.544h21.824c4.608 0 8.416-.704 11.424-2.112 3.008-1.408 5.408-3.536 7.2-6.432 1.792-2.88 3.056-6.56 3.792-10.992.736-4.448 1.088-9.68 1.088-15.696 0-5.04-.288-9.616-.864-13.712zm-13.408 25.824c-.272 3.152-.848 5.68-1.728 7.6-.864 1.92-2.032 3.296-3.504 4.128-1.472.832-3.424 1.248-5.856 1.248h-6.144v-48.208h5.84c2.624 0 4.72.512 6.288 1.504 1.584.976 2.752 2.448 3.568 4.4.8 1.952 1.328 4.4 1.584 7.344.24 2.96.384 6.384.384 10.288 0 4.672-.144 8.576-.432 11.696zM211.232 129.184v-11.328h-38.304V186.4h13.824v-29.76h23.04v-11.328h-23.04v-16.128z"
    />
  </svg>
);

export default SvgPdfWhite;
