// @flow

import { ButtonIcon, ButtonLabel, styles as buttonStyles } from 'components/Button';
import ClickableOpacity from 'components/ClickableOpacity';
import View from 'components/View';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  link: ({ isLast, disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: disabled ? 'none' : undefined,
    ...(isLast ? null : { borderRight: '2px solid white' })
  }),
  button: (props, { components: { DetailBottomBarItem } }) => DetailBottomBarItem.normal,
  confirmButton: (props, { components: { DetailBottomBarItem } }) => DetailBottomBarItem.confirm
};

const fontFamily = 'PT Sans Caption';

const DetailBottomBarItem = React.memo(({ direction, disabled }) => {
  const icon = direction === 'forward' ? 'arrow-forward' : 'arrow-back';
  const name = direction === 'forward' ? 'Ďalej' : 'Naspäť';

  return (
    <View
      icon={icon}
      label={name}
      style={[buttonStyles.button, styles.button]}
      accent="plain"
      disabled={disabled}
      nativeProps={{ tabIndex: disabled ? -1 : 0, role: 'button' }}>
      <ButtonIcon icon={icon} label={name} fontFamily={fontFamily} />
      <ButtonLabel label={name} fontFamily={fontFamily} weight="bold" />
    </View>
  );
});

const DetailBottomBarConfirm = ({ onClick, name, disabled, type = 'submit' }) => (
  <View
    as={ClickableOpacity}
    nativeProps={{ onClick, tabIndex: -1, disabled, type }}
    style={styles.link}
    isLast>
    <View
      accent="primary"
      icon="check"
      label={name}
      disabled={disabled}
      style={[buttonStyles.button, styles.confirmButton]}
      nativeProps={{ tabIndex: disabled ? -1 : 0, role: 'button' }}>
      <ButtonIcon icon="check" label={name} fontFamily={fontFamily} />
      <ButtonLabel label={name} fontFamily={fontFamily} weight="bold" />
    </View>
  </View>
);

const DetailBottomBarItemLink = ({ url, direction, disabled, onClick }) => (
  <View
    as={Link}
    nativeProps={{ to: url || '#', tabIndex: -1, onClick }}
    disabled={disabled}
    style={styles.link}
    isLast={direction === 'forward'}>
    <DetailBottomBarItem direction={direction} disabled={disabled} />
  </View>
);

const DetailBottomBarItemButton = ({ onClick, direction, disabled, type = 'button' }) => (
  <View
    as={ClickableOpacity}
    nativeProps={{ onClick, tabIndex: -1, disabled, type }}
    style={styles.link}
    isLast={direction === 'forward'}>
    <DetailBottomBarItem direction={direction} disabled={disabled} />
  </View>
);

export { DetailBottomBarItemLink, DetailBottomBarItemButton, DetailBottomBarConfirm };
