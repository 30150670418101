// @flow

import React from 'react';
import { path } from 'rambda';
import moment from 'moment';
import type { Style, Theme } from '../../types';
import View from '../../components/View';
import Heading from '../../components/Heading';
import ScrollView from '../../components/ScrollView';
import { commonStyles } from '../components/Common';

const styles = {
  actualPriceActionsWidget: (props, theme: Theme): Style => ({
    width: 'calc(100% - 20px)',
    minWidth: '300px',
    maxWidth: '700px',
    margin: '10px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxShadow: theme.shadows.normalAround,
    position: 'relative'
  }),
  actualPriceActionsDivider: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.prepaidProductsWidgetDivider,
    height: '2px',
    width: '100%'
  }),
  actualPriceActionsContentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  actualPriceActionsItemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  actualPriceActionsItemsScroll: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  actualPriceActionsItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0px'
  },
  actualPriceActionsItemDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  actualPriceActionsItemValue: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.actualPriceActionsItemValue,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }),
  actualPriceActionsItemIcon: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.actualPriceActionsItemValue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '74px'
  })
};

/* const itemValue = itemData => {
  if (itemData.icon) {
    return (
      <View style={styles.actualPriceActionsItemValue}>
        <Icon name={itemData.icon} scale={4} style={styles.actualPriceActionsItemIcon} />
      </View>
    );
  }
  if (itemData.value) {
    return (
      <View style={styles.actualPriceActionsItemValue}>
        <Heading style={commonStyles.numbersSympolsPrimaryHeader} scale={3}>
          {itemData.value}
        </Heading>
        <Heading style={commonStyles.primarySubHeaderWithSpace} scale={3}>
          {itemData.symbol}
        </Heading>
      </View>
    );
  }
  return (
    <View style={styles.actualPriceActionsItemValue}>
      <Icon name="voucher" scale={4} style={styles.actualPriceActionsItemIcon} />
    </View>
  );
}; */

const PriceActionsWidget = ({ props, theme, rows, salesSet, companyName }) => {
  const priceActionsItems =
    salesSet &&
    salesSet.map((salesNode, index) => {
      const salesData = path('node', salesNode) ? salesNode.node : null;
      const divider =
        index > 0 ? (
          <View
            key={`actualPriceActionsDivider${index}`}
            props={props}
            theme={theme}
            style={styles.actualPriceActionsDivider}
          />
        ) : null;

      return (
        <View
          key={`actualPriceActionsItem${index}`}
          style={styles.actualPriceActionsContentWrapper}>
          {divider && divider}
          <View style={styles.actualPriceActionsItem}>
            {/* {itemValue(salesData)} */}
            <View style={styles.actualPriceActionsItemDetails}>
              <Heading style={commonStyles.tertiaryHeader} scale={1}>
                {salesData.name}
              </Heading>
              <Heading style={commonStyles.tertiarySubHeader} scale={-0.5}>
                {`Platné do ${salesData.saleTo && moment(salesData.saleTo).format('L')}`}
              </Heading>
            </View>
          </View>
        </View>
      );
    });

  const itemsWrapperHeight = `${typeof rows === 'number' ? rows * 65 : 50}px`;

  return (
    <View style={styles.actualPriceActionsWidget}>
      <Heading style={styles.secondaryHeader} scale={1}>
        {`AKTUÁLNE AKCIE V ${companyName}`}
      </Heading>
      <View style={[styles.actualPriceActionsItemsWrapper, { maxHeight: itemsWrapperHeight }]}>
        <ScrollView style={styles.actualPriceActionsItemsScroll} suppressScrollX>
          {priceActionsItems}
        </ScrollView>
      </View>
    </View>
  );
};

export default PriceActionsWidget;
