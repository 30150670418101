// @flow
import propTypes from 'prop-types';
import { flatten, reduce } from 'rambda';
import * as React from 'react';
import { withTheme } from 'react-fela';
import type { Theme } from 'types';
import { Style } from '../types';

const defaultStyle = {
  display: 'flex'
};

type Props = {
  as?: React.ComponentClass | string,
  style?: Style,
  children?: React.Node,
  className?: string,
  nativeProps?: Object,
  theme: Theme
};

// const Stylo = (
//   { as = 'div', style, children, nativeProps, className = '', theme, ...restProps }: Props,
//   { renderer }
// ) => {
//   const styled = renderer.renderRule(
//     () => ({
//       ...defaultStyle,
//       ...reduce(
//         (acc, nextStyle) => ({
//           ...acc,
//           ...(typeof nextStyle === 'function' ? nextStyle(restProps, theme) : nextStyle)
//         }),
//         {},
//         Array.isArray(style) ? flatten(style) : [style]
//       )
//     }),
//     null
//   );
//
//   const props: any = {
//     className: className ? `${className} ${styled}` : styled,
//     ...nativeProps
//   };
//
//   return React.createElement(as, props, children);
// };

class Stylo extends React.PureComponent {
  render() {
    const { renderer } = this.context;
    const {
      as = 'div',
      style,
      children,
      nativeProps,
      className = '',
      theme,
      ...restProps
    }: Props = this.props;
    const styled = renderer.renderRule(
      () => ({
        ...defaultStyle,
        ...reduce(
          (acc, nextStyle) => ({
            ...acc,
            ...(typeof nextStyle === 'function' ? nextStyle(restProps, theme) : nextStyle)
          }),
          {},
          Array.isArray(style) ? flatten(style) : [style]
        )
      }),
      null
    );

    const props: any = {
      className: className ? `${className} ${styled}` : styled,
      ...nativeProps
    };

    return React.createElement(as, props, children);
  }
}

Stylo.contextTypes = {
  renderer: propTypes.object
};

export default withTheme(Stylo);
