import React from 'react';

const SvgCard2 = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M2.668 14.023V5.977c0-.39.315-.679.679-.679h13.306c.364 0 .679.29.679.678v8.047a.692.692 0 0 1-.678.679H3.347a.698.698 0 0 1-.679-.678zM3.396 7.3v2.77H16.62V7.3H3.396zm1.373 3.945v.587h2.1v-.587h-2.1zm2.506 0v.587h2.73v-.587h-2.73zm-2.506.926v.579h5.64v-.58H4.77z"
    />
  </svg>
);

export default SvgCard2;
