// @flow

import React from 'react';
import { path } from 'rambda';
import { formatCurrency } from 'utils/numbers';
import type { Style, Theme } from '../../types';
import View from '../../components/View';
import Heading from '../../components/Heading';
import { commonStyles } from '../components/Common';
import TextWithPopover from '../components/withPopover/TextWithPopover';
import { MyTransactionContent } from './MainPagePopupsContent';

const styles = {
  basicInfoWidget: (props, { shadows }: Theme) => ({
    width: 'calc(100% - 20px)',
    minWidth: '300px',
    maxWidth: '700px',
    margin: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxShadow: shadows.normalAround,
    position: 'relative'
  }),
  basicInfoWallet: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    minWidth: '300px'
  },
  basicInfoDivider: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.basicInfoWidgetDivider,
    position: 'absolute',
    width: '2px',
    margin: '25px 0px',
    left: '49%',
    right: '49%',
    height: 'calc(100% - 50px)',
    '@media (min-width: 970px) and (max-width: 1240px)': {
      display: 'none'
    },
    '@media (max-width: 915px)': {
      display: 'none'
    }
  }),
  basicInfoPoints: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    minWidth: '300px'
  }
};

const generateWalletsComponents = (props, theme, userWalletsSet, currency) =>
  userWalletsSet.map((walletNode, index) => {
    const walletData = path('node', walletNode) ? walletNode.node : null;
    if (walletData && walletData.wallet && walletData.wallet.walletType === 'CREDIT') {
      return (
        <View key={`wallet_${index}`} props={props} theme={theme} style={styles.basicInfoWallet}>
          <Heading isCaps="true" style={commonStyles.secondaryHeader} scale={1}>
            {`AKTUÁLNA SUMA V ${walletData.wallet.name}`}
          </Heading>
          <View>
            <Heading style={commonStyles.primaryHeader} scale={4}>
              {formatCurrency(walletData.balance, { currency: '', decimalPlaces: 0 })}
            </Heading>
            <Heading style={commonStyles.primarySubHeader} scale={4}>
              {currency}
            </Heading>
          </View>
          <View style={commonStyles.linksWrapper}>
            {/*          <TextWithPopover
            text="Možnosti čerpania"
            style={commonStyles.links}
            popupContent={onClose => (
              <MyDrawingPossibilitiesContent title="Možnosti čerpania" onClose={onClose} />
            )}
          />
          <View props={props} theme={theme} style={commonStyles.linksDivider} /> */}
            <View style={commonStyles.links}>
              <TextWithPopover
                text="Moje transakcie"
                style={commonStyles.links}
                popupContent={onClose => (
                  <MyTransactionContent
                    title="Kreditné transakcie"
                    onClose={onClose}
                    params={{
                      userWalletId: walletData.id,
                      userWalletName: walletData.wallet.name
                    }}
                  />
                )}
              />
            </View>
          </View>
        </View>
      );
    }
    if (walletData && walletData.wallet && walletData.wallet.walletType === 'BONUS') {
      return (
        <View key={`wallet_${index}`} props={props} theme={theme} style={styles.basicInfoPoints}>
          <Heading isCaps="true" style={commonStyles.secondaryHeader} scale={1}>
            {`NAZBIERANÉ BODY V ${walletData.wallet.name}`}
          </Heading>
          <Heading style={commonStyles.primaryHeader} scale={4}>
            {walletData.balance}
          </Heading>
          <View style={commonStyles.linksWrapper}>
            {/*            <Link style={commonStyles.links} to="/">
              <Text style={commonStyles.links}>Možnosti čerpania</Text>
            </Link>
            <View props={props} theme={theme} style={commonStyles.linksDivider} /> */}
            <View style={commonStyles.links}>
              <TextWithPopover
                text="Moje transakcie"
                style={commonStyles.links}
                popupContent={onClose => (
                  <MyTransactionContent
                    title="Bonusové transakcie"
                    onClose={onClose}
                    params={{
                      userWalletId: walletData.id,
                      userWalletName: walletData.wallet.name
                    }}
                  />
                )}
              />
            </View>
          </View>
        </View>
      );
    }
    return <></>;
  });

const BasicInfoWidget = ({ props, theme, userWalletsSet, currency }) => (
  <View style={styles.basicInfoWidget}>
    <View props={props} theme={theme} style={styles.basicInfoDivider} />
    {generateWalletsComponents(props, theme, userWalletsSet, currency)}
  </View>
);

/* const BasicInfoWidget = ({ props, theme, data }) => (
  <View style={styles.basicInfoWidget}>
    <View props={props} theme={theme} style={styles.basicInfoDivider} />
    <View props={props} theme={theme} style={styles.basicInfoWallet}>
      <Heading style={commonStyles.secondaryHeader} scale={1}>
        {`AKTUÁLNA SUMA V $d`}
      </Heading>
      <View>
        <Heading style={commonStyles.primaryHeader} scale={4}>
          {data.walletValue}
        </Heading>
        <Heading style={commonStyles.primarySubHeader} scale={4}>
          {data.walletCurrency}
        </Heading>
      </View>
      <View style={commonStyles.linksWrapper}>
        <TextWithPopover
          text="Možnosti čerpania"
          style={commonStyles.links}
          popupContent={onClose => (
            <MyDrawingPossibilitiesContent
              title="Možnosti čerpania"
              params={data}
              onClose={onClose}
            />
          )}
        />
        <View props={props} theme={theme} style={commonStyles.linksDivider} />
        <View style={commonStyles.links}>
          <TextWithPopover
            text="Moje transakcie"
            style={commonStyles.links}
            popupContent={onClose => (
              <MyTransactionContent title="Kreditné transakcie" params={data} onClose={onClose} />
            )}
          />
        </View>
      </View>
    </View>
    <View props={props} theme={theme} style={styles.basicInfoPoints}>
      <Heading style={commonStyles.secondaryHeader} scale={1}>
        NAZBIERANÉ BODY
      </Heading>
      <Heading style={commonStyles.primaryHeader} scale={4}>
        {data.pointsValue}
      </Heading>
      <View style={commonStyles.linksWrapper}>
        <Link style={commonStyles.links} to="/">
          <Text style={commonStyles.links}>Možnosti čerpania</Text>
        </Link>
        <View props={props} theme={theme} style={commonStyles.linksDivider} />
        <Link style={commonStyles.links} to="/">
          <Text style={commonStyles.links}>Moje transakcie</Text>
        </Link>
      </View>
    </View>
  </View>
); */

export default BasicInfoWidget;
