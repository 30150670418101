// @flow
import View from 'components/View';
import React from 'react';
import { defaultTableRowRenderer as tableRowRendererVendor } from 'react-virtualized';
import Button from 'components/Button';
import type { Theme } from 'types';
import hoistNonReactStatic from 'hoist-non-react-statics';

const styles = {
  defaultRowRenderer: (
    { index, isRowSelected, isSelectable, rowsAlternation },
    {
      components: {
        Table: {
          Body: { Row, AlternateRow, BorderedRow, SelectedRow }
        }
      }
    }: Theme
  ) => {
    let rowType;
    if (isRowSelected) {
      rowType = { borderBottom: 'solid 1px', ...SelectedRow };
    } else if (rowsAlternation === 'background' && !(index % 2)) {
      rowType = { borderBottom: 'solid 1px transparent', ...AlternateRow };
    } else if (rowsAlternation === 'border') {
      rowType = { borderBottom: 'solid 1px', borderTop: 'solid 1px', ...BorderedRow };
    }

    return {
      cursor: isSelectable ? 'pointer' : 'default',
      display: 'flex',
      ...Row,
      ...rowType,

      '& .on-hover': {
        display: 'none'
      },
      '&:hover .on-hover': {
        display: 'flex'
      },

      '& > div': {
        flex: '1 0 100%'
      }
    };
  },
  actions: { position: 'absolute', right: 0 }
};

const RowRenderer = ({
  isRowSelected,
  isSelectable,
  rowsAlternation,
  style,
  decorators = {},
  ...props
}) => {
  // index was consumed by react-sortable-hoc
  const { index, _index } = props;
  const realIndex = index || _index || 0;

  return (
    <View
      style={styles.defaultRowRenderer}
      nativeProps={{ style }}
      index={realIndex}
      isRowSelected={isRowSelected}
      isSelectable={isSelectable}
      rowsAlternation={rowsAlternation}>
      {decorators.Before ? <decorators.Before {...props} style={style} index={realIndex} /> : null}
      {tableRowRendererVendor(props)}
      {decorators.After ? <decorators.After {...props} style={style} index={realIndex} /> : null}
    </View>
  );
};

RowRenderer.rowHeight = 40;

// perf:
// const RowRenderer = shouldUpdate((now, then) => {
//   return now.isRowSelected !== then.isRowSelected || now.style.width !== then.style.width
// })(RowRenderer1);

export const withActions = ({ isEnabled } = {}) => {
  const After = ({ index, actions = [] }) => (
    <View className="on-hover after" style={styles.actions}>
      {actions.map(({ icon, accent, onClick, predicate = null }) => {
        if (predicate && !predicate(index)) {
          return null;
        }
        return (
          <Button
            key={icon}
            icon={icon}
            accent={accent}
            onClick={e => onClick(e, index)}
            scale={-0.5}
          />
        );
      })}
    </View>
  );

  return BaseRowRenderer =>
    hoistNonReactStatic(({ isRowSelected, isSelectable, decorators, ...props }) => {
      if ((isEnabled && !isEnabled(props)) || props.isMoving) return <BaseRowRenderer {...props} />;

      return <BaseRowRenderer {...props} decorators={{ ...decorators, After }} />;
    }, BaseRowRenderer);
};

export default RowRenderer;
