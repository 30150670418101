// @flow
import React from 'react';
import { Style, Theme } from '../types';
import Stylo from './Stylo';

export type Props = {
  align?: string,
  fontFamily?: string,
  color?: string,
  weight?: number | string,
  scale?: number,
  style?: Style,
  variant?: undefined | 'small-caps',
  transform?: undefined | 'uppercase',
  title?: string,
  children: string,
  showEllipsis?: boolean
};

export const defaultFontFamily = 'Roboto';

export const getFontSize = ({ fontFamily = defaultFontFamily, scale = 0 }, theme: Theme): Style =>
  theme.typo[fontFamily].fontSize[scale];

export const getLineHeight = ({ fontFamily = defaultFontFamily, scale = 0 }, theme: Theme): Style =>
  theme.typo[fontFamily].lineHeight[scale];

export const textStyle = (props, theme: Theme): Style => {
  const { fontFamily = defaultFontFamily, color, weight, variant, transform, align } = props;

  const style = {
    ...theme.components.Text,
    fontFamily,
    fontSize: getFontSize(props, theme),
    fontWeight: theme.typo[fontFamily].weight[weight] || weight,
    lineHeight: getLineHeight(props, theme),
    fontVariant: variant,
    textTransform: transform,
    textAlign: align,
    ...(props.showEllipsis
      ? {
          display: 'block', // otherwise ellipsis may not be rendered
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '100%'
        }
      : null)
  };

  if (color) {
    style.color = color;
  }

  return style;
};

const Text = ({ style, title, ...restProps }: Props) => (
  <Stylo
    {...restProps}
    style={[textStyle, style]}
    as="span"
    nativeProps={title ? { title } : null}
  />
);

Text.defaultProps = {
  scale: 0,
  fontFamily: defaultFontFamily,
  weight: 'regular'
};

export default Text;
