// @flow
import { defaultTableHeaderRowRenderer } from 'react-virtualized';

const HeaderRowRenderer = defaultTableHeaderRowRenderer;

HeaderRowRenderer.rowHeight = 36;

// perf:
// const HeaderRowRenderer = shouldUpdate((now, then) => {
//   return now.style.width !== then.style.width
// })(defaultTableHeaderRowRenderer);

export default HeaderRowRenderer;
