import React from 'react';

const SvgMinus = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M4.8 7.9h10.5v4.2H4.8V7.9z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
);

export default SvgMinus;
