import React from 'react';

const SvgEuro = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M12.3 10.5H9.8v-1h2.6V8.4H9.8c.1-1.5.9-2.2 2.4-2.2.5 0 .9.1 1.4.2l.3-1.9c-.7-.2-1.3-.2-1.8-.2-1.3 0-2.4.4-3.2 1.1-.9.7-1.3 1.7-1.4 3H6.2v1.2h1.3v.9H6.2v1.2h1.3c0 1.3.5 2.3 1.2 3 .8.7 1.8 1.1 3.2 1.1.7 0 1.3-.1 2-.2l-.3-1.8c-.4.2-.9.2-1.4.2-.8 0-1.4-.2-1.7-.6-.4-.4-.6-.9-.6-1.7h2.6v-1.2z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEuro;
