// @flow
import Icon from 'components/Icon';
import Text from 'components/Text';
import View from 'components/View';
import { connect as connectFormik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Style, Theme } from '../types';
import ClickableOpacity from './ClickableOpacity';

export type Props = {
  onClick?: React.MouseEventHandler,
  scale?: number,
  style?: Style,
  disabled?: boolean,
  icon?: string,
  label?: string,
  accent?: 'primary' | 'secondary' | 'warning' | 'danger' | 'plain' | 'transparent',
  type?: 'submit' | 'button',
  weight?: string
};

export const styles = {
  button: (
    { accent, disabled, scale, icon, label },
    { baseSize, components: { Button: ButtonTheme } }: Theme
  ) => {
    const paddingVertical = scale < 0 ? baseSize * 2.5 : baseSize * 4;
    const paddingHorizontal = scale < 0 ? baseSize * 6 : baseSize * 8;

    return {
      textAlign: 'center',
      justifyContent: 'center',
      border: 'none',
      paddingVertical,
      paddingHorizontal: icon && !label ? paddingVertical : paddingHorizontal,
      cursor: 'pointer',
      opacity: disabled ? 0.3 : 1,
      filter: 'contrast(100%)',
      transition: 'filter 50ms linear',
      ...(accent ? ButtonTheme[accent] : null)
    };
  },
  icon: ({ hasLabel }, { baseSize }: Theme) => ({
    marginLeft: hasLabel ? baseSize * -1 * 2 : 0,
    marginRight: hasLabel ? baseSize * 2 : 0
  })
};

export const ButtonIcon = ({ icon, label, scale, fontFamily = 'PT Sans Caption' }) => (
  <View hasLabel={!!label} style={styles.icon}>
    <Icon name={icon} fontFamily={fontFamily} scale={scale} />
  </View>
);

export const ButtonLabel = ({ weight, label, scale, fontFamily = 'PT Sans Caption' }) => (
  <Text weight={weight} fontFamily={fontFamily} scale={scale} color="inherit">
    {label}
  </Text>
);

const Button = ({
  accent,
  icon,
  label,
  scale,
  onClick,
  disabled,
  style,
  fontFamily,
  type,
  weight,
  ...restProps
}: Props) => (
  <ClickableOpacity onClick={onClick} disabled={disabled} tabIndex={-1} type={type}>
    <View
      accent={accent}
      icon={icon}
      label={label}
      scale={scale}
      disabled={disabled}
      style={[styles.button, style]}
      nativeProps={{ tabIndex: disabled ? -1 : 0, role: 'button' }}
      {...restProps}>
      {icon ? <ButtonIcon icon={icon} label={label} scale={scale} fontFamily={fontFamily} /> : null}
      {label ? (
        <ButtonLabel label={label} scale={scale} fontFamily={fontFamily} weight={weight} />
      ) : null}
    </View>
  </ClickableOpacity>
);

Button.defaultProps = {
  accent: 'primary',
  fontFamily: 'PT Sans Caption',
  scale: 0
};

const SubmitButton = connectFormik(({ formik: { isSubmitting }, ...props }) => (
  <Button {...props} disabled={isSubmitting} type="submit" />
));

const DeleteButton = ({
  confirmMessage,
  mutation,
  variables,
  refetchQueries,
  onSuccess,
  onError
}) => (
  <Mutation
    mutation={mutation}
    variables={variables}
    onCompleted={onSuccess}
    onError={onError}
    refetchQueries={refetchQueries}>
    {(action, { client }) => (
      <Button
        icon="trash"
        accent="danger"
        onClick={() => {
          // eslint-disable-next-line no-alert
          const confirmed = window.confirm(confirmMessage);
          if (confirmed) {
            action().then(() => {
              window.client = client;
            });
          }
        }}
      />
    )}
  </Mutation>
);

export { SubmitButton, DeleteButton };

export default Button;
