// @flow
import View from 'components/View';
import React from 'react';
import type { Props as TagProps } from './Tag';

type Props = {
  children: React.Component<TagProps>
};

const styles = {
  box: (props, { baseSize }) => ({
    maxWidth: '100%',
    '& > *:nth-child(n)': {
      marginHorizontal: baseSize
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  })
};

const TagsGroup = ({ children }: Props) => <View style={styles.box}>{children}</View>;

export default TagsGroup;
