// @flow

import React from 'react';
import type { Style } from '../../types';
import View from '../../components/View';

type InactiveBgProps = {
  onClose?: () => void,
  style?: Style
};

const defaultStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

const className = 'inactive-bg';

const close = onClose => ({ target }) => {
  const clickedOnBg = target.classList.contains(className) || target.closest(className);

  if (clickedOnBg && onClose) {
    onClose();
  }
};

const InactiveBg = ({ onClose, style }: InactiveBgProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  <View
    style={[defaultStyle, style]}
    className={className}
    onClick={close(onClose)}
    nativeProps={{ onClick: onClose }}
  />
);

export default InactiveBg;
