// @flow
import React from 'react';
import { Route, Redirect } from 'react-router';
import { AUTH_TOKEN } from 'common/constants';
import { withCookies } from 'react-cookie';
import { branch, renderComponent, compose } from 'recompose';
import { homePath, salesPagePath } from '../app/routerPaths';

const isLoggedIn = is => ({ cookies }) => !!cookies.get(AUTH_TOKEN) === is;

const NotLoggedIn = ({ location }) => (
  <Redirect to={{ pathname: homePath, state: { from: location } }} />
);

const LoggedIn = ({ redirect }) => <Redirect to={redirect || salesPagePath} />;

export const PrivateRoute = compose(
  withCookies,
  branch(isLoggedIn(false), renderComponent(NotLoggedIn))
)(Route);

export const PublicRoute = compose(
  withCookies,
  branch(isLoggedIn(true), renderComponent(LoggedIn))
)(Route);
