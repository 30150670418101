// @flow

import ScrollView from 'components/ScrollView';
import View from 'components/View';
import * as React from 'react';

const styles = {
  box: {
    flexDirection: 'column',
    flexGrow: 1,
    margin: 22
  },
  layout: {
    paddingHorizontal: 12,
    flexDirection: 'column',
    flexGrow: 1
  }
};

const FocusContent = ({ children }) => (
  <View style={styles.layout}>
    <ScrollView style={styles.box}>{children}</ScrollView>
  </View>
);

export default FocusContent;
