// @flow
import Text, { defaultFontFamily } from 'components/Text';
import View from 'components/View';
import React from 'react';
import type { Theme } from 'types';

export type Props = {
  children: string,
  accent?: 'primary' | 'secondary' | 'plain'
};

const styles = {
  box: ({ accent }, { typo, baseSize, components: { Tag }, borderRadius }: Theme) => ({
    background: Tag[accent].background,
    height: typo[defaultFontFamily].lineHeight[-0.5],
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 'auto',
    marginHorizontal: baseSize * 2,
    paddingHorizontal: baseSize,
    borderRadius: borderRadius.normal,
    maxWidth: '100%'
  }),
  text: ({ accent }, { components: { Tag } }: Theme) => ({
    color: Tag[accent].color
  })
};

const Tag = ({ children, accent }: Props) => (
  <View style={styles.box} isVertical accent={accent}>
    <Text
      style={styles.text}
      accent={accent}
      scale={-1.5}
      weight="bold"
      transform="uppercase"
      showEllipsis>
      {children}
    </Text>
  </View>
);

Tag.defaultProps = {
  accent: 'secondary'
};

export default Tag;
