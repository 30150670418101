import React from 'react';

const SvgFile = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M15.7 17.9H4.3V2.1h7.6l.3.3 3.2 3.2.3.3v12zM5.3 3v14h9.4V7.1c0-.6-.5-.9-1.2-.9-.6 0-1.3 0-1.8.1l-.3.1v-.2c0-.2.1-.7.1-1.1 0-.4 0-1-.1-1.4-.1-.4-.4-.7-.7-.7H5.3z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFile;
