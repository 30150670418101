import React from 'react';
import './spinner.css';

const SvgSpinner = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" className="c-spinner-animation">
    <path
      fill="currentColor"
      d="M15.8 10.818c0-.234.08-.468.08-.779 0-.312 0-.545-.08-.78l1.661-1.323c.158-.156.158-.312.079-.467l-1.582-2.726c-.078-.078-.237-.156-.474-.078l-1.976.779c-.396-.312-.87-.546-1.344-.78l-.316-2.025c.078-.233-.159-.389-.317-.389H8.37c-.158 0-.395.156-.395.312l-.317 2.103c-.474.155-.87.467-1.344.779l-1.897-.78c-.238-.077-.395 0-.554.157l-1.58 2.726c-.08.078 0 .312.157.467l1.66 1.246c0 .234-.078.467-.078.78 0 .31 0 .545.078.778l-1.66 1.324c-.158.156-.158.312-.079.468l1.581 2.726c.08.078.237.155.475.078l1.977-.78c.395.312.869.546 1.343.78l.317 2.025c0 .155.158.311.395.311h3.162c.158 0 .396-.156.396-.311l.316-2.025c.474-.234.949-.468 1.344-.78l1.976.78c.159.077.396 0 .475-.156l1.581-2.726c.079-.156.079-.39-.08-.468l-1.817-1.246zm-5.85 1.947c-1.502 0-2.767-1.246-2.767-2.726S8.448 7.313 9.95 7.313s2.767 1.247 2.767 2.726c0 1.48-1.265 2.726-2.767 2.726z"
    />
  </svg>
);

export default SvgSpinner;
