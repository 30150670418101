import React from 'react';

const SvgBarCode2 = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M4 2.8H1.6c-.3 0-.6.2-.6.6v2.4c0 .3.3.6.6.6s.6-.3.6-.6V4H4c.3 0 .6-.3.6-.6 0-.4-.3-.6-.6-.6zm14.4 10.8c-.3 0-.6.3-.6.6V16H16c-.3 0-.6.3-.6.6s.3.6.6.6h2.4c.3 0 .6-.3.6-.6v-2.4c0-.3-.3-.6-.6-.6zm0-10.8H16c-.3 0-.6.3-.6.6s.3.6.6.6h1.8v1.8c0 .3.3.6.6.6s.6-.3.6-.6V3.4c0-.4-.3-.6-.6-.6zM4 16H2.2v-1.8c0-.3-.3-.6-.6-.6s-.6.3-.6.6v2.4c0 .3.3.6.6.6H4c.3 0 .6-.3.6-.6S4.3 16 4 16zM4 5.2c-.3 0-.6.3-.6.6v8.5c0 .3.3.6.6.6s.6-.3.6-.6V5.8c0-.4-.3-.6-.6-.6zm2.4 7.2c.3 0 .6-.3.6-.6v-6c0-.3-.3-.6-.6-.6s-.6.3-.6.6v6c0 .3.3.6.6.6zm2.4 0c.3 0 .6-.3.6-.6v-6c0-.3-.3-.6-.6-.6s-.6.2-.6.6v6c0 .3.3.6.6.6zm2.4-7.2c-.3 0-.6.3-.6.6v8.5c0 .3.3.6.6.6s.6-.3.6-.6V5.8c0-.4-.3-.6-.6-.6zm2.4 7.2c.3 0 .6-.3.6-.6v-6c0-.3-.3-.6-.6-.6s-.6.3-.6.6v6c0 .3.3.6.6.6zm2.4 2.4c.3 0 .6-.3.6-.6V5.8c0-.3-.3-.6-.6-.6s-.6.3-.6.6v8.5c0 .3.3.5.6.5zm-9.6-1.2c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.2-.6-.6-.6zm2.4 0c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.2-.6-.6-.6zm4.8 0c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.2-.6-.6-.6z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBarCode2;
