// @flow
import React from 'react';

type RenderProps = {
  client: any
};

export type QueryClientContextType = React.Component<RenderProps>;

const QueryClientContext = React.createContext<QueryClientContextType>();

export default QueryClientContext;
