/* eslint-disable react/destructuring-assignment,no-nested-ternary */
import * as React from 'react';
import { path } from 'rambda';
import { connect } from 'formik';

/**
 * Based on upstream Formik, added status for server-validation errors
 */
class ErrorMessage extends React.Component {
  shouldComponentUpdate(props) {
    if (
      path(this.props.name, this.props.formik.errors) !==
        path(this.props.name, props.formik.errors) ||
      path(this.props.name, this.props.formik.touched) !==
        path(this.props.name, props.formik.touched) ||
      path(this.props.name, this.props.formik.status) !==
        path(this.props.name, props.formik.status) ||
      Object.keys(this.props).length !== Object.keys(props).length
    ) {
      return true;
    }

    return false;
  }

  _renderError = (message, rest) => {
    const { component } = this.props;

    return React.createElement(component, rest, message);
  };

  render() {
    const { component, formik, name, ...rest } = this.props;

    if (component) {
      const touch = path(name, formik.touched);
      const error = path(name, formik.errors);
      const status = path(name, formik.status);

      if (touch && error) {
        return this._renderError(error, rest);
      }
      if (status) {
        return this._renderError(status, rest);
      }
    }

    return null;
  }
}

export default connect(ErrorMessage);
