import React from 'react';

const SvgPencil = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M16.27 5.924l-2.217-2.199 1.878-1.877 2.208 2.198-1.869 1.878zM5.855 16.33l-3.994 1.822 1.796-4.012 9.398-9.416.623.613-7.164 7.154.596.596 7.172-7.163.99 1.007-9.417 9.399zm-1.182-1.988l-.769 1.768 1.722-.806.889-.862-.825-.137-.137-.824-.88.86z"
    />
  </svg>
);

export default SvgPencil;
