// @flow
import React from 'react';
import { Query } from 'react-apollo';

type RenderProps = {
  query: string, // or gql
  children: ({ loading: boolean, error: any, data: any }) => React.Component
};

export type QueryServiceContextType = React.Component<RenderProps>;

// may be a right thing to do (https://github.com/apollographql/react-apollo/issues/2576)
// Query.defaultProps = { ...Query.defaultProps, partialRefetch: true };

const QueryServiceContext = React.createContext<QueryServiceContextType>(Query);

export default QueryServiceContext;
