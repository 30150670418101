/* eslint-disable */
export const elHasParent = (el, parent) => {
  let parentEl = el;
  do {
    if (parentEl === parent) {
      return true;
    }
  } while ((parentEl = parentEl.parentNode));

  return false;
};
