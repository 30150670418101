import placeholderPrefixer from 'fela-plugin-placeholder-prefixer';
import validator from 'fela-plugin-validator';
import { createRenderer } from 'fela';
import fallbackValue from 'fela-plugin-fallback-value';
import prefixer from 'fela-plugin-prefixer';
import monolithic from 'fela-monolithic';
import unit from 'fela-plugin-unit';
import customProperty from 'fela-plugin-custom-property';
import { coreEasing } from 'utils/transitions';

const staticStyles = `
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&subset=latin-ext');
  @import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700&subset=latin-ext');

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  body {
    margin: 0;
    overflow: hidden;
  }
  html, body, #root {
    height: 100%;
  }
  html, body, #loyalman_external_root {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 99999;
  }
  a {
    -webkit-text-decoration-skip: objects;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  textarea {
    overflow: auto;
    resize: none;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  [role="button"] {
    cursor: pointer;
    outline: none;
  }
  a,
  area,
  button,
  [role="button"],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
    padding: 0;
    margin: 0;
    border: none;
  }
  
  a {
    text-decoration: none;
  }
  
  button:disabled {
    pointer-events: none
  }
  
  a:focus,
  .js-focus-visible :focus:not(.focus-visible),
  input:focus,
  button:focus,
  [role=button]:focus {
    outline: none;
  }
`;

const vendorGlobals = `
  :root {
    --animated-switch-transition-easing: ${coreEasing} !important;
    --animated-switch-transition-speed: 500ms !important;
    --overlay-modal-transition-easing: ${coreEasing} !important;
    --overlay-modal-transition-speed: 500ms !important;
  }
`;

const configureFela = () => {
  const plugins = [
    customProperty({
      paddingVertical: size => ({ paddingTop: size, paddingBottom: size }),
      paddingHorizontal: size => ({ paddingLeft: size, paddingRight: size }),
      marginVertical: size => ({ marginTop: size, marginBottom: size }),
      marginHorizontal: size => ({ marginLeft: size, marginRight: size })
    }),
    unit(),
    placeholderPrefixer(),
    prefixer(),
    fallbackValue()
  ];

  const enhancers = [];

  if (process.env.NODE_ENV !== 'production') {
    plugins.unshift(validator({ logInvalid: true }));
    enhancers.unshift(monolithic({ prettySelectors: false }));
  }

  const renderer = createRenderer({
    plugins,
    enhancers,
    devMode: process.env.NODE_ENV !== 'production',
    selectorPrefix: '_'
  });

  renderer.renderStatic(staticStyles);
  renderer.renderStatic(vendorGlobals);

  return renderer;
};

export default configureFela;
