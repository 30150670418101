// @flow

import React from 'react';
import type { Style } from '../../../types';
import Text from '../../../components/Text';
import withPopover from '../hoc/withPopover';

type Props = {
  text: string,
  style?: Style,
  popupContent: () => any,
  params?: {}
};

const popupContentStyle = {
  backgroundColor: 'transparent',
  position: 'fixed',
  top: '0',
  left: '0',
  transform: 'translate(calc(50vw - 50%), calc(50vh - 50%))'
};

const TextWithPopoverCore = withPopover({
  hideOnScroll: false,
  contentStyle: popupContentStyle,
  floatOptions: {}
})(Text);

const TextWithPopover = ({ text, style, popupContent }: Props) => (
  <TextWithPopoverCore Content={({ onClose }) => <>{popupContent(onClose)}</>} style={style}>
    {text}
  </TextWithPopoverCore>
);

export default TextWithPopover;
