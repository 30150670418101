import React from 'react';

const SvgCheckbox = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M7.13 14.755l-1.885-1.884L8.092 10h.015L5.245 7.136l1.884-1.892L10 8.115l2.863-2.87 1.893 1.892L11.885 10h.015l2.855 2.87-1.893 1.885L10 11.893l-2.87 2.862z"
    />
  </svg>
);

export default SvgCheckbox;
