// @flow

import { CheckboxFormFast } from 'components/Checkbox';
import { LabelForInput } from 'components/Label';
import View from 'components/View';
import React from 'react';
import camelCase from 'lodash.camelcase';
import { filter, findIndex, map, reject, update, pathOr, reduce } from 'rambda';

export const submitOnClick = ({ setFieldValue, handleSubmit, submitType }) => e => {
  e.preventDefault();

  if (window.location.href === e.currentTarget.href) return;

  setFieldValue(
    'submitMeta',
    { type: submitType, href: e.currentTarget.getAttribute('href') },
    false
  );

  handleSubmit();
};

const months = [
  'Január',
  'Február',
  'Marec',
  'Apríl',
  'Máj',
  'Jún',
  'Júl',
  'August',
  'September',
  'Október',
  'November',
  'December'
];

const days = ['Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota', 'Nedeľa'];

const styles = {
  colsMonths: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(6, 1fr)',
    gridAutoFlow: 'column',
    gridColumnGap: 20,
    gridRowGap: 15
  },
  colsDays: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    gridAutoFlow: 'column',
    gridColumnGap: 20,
    gridRowGap: 15
  }
};

export const MonthsForm = ({ name, labelWidth }) => (
  <View isExpanded>
    <LabelForInput alignSelf="flex-start" width={labelWidth} noMarginTop>
      Mesiace
    </LabelForInput>

    <View style={styles.colsMonths}>
      {months.map((month, i) => (
        <CheckboxFormFast key={i} name={name} value={i + 1} label={month} />
      ))}
    </View>
  </View>
);

export const DaysForm = ({ name, labelWidth }) => (
  <View isExpanded>
    <LabelForInput alignSelf="flex-start" width={labelWidth} noMarginTop>
      Dni
    </LabelForInput>

    <View style={styles.colsDays}>
      {days.map((day, i) => (
        <CheckboxFormFast key={i} name={name} value={i + 1} label={day} />
      ))}
    </View>
  </View>
);

const clearLocal = ({ __local, __canSubmit, __dirty, __priorityOnly, ...rest }) => {
  if (__local) {
    delete rest.id; // eslint-disable-line no-param-reassign
    delete rest.record; // eslint-disable-line no-param-reassign
  }

  return rest;
};

const canRmLocal = ({ __local, delete: toDelete }) => __local && toDelete;

// todo get rid of node
export const clearLocalFlags = (arr: []) => {
  const clearLocalDeleted = reject(
    something => (something.node ? canRmLocal(something.node) : canRmLocal(something)),
    arr
  );

  return map(
    something => (something.node ? clearLocal(something.node) : clearLocal(something)),
    clearLocalDeleted
  );
};

export const mergeEscalateValueWithLocals = (escalateValue: Object, locals = []) => {
  if (escalateValue.id) {
    const index = findIndex(something => something.id === escalateValue.id, locals);

    if (index !== -1) {
      return update(index, escalateValue, locals);
    }
  }

  return [escalateValue, ...locals];
};

export const removeFromValues = (id, values: [], defaultValue) => {
  const index = findIndex(something => something.id === id, values);

  return index === -1
    ? [{ id, ...defaultValue, delete: true }, ...values]
    : map(
        something =>
          something.id === id
            ? {
                ...something,
                delete: true
              }
            : something,
        values
      );
};

export const undeleted = (arr: []) => filter(({ delete: isDeleted }) => !isDeleted, arr);

export const ascPriority = (values: [], { markChanged = true } = {}) =>
  map(
    (value, i) => ({
      ...value,
      ...(markChanged && !value.__dirty ? { __priorityOnly: true } : null),
      priority: i + 1
    }),
    values
  );

export const otherFormActions = () => {
  let _otherFormActions;

  return {
    withFormActions: (actions, cb) => {
      _otherFormActions = actions;
      cb();
    },
    getOtherForm: () => _otherFormActions
  };
};

export const serverInternalError = error => {
  const errors = pathOr([{}], 'networkError.result.errors', error);

  return errors[0].message;
};

export const serverValidationErrors = error => {
  const errors = reduce(
    (acc, err) => {
      acc[camelCase(err.field)] = err.message;
      return acc;
    },
    {},
    error.graphQLErrors
  );

  return Object.keys(errors).length ? errors : undefined;
};
