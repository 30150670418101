// @flow
import React from 'react';
import Text, { Props as TextProps } from './Text';

export type Props = TextProps & {
  color?: string,
  scale?: number,
  isCaps?: boolean,
  children: string
};

const styles = {
  heading: {
    flexShrink: 0
  },
  altHeading: (props, { components: { SaleAltHeader } }) => ({
    ...SaleAltHeader,
    flexShrink: 0,
    marginBottom: 5
  })
};

const Heading = ({ style, isCaps, color, scale = 0, showEllipsis = true, children }: Props) => (
  <Text
    color={color}
    scale={scale}
    variant={isCaps ? 'small-caps' : undefined}
    transform={isCaps ? 'uppercase' : undefined}
    fontFamily="PT Sans Caption"
    showEllipsis={showEllipsis}
    weight="bold"
    style={[styles.heading, style]}>
    {children}
  </Text>
);

export const AltHeading = ({ style, children }) => (
  <Text scale={-1} transform="uppercase" weight="bold" style={[styles.altHeading, style]}>
    {children}
  </Text>
);

export default Heading;
