export const homePath = '/';
export const appSettingsPath = '/global-settings';

export const salesPagePath = '/sales';
export const salesPageInfoPath = '/sales/:id';
export const salesPagePreselectedPath = '/sales/:id?';
export const salesPageDetailPath = '/sales/:id/edit';
export const salesPageDetailDefinitionPath = '/sales/:id/edit/definition';
export const salesPageDetailGlobalDiscountsPath = '/sales/:id/edit/global-discounts';
export const salesPageDetailCustomerDiscountsPath = '/sales/:id/edit/customer-discounts';
export const salesPageDetailCustomersPath = '/sales/:id/edit/customers';
export const salesPageDetailBonusPath = '/sales/:id/edit/bonus';
export const salesPageDetailCreditPath = '/sales/:id/edit/credit';
export const salesPageFocusPath = '/sales/:id/edit/focus/:tab';
export const salesPageFocusGlobalDiscountPath = '/sales/:id/edit/global-discounts/focus/:ruleId';
export const salesPageFocusGlobalDiscountDefinitionPath =
  '/sales/:id/edit/global-discounts/focus/:ruleId/definition';
export const salesPageFocusGlobalDiscountDatePath =
  '/sales/:id/edit/global-discounts/focus/:ruleId/date';
export const salesPageFocusGlobalDiscountPriceListPath =
  '/sales/:id/edit/global-discounts/focus/:ruleId/price-list';
export const salesPageFocusGlobalDiscountGroupsPath =
  '/sales/:id/edit/global-discounts/focus/:ruleId/groups';
export const salesPageFocusGlobalDiscountPaymentsPath =
  '/sales/:id/edit/global-discounts/focus/:ruleId/itemGroups';
export const salesPageFocusCustomerDiscountPath =
  '/sales/:id/edit/customer-discounts/focus/:ruleId';
export const salesPageFocusCustomerDiscountDefinitionPath =
  '/sales/:id/edit/customer-discounts/focus/:ruleId/definition';
export const salesPageFocusCustomerDiscountDatePath =
  '/sales/:id/edit/customer-discounts/focus/:ruleId/date';
export const salesPageFocusCustomerDiscountPriceListPath =
  '/sales/:id/edit/customer-discounts/focus/:ruleId/price-list';
export const salesPageFocusCustomerDiscountGroupsPath =
  '/sales/:id/edit/customer-discounts/focus/:ruleId/groups';
export const salesPageFocusCustomerDiscountPaymentsPath =
  '/sales/:id/edit/customer-discounts/focus/:ruleId/itemGroups';

export const salesPageFocusBonusPath = '/sales/:id/edit/bonus/focus/:walletId/:ruleId';
export const salesPageFocusBonusDefinitionPath =
  '/sales/:id/edit/bonus/focus/:walletId/:ruleId/definition';
export const salesPageFocusBonusDatePath = '/sales/:id/edit/bonus/focus/:walletId/:ruleId/date';
export const salesPageFocusBonusPriceListPath =
  '/sales/:id/edit/bonus/focus/:walletId/:ruleId/price-list';
export const salesPageFocusBonusGroupsPath = '/sales/:id/edit/bonus/focus/:walletId/:ruleId/groups';
export const salesPageFocusBonusPaymentsPath =
  '/sales/:id/edit/bonus/focus/:walletId/:ruleId/itemGroups';
export const salesPageFocusCreditPath = '/sales/:id/edit/credit/focus/:walletId/:ruleId';
export const salesPageFocusCreditDefinitionPath =
  '/sales/:id/edit/credit/focus/:walletId/:ruleId/definition';
export const salesPageFocusCreditDatePath = '/sales/:id/edit/credit/focus/:walletId/:ruleId/date';
export const salesPageFocusCreditPriceListPath =
  '/sales/:id/edit/credit/focus/:walletId/:ruleId/price-list';
export const salesPageFocusCreditGroupsPath =
  '/sales/:id/edit/credit/focus/:walletId/:ruleId/groups';
export const salesPageFocusCreditPaymentsPath =
  '/sales/:id/edit/credit/focus/:walletId/:ruleId/itemGroups';

export const customersPagePath = '/customers';
export const customersPagePreselectedPath = '/customers/:id?';
export const customersPageInfoPath = '/customers/:id';
export const customersPageFocusPath = '/customers/:id/edit/focus';
export const customersPageFocusBasicPath = '/customers/:id/edit/focus/basic';
export const customersPageFocusExtendedPath = '/customers/:id/edit/focus/extended';
export const customersPageFocusPackagesPath = '/customers/:id/edit/focus/packages';
export const customersPageFocusCardsPath = '/customers/:id/edit/focus/cards';
export const customersPageFocusTransactionsPath = '/customers/:id/edit/focus/transactions';
export const customersPageWalletTransferPath = '/customers/:id/wallet/transfer';

export const usersPagePath = '/users';
export const usersPagePreselectedPath = '/users/:id?';
export const usersPageInfoPath = '/users/:id';
export const usersPageFocusPath = '/users/:id/edit/focus';
export const usersPageFocusBasicPath = '/users/:id/edit/focus/basic';
export const usersPageFocusExtendedPath = '/users/:id/edit/focus/extended';
export const usersPageFocusPackagesPath = '/users/:id/edit/focus/packages';
export const usersPageFocusCardsPath = '/users/:id/edit/focus/cards';
export const usersPageFocusPermissionsPath = '/users/:id/edit/focus/permissions';
export const usersPageFocusTransactionsPath = '/users/:id/edit/focus/transactions';

export const transactionsPagePath = '/transactions';
export const transactionsPagePreselectedPath = '/transactions/:id?';
export const transactionsPageInfoPath = '/transactions/:id';
export const transactionsPageFocusPath = '/transactions/:id/edit/focus/';

export const cardsPagePath = '/cards';
export const cardsPagePreselectedPath = '/cards/:id?';
export const cardsPageMultiInfoPath = '/cards/multi';
export const cardsPageInfoPath = '/cards/:id';
export const cardsPageFocusPath = '/cards/:id/edit/focus';

export const changeHistoriesPagePath = '/changeHistories';

export const productPrepaymentsPagePath = '/product-prepayments';
export const productPrepaymentsPagePreselectedPath = '/product-prepayments/:id?';
export const productPrepaymentsPageInfoPath = '/product-prepayments/:id';
export const productPrepaymentsPageFocusPath = '/product-prepayments/:id/edit/focus';
export const productPrepaymentsPageFocusDefinitionPath =
  '/product-prepayments/:id/edit/focus/definition';
export const productPrepaymentsPageFocusDepositPath = '/product-prepayments/:id/edit/focus/deposit';
export const productPrepaymentsPageFocusWithdrawalPath =
  '/product-prepayments/:id/edit/focus/withdrawal';

export const vouchersPagePath = '/vouchers';
export const vouchersPagePreselectedPath = '/vouchers/:id?';
export const vouchersPageMultiInfoPath = '/vouchers/multi';
export const vouchersPageInfoPath = '/vouchers/:id';
export const vouchersPageFocusPath = '/vouchers/:id/edit/focus/';

export const discountPackagesPagePath = '/discount-packages/';
export const discountPackagesPagePreselectedPath = '/discount-packages/:id?';
export const discountPackagesPageInfoPath = '/discount-packages/:id';
export const discountPackagesPageDetailPath = '/discount-packages/:id/edit';
export const discountPackagesPageDetailDefinitionPath = '/discount-packages/:id/edit/definition';
export const discountPackagesPageDetailDiscountPath = '/discount-packages/:id/edit/discounts';
export const discountPackagesPageFocusDiscountPath =
  '/discount-packages/:id/edit/discounts/focus/:ruleId';
export const discountPackagesPageFocusDiscountDefinitionPath =
  '/discount-packages/:id/edit/discounts/focus/:ruleId/definition';
export const discountPackagesPageFocusDiscountDatePath =
  '/discount-packages/:id/edit/discounts/focus/:ruleId/date';
export const discountPackagesPageFocusDiscountPriceListPath =
  '/discount-packages/:id/edit/discounts/focus/:ruleId/price-list';
export const discountPackagesPageFocusDiscountGroupsPath =
  '/discount-packages/:id/edit/discounts/focus/:ruleId/groups';
export const discountPackagesPageFocusDiscountPaymentsPath =
  '/discount-packages/:id/edit/discounts/focus/:ruleId/itemGroups';
export const discountPackagesPageDetailCreditPath = '/discount-packages/:id/edit/credit';
export const discountPackagesPageFocusCreditPath =
  '/discount-packages/:id/edit/credit/focus/:walletId/:ruleId';
export const discountPackagesPageFocusCreditDefinitionPath =
  '/discount-packages/:id/edit/credit/focus/:walletId/:ruleId/definition';
export const discountPackagesPageFocusCreditDatePath =
  '/discount-packages/:id/edit/credit/focus/:walletId/:ruleId/date';
export const discountPackagesPageFocusCreditPriceListPath =
  '/discount-packages/:id/edit/credit/focus/:walletId/:ruleId/price-list';
export const discountPackagesPageFocusCreditGroupsPath =
  '/discount-packages/:id/edit/credit/focus/:walletId/:ruleId/groups';
export const discountPackagesPageFocusCreditPaymentsPath =
  '/discount-packages/:id/edit/credit/focus/:walletId/:ruleId/itemGroups';
export const discountPackagesPageDetailBonusPath = '/discount-packages/:id/edit/bonus';
export const discountPackagesPageFocusBonusPath =
  '/discount-packages/:id/edit/bonus/focus/:walletId/:ruleId';
export const discountPackagesPageFocusBonusDefinitionPath =
  '/discount-packages/:id/edit/bonus/focus/:walletId/:ruleId/definition';
export const discountPackagesPageFocusBonusDatePath =
  '/discount-packages/:id/edit/bonus/focus/:walletId/:ruleId/date';
export const discountPackagesPageFocusBonusPriceListPath =
  '/discount-packages/:id/edit/bonus/focus/:walletId/:ruleId/price-list';
export const discountPackagesPageFocusBonusGroupsPath =
  '/discount-packages/:id/edit/bonus/focus/:walletId/:ruleId/groups';
export const discountPackagesPageFocusBonusPaymentsPath =
  '/discount-packages/:id/edit/bonus/focus/:walletId/:ruleId/itemGroups';

export const settingsPagePath = '/settings';
