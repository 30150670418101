// @flow

import Text from 'components/Text';
import * as React from 'react';

const styles = {
  default: ({ withLabel }, { baseSize, components: { ValidationError } }) => ({
    ...ValidationError,
    marginTop: baseSize,
    marginLeft: withLabel ? 150 : 0
  })
};

const ValidationError = ({ children }) => (
  <Text style={styles.default} weight="bold">
    {children}
  </Text>
);

const ValidationErrorWithLabel = ({ children }) => (
  <Text style={styles.default} withLabel weight="bold">
    {children}
  </Text>
);

export { ValidationErrorWithLabel };

export default ValidationError;
