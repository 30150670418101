// @flow

import React from 'react';
import FloatAnchor from 'react-float-anchor';
import throttle from 'lodash.throttle';
import { elHasParent } from 'utils/dom';
import Popover from '../Popover';

const style = {
  popover: {
    marginTop: 10
  }
};

const floatOptions = { position: 'bottom', hAlign: 'center' };

const withPopover = ({ hideOnScroll = true, withTipPosition = false } = {}) => BaseComponent =>
  class extends React.Component {
    state = {
      isActive: false,
      tipStyle: { alignSelf: 'center' },
      tipPosition: 'top'
    };

    componentDidMount() {
      window.addEventListener('click', this.onGlobalClick);

      if (hideOnScroll) {
        window.addEventListener('scroll', this.onScroll, true);
      }
    }

    componentDidUpdate() {
      const { tipStyle, tipPosition } = this.state;
      if (withTipPosition && this.trigger && this.popover) {
        const popoverRect = this.popover.getBoundingClientRect();
        const triggerRect = this.trigger.getBoundingClientRect();

        const popoverPositionX = Math.floor(popoverRect.x + popoverRect.width / 2);
        const triggerPositionX = Math.floor(triggerRect.x + triggerRect.width / 2);
        const popoverPositionY = Math.floor(popoverRect.y + popoverRect.height / 2);
        const triggerPositionY = Math.floor(triggerRect.y + triggerRect.height / 2);

        if (popoverPositionX < triggerPositionX) {
          if (!tipStyle || tipStyle.alignSelf !== 'flex-end') {
            this.setState({ tipStyle: { alignSelf: 'flex-end', marginRight: '15px' } });
          }
        } else if (popoverPositionX > triggerPositionX) {
          if (!tipStyle || tipStyle.alignSelf !== 'flex-start') {
            this.setState({ tipStyle: { alignSelf: 'flex-start', marginLeft: '15px' } });
          }
        } else if (!tipStyle || tipStyle.alignSelf !== 'center') {
          this.setState({ tipStyle: { alignSelf: 'center', marginLeft: '0px' } });
        }

        if (triggerPositionY > popoverPositionY && tipPosition === 'top') {
          this.setState({ tipPosition: 'bottom' });
        }
      }
    }

    componentWillUnmount() {
      window.removeEventListener('click', this.onGlobalClick);

      if (hideOnScroll) {
        window.removeEventListener('scroll', this.onScroll, true);
      }
    }

    // eslint-disable-next-line react/sort-comp
    close = e => {
      if (e) {
        const { target } = e;
        if (target.closest('.filters-prevent-hide')) return;
      }

      const { isActive } = this.state;

      if (isActive) {
        this.setState({ isActive: false });
      }
    };

    onGlobalClick = ({ target }) => {
      const { isActive } = this.state;

      if (isActive) {
        if (!elHasParent(target, this.popover)) {
          this.setState({ isActive: false });
        }
      } else if (elHasParent(target, this.trigger)) {
        this.setState({ isActive: true });
      }
    };

    onScroll = throttle(this.close, 100);

    getPopoverContent = (Content, ContentActions, tipStyle, tipPosition) => (
      <div
        ref={c => {
          this.popover = c;
        }}>
        <Popover
          Content={Content}
          ContentActions={ContentActions}
          style={style.popover}
          tipStyle={tipStyle}
          tipPosition={tipPosition}
          onClose={this.close}
        />
      </div>
    );

    render() {
      const { Content, ContentActions, ...props } = this.props;
      const { isActive, tipStyle, tipPosition } = this.state;

      return (
        <FloatAnchor
          options={floatOptions}
          zIndex={1}
          anchor={
            <div
              ref={c => {
                this.trigger = c;
              }}>
              <BaseComponent {...props} isActive={isActive} />
            </div>
          }
          float={
            isActive ? this.getPopoverContent(Content, ContentActions, tipStyle, tipPosition) : null
          }
        />
      );
    }
  };

export default withPopover;
