// @flow

import ClickableOpacity from 'components/ClickableOpacity';
import Icon from 'components/Icon';
import ScrollView from 'components/ScrollView';
import Text from 'components/Text';
import View from 'components/View';
import React from 'react';
import type { Theme } from 'types';

type Props = {
  withShadow: boolean,
  children: any,
  error?: string,
  clearError?: () => void
};

const styles = {
  box: ({ withShadow }) => ({
    // position: 'relative',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    zIndex: withShadow ? 1 : 0
  }),
  boxWide: ({ withShadow }) => ({
    // position: 'relative',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    zIndex: withShadow ? 1 : 0,
    width: '100%'
  }),
  bar: ({ withShadow }, { shadows, components: { DetailBottomBar } }: Theme) => ({
    ...DetailBottomBar,
    flexGrow: 1,
    justifyContent: 'flex-end',
    boxShadow: withShadow ? shadows.subtle : undefined
  }),
  errorBox: (props, { baseSize, components: { DetailBottomBarError } }) => ({
    ...DetailBottomBarError,
    // position: 'absolute',
    // bottom: '100%',
    // left: 0,
    // right: 0,
    // zIndex: -1,
    paddingHorizontal: baseSize * 10,
    paddingVertical: baseSize * 4,
    justifyContent: 'center'
  }),
  successBox: (props, { baseSize, components: { DetailBottomBarSuccess } }) => ({
    ...DetailBottomBarSuccess,
    paddingHorizontal: baseSize * 10,
    paddingVertical: baseSize * 4,
    justifyContent: 'center'
  }),
  notificationBox: (props, { baseSize, components: { DetailBottomBarError } }) => ({
    ...DetailBottomBarError,
    paddingHorizontal: baseSize * 10,
    paddingVertical: baseSize * 4 + 1.5,
    justifyContent: 'center'
  }),
  closeButton: {
    position: 'absolute',
    right: 0,
    transform: 'translateX(-150%)',
    alignSelf: 'center'
  }
};

type NotificationBarProps = {
  style?: any,
  text: string,
  clearFn?: () => void
};

const InsideBar = ({ style = styles.errorBox, text, clearFn }: NotificationBarProps) => (
  <View style={style}>
    <ScrollView suppressScrollX style={{ maxHeight: 50 }}>
      <Text color="inherit">{text}</Text>
    </ScrollView>
    {clearFn ? (
      <View style={styles.closeButton}>
        <ClickableOpacity onClick={clearFn} type="button">
          <Icon name="checkbox" scale={0.5} />
        </ClickableOpacity>
      </View>
    ) : null}
  </View>
);

export const NotificationBar = ({
  style = styles.notificationBox,
  text,
  clearFn
}: NotificationBarProps) =>
  text ? (
    <View style={styles.boxWide}>
      <InsideBar style={style} text={text} clearFn={clearFn} />
    </View>
  ) : null;

const DetailBottomBar = ({ withShadow, children, error, success, clearError }: Props) => (
  <View style={styles.box} withShadow={withShadow}>
    {error ? <InsideBar style={styles.errorBox} text={error} clearFn={clearError} /> : null}
    {success ? <InsideBar style={styles.successBox} text={success} clearFn={clearError} /> : null}
    <View style={styles.bar} withShadow={withShadow}>
      {children}
    </View>
  </View>
);

export default DetailBottomBar;
