import React from 'react';

const SvgTableware = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M16.928 3.757l-.497-.498-6.291 6.222 1.98 1.693c.061-.027.088-.062.149-.088l4.66-6.143c.226-.192.287-.375.287-.602 0-.183-.087-.401-.288-.584zM9.145 9.044l.497-.506c.28-.244.219-.741-.13-1.09L5.375 3.38l-.523.498 3.665 3.603-.532.524-3.665-3.63-.533.541 3.657 3.586-.533.515-3.63-3.577-.497.497 4.127 4.075c.35.349.847.41 1.135.122l.497-.471 6.082 6.806c.183.183.462.27.707.27a.988.988 0 0 0 .698-.27c.217-.227.313-.48.313-.698 0-.253-.096-.498-.288-.716l-6.91-6.011zm-4.703 6.012a1.065 1.065 0 0 0-.245.716c0 .262.061.471.245.698.227.183.471.27.75.27a.922.922 0 0 0 .663-.27l3.97-4.86-.872-.968-4.511 4.414z"
    />
  </svg>
);

export default SvgTableware;
