// @flow

import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import ValidationError from 'components/ValidationError';
import { FastField } from 'formik';
import * as React from 'react';
import View from 'components/View';
import TimeField from 'react-simple-timefield';

type Props = {
  value?: string,
  onChange?: Event => void,
  disabled?: boolean,
  hasError?: boolean
};

type RangeProps = {
  children: [React.Element<Props>, React.Element<Props>]
};

const styles = {
  box: { width: 74, flexDirection: 'column' },
  rangeBox: {
    alignItems: 'center',
    '& > *:nth-child(2)': {
      marginHorizontal: 10
    }
  }
};

const TimeInput = (props: Props) => (
  <View style={styles.box}>
    <TimeField input={<TextInput />} {...props} />
  </View>
);

const TimeInputRange = ({ children: [timeInput1, timeInput2] }: RangeProps) => (
  <View style={styles.rangeBox}>
    {timeInput1}
    <Text>—</Text>
    {timeInput2}
  </View>
);

const inForm = SomeTimeInput => ({ name, ...props }) => (
  <FastField name={name}>
    {({ field, form: { isSubmitting, errors, status = {}, touched, setFieldValue } }) => {
      const hasError = (status[field.name] || errors[field.name]) && touched[field.name];

      return (
        <>
          <SomeTimeInput
            {...field}
            {...props}
            disabled={isSubmitting}
            hasError={hasError}
            onChange={value => setFieldValue(field.name, value)}
          />
          <ErrorMessage
            name={name}
            component={p => (
              <View style={styles.error}>
                <ValidationError {...p} />
              </View>
            )}
          />
        </>
      );
    }}
  </FastField>
);

const TimeInputForm = inForm(TimeInput);

export { TimeInputRange, TimeInputForm };

export default TimeInput;
