// @flow
import React from 'react';
import { coreEasing } from 'utils/transitions';
import View from './View';

const style = {
  row: {
    flex: '1 0 100%',
    maxWidth: '100%',
    minHeight: 0
  },
  col: ({ size: sizeKey }) => {
    let size;
    switch (sizeKey) {
      case 'half':
        size = { flex: `0 0 50%`, maxWidth: '50%' };
        break;

      case 'menu-expanded':
        size = { flex: `0 0 230px`, maxWidth: '230px' };
        break;

      case 'menu-collapsed':
        size = { flex: `0 0 50px`, maxWidth: '50px' };
        break;

      case 'info-panel-expanded':
        size = { flex: `0 0 320px`, maxWidth: '320px' };
        break;

      case 'info-panel-collapsed':
        size = { flex: `0 0 0px`, maxWidth: 0 };
        break;

      case 'help-panel':
        size = {
          flex: `0 0 0`,
          width: 0,
          '@media (min-width: 1100px)': {
            width: 'auto',
            flexBasis: 210,
            maxWidth: 210
          }
        };
        break;

      case 'help-panel-focus':
        size = {
          display: 'none',
          '@media (min-width: 1100px)': {
            display: 'flex',
            flex: `1 1 100%`,
            minWidth: 150,
            maxWidth: 290
          }
        };
        break;

      case 'focus-middle':
        size = {
          flex: '1 0 100%',
          maxWidth: 900,
          margin: '0 auto',
          '@media (min-width: 1100px)': {
            flex: `1 1 auto`,
            minWidth: 800,
            maxWidth: 900,
            margin: 0
          }
        };
        break;

      case 'auto-right-margin':
        size = {
          flex: `0 1 auto`,
          flexDirection: 'column',
          marginRight: 5
        };
        break;
      case 'auto-left-margin':
        size = {
          flex: `0 1 auto`,
          flexDirection: 'column',
          marginLeft: 5
        };
        break;

      default:
        size = { flex: `1 1 auto` };
    }

    return {
      ...size,
      transition: `flex 300ms ${coreEasing}`,
      willChange: 'flex'
    };
  }
};

const Row = ({ children }) => <View style={style.row}>{children}</View>;

type ColProps = {
  children: any,
  size:
    | 'half'
    | 'menu-expanded'
    | 'menu-collapsed'
    | 'info-panel-expanded'
    | 'info-panel-collapsed'
    | 'help-panel'
    | 'help-panel-focus'
    | 'focus-middle'
};

const Col = ({ children, size }: ColProps) => (
  <View style={style.col} size={size}>
    {children}
  </View>
);

export { Row, Col };
