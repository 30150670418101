// @flow

import React from 'react';
import { path } from 'rambda';
import type { Style, Theme } from '../../types';
import View from '../../components/View';
import Heading from '../../components/Heading';
// import Text from '../../components/Text';
import { commonStyles } from '../components/Common';
// import { commonStyles, Link } from '../components/Common';

const styles = {
  prepaidProductsWidget: (props, { shadows }: Theme) => ({
    width: 'calc(100% - 20px)',
    minWidth: '300px',
    maxWidth: '350px',
    margin: '10px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxShadow: shadows.normalAround
  }),
  prepaidProductsItemsWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  prepaidProductsItemDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  prepaidProductsItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0px'
  },
  prepaidProductsDivider: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.prepaidProductsWidgetDivider,
    height: '2px',
    width: '100%'
  })
};

const PrepaidProductsWidget = ({ props, theme, productsSet }) => {
  const prepaidProductsItems = prepaidProductsNodes => {
    const items = [];
    if (prepaidProductsNodes) {
      prepaidProductsNodes.forEach((productNode, index) => {
        const productData = path('node', productNode) ? productNode.node : null;
        if (index > 0) {
          items.push(
            <View
              key={`prepaidProductsDivider${index}`}
              props={props}
              theme={theme}
              style={styles.prepaidProductsDivider}
            />
          );
        }
        items.push(
          <View key={`prepaidProductsItem${index}`} style={styles.prepaidProductsItem}>
            {/* <Heading style={commonStyles.numbersPrimaryHeader} scale={3}>
              {productData.count}
            </Heading>
            <Heading style={commonStyles.primarySubHeaderWithSpace} scale={3}>
              x
            </Heading> */}
            <View style={styles.prepaidProductsItemDetails}>
              <Heading style={commonStyles.tertiaryHeader} scale={1}>
                {productData.name}
              </Heading>
              {/* <Heading style={commonStyles.tertiarySubHeader} scale={-0.5}>
                {item.expiration}
              </Heading> */}
            </View>
          </View>
        );
      });
    }

    return items;
  };
  return (
    <View style={styles.prepaidProductsWidget}>
      <Heading style={commonStyles.secondaryHeader} scale={1}>
        PREDPLATENÉ PRODUKTY
      </Heading>
      <View style={styles.prepaidProductsItemsWrapper}>{prepaidProductsItems(productsSet)}</View>
      <View style={commonStyles.linksWrapper}>
        {/*  <Link style={commonStyles.links} to="/">
          <Text style={commonStyles.links}>Zobraziť produkty, ktoré sa dajú predplatiť</Text>
        </Link> */}
      </View>
    </View>
  );
};

export default PrepaidProductsWidget;
