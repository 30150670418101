// @flow
import ErrorMessage from 'components/ErrorMessage';
import Icon from 'components/Icon';
import Text, { defaultFontFamily } from 'components/Text';
import ValidationError from 'components/ValidationError';
import View from 'components/View';
import { FastField, Field } from 'formik';
import React from 'react';
import { Theme } from '../../types';
import { focus } from '../themes/default';

type Props = {
  accent?: 'primary' | 'secondary',
  value?: string | number,
  onClick?: Event => void,
  onChange?: Event => void,
  defaultChecked?: boolean,
  checked?: boolean,
  label?: string,
  disabled?: boolean,
  readOnly?: boolean,
  name?: string
};

const styles = {
  box: {
    position: 'relative'
  },
  input: ({ accent }, { components: { Checkbox } }: Theme) => ({
    zIndex: -9999,
    opacity: 0,
    position: 'absolute',
    '&:checked + div': {
      '&:before': Checkbox[accent][':checked']
    },
    '&:checked + div svg': {
      color: Checkbox[accent][':checked'].color
    },
    [`${focus} + div`]: Checkbox[accent][focus],
    '&:disabled + div': Checkbox[':disabled']
  }),
  icon: ({ accent }, { typo, borderRadius, components: { Checkbox } }: Theme) => ({
    position: 'relative',
    color: 'transparent',
    borderRadius: borderRadius.normal,
    userSelect: 'none',
    '&:before': {
      display: 'block',
      content: '" "',
      border: '2px solid',
      borderRadius: borderRadius.normal,
      width: typo[defaultFontFamily].rhythm,
      height: typo[defaultFontFamily].rhythm,
      ...Checkbox[accent]
    }
  }),
  iconPosition: { position: 'absolute' },
  label: (props, { baseSize }) => ({
    marginLeft: baseSize * 2,
    userSelect: 'none',
    whiteSpace: 'pre-wrap'
  })
};

const Checkbox = ({
  accent,
  value,
  onClick,
  onChange,
  onBlur,
  defaultChecked,
  checked,
  label,
  disabled,
  readOnly,
  name
}: Props) => (
  // HTML checkbox doens't support readOnly, but it is intuitive, so I did this
  <View as={readOnly ? 'div' : 'label'} style={styles.box} nativeProps={{ onClick }}>
    <View
      as="input"
      nativeProps={{
        type: 'checkbox',
        name,
        value,
        onChange,
        onBlur,
        defaultChecked,
        checked,
        disabled
      }}
      style={styles.input}
      accent={accent}
    />

    <View style={styles.icon} accent={accent}>
      <View style={styles.iconPosition}>
        <Icon name="check" />
      </View>
    </View>

    {label ? (
      <Text style={styles.label} weight="bold">
        {label}
      </Text>
    ) : null}
  </View>
);

Checkbox.defaultProps = {
  accent: 'primary'
};

const inForm = (SomeCheckboxInput, fast) => {
  const FieldImpl = fast ? FastField : Field;

  return ({ name, ...props }) => (
    <FieldImpl name={name}>
      {({ field, form: { isSubmitting, setFieldValue } }) => (
        <View isVertical>
          <SomeCheckboxInput
            {...field}
            {...props}
            disabled={isSubmitting}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(value => value !== props.value);
                setFieldValue(name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                setFieldValue(name, nextValue);
              }
            }}
          />
          <ErrorMessage name={`${name}.${props.value}`} component={ValidationError} />
        </View>
      )}
    </FieldImpl>
  );
};

const CheckboxForm = inForm(Checkbox);
const CheckboxFormFast = inForm(Checkbox, true);

export { CheckboxForm, CheckboxFormFast };

export default Checkbox;
