// @flow
import { FastField } from 'formik';
import React from 'react';
import ValidationError from 'components/ValidationError';
import ErrorMessage from 'components/ErrorMessage';
import InputPattern from 'components/InputPattern';
import Stylo from './Stylo';
import { styles as textInputStyles } from './TextInput';
import View from './View';

type Props = {
  label?: string,
  onChange?: Event => void,
  onBlur?: Event => void,
  name?: string,
  defaultValue?: string,
  value?: string,
  disabled?: boolean,
  children: HTMLOptionElement[]
};

const styles = {
  label: {
    alignItems: 'flex-start'
  },
  inputPattern: ({ disabled }, { components: { Select } }) => ({
    ...Select,
    ...(disabled ? Select[':disabled'] : null),
    appearance: 'none',
    display: 'flex'
  })
};

const SelectBase = ({
  options,
  name,
  value,
  disabled,
  style,
  bold,
  onChange,
  onFocus,
  onBlur,
  defaultValue,
  ...props
}) => (
  <Stylo
    as="select"
    weight={bold ? 'bold' : 'regular'}
    style={[style, styles.inputPattern]}
    nativeProps={{ name, value, disabled, onChange, onBlur, onFocus, defaultValue }}
    disabled={disabled}
    {...props}>
    {options}
  </Stylo>
);

const SimpleSelect = ({ children, isVertical = false, style, ...props }: Props) => (
  <View as="div" isExpanded style={styles.label}>
    <InputPattern
      {...props}
      Base={SelectBase}
      iconRight="arrow-down-3"
      iconScale={-2}
      options={children}
      style={[textInputStyles.borderInput, style]}
      styleBox={{ flexDirection: isVertical ? 'column' : 'row', alignItems: 'flex-start' }}
    />
  </View>
);

const inForm = SomeSelect =>
  function({ name, ...props }) {
    return (
      <FastField name={name}>
        {({ field, form: { isSubmitting } }) => (
          <>
            <SomeSelect
              {...field}
              {...props}
              disabled={props.disabled || isSubmitting}
              onChange={
                props.onChange
                  ? e => {
                      field.onChange(e);
                      props.onChange(e);
                    }
                  : field.onChange
              }
            />
            <ErrorMessage name={name} component={ValidationError} />
          </>
        )}
      </FastField>
    );
  };

const SimpleSelectForm = inForm(SimpleSelect);

export { SimpleSelectForm };

export default SimpleSelect;
