import React from 'react';

const SvgXlsWhite = () => (
  <svg
    version={1.1}
    id="Capa_1"
    x={0}
    y={0}
    viewBox="0 0 384 384"
    xmlSpace="preserve"
    width="1em"
    height="1em">
    <style>{`.st0{fill:#fff}`}</style>
    <path
      className="st0"
      d="M64 0v80H0v144h64v160h224l96-96V0H64zM16 208V96h240v112H16zm272 153.376V288h73.376L288 361.376zM368 272h-96v96H80V224h192V80H80V16h288v256z"
    />
    <path
      className="st0"
      d="M77.888 151.744l15.936-33.888H78.752l-8.736 22.176-8.832-22.176H45.92l15.744 33.888L44.576 186.4h15.168l9.808-23.232 9.872 23.232h15.552zM123.776 175.072v-57.216h-13.824V186.4h38.288v-11.328zM206.336 160.944a13.308 13.308 0 0 0-2.064-4.752c-.976-1.44-2.32-2.784-3.984-4.032-1.648-1.264-3.792-2.464-6.416-3.616l-10.656-4.608c-2.944-1.2-4.864-2.528-5.76-3.984-.896-1.44-1.344-3.2-1.344-5.328 0-1.024.096-2.016.336-2.976.224-.96.624-1.808 1.232-2.544s1.44-1.328 2.448-1.776c1.024-.432 2.304-.672 3.84-.672 2.688 0 4.608.896 5.76 2.64 1.136 1.776 1.712 4.288 1.712 7.552h13.248v-1.92c0-3.264-.544-6.064-1.584-8.4-1.056-2.32-2.528-4.24-4.416-5.744-1.888-1.52-4.128-2.592-6.72-3.264s-5.424-1.008-8.496-1.008c-6.592 0-11.792 1.664-15.552 4.992-3.776 3.328-5.664 8.368-5.664 15.152 0 2.832.352 5.296 1.056 7.456a15.298 15.298 0 0 0 3.312 5.664c1.504 1.648 3.376 3.072 5.616 4.272 2.256 1.216 4.912 2.352 7.968 3.376 2.304.768 4.256 1.52 5.808 2.256 1.552.736 2.816 1.536 3.792 2.448.96.88 1.648 1.888 2.064 3.008.4 1.12.624 2.448.624 3.984 0 2.864-.864 5.008-2.544 6.384-1.696 1.376-3.584 2.064-5.712 2.064-1.776 0-3.28-.272-4.448-.768-1.184-.512-2.144-1.216-2.848-2.128-.704-.896-1.168-2-1.44-3.312-.24-1.296-.368-2.752-.368-4.352v-2.032h-13.824v2.784c0 6.336 1.664 11.2 5.024 14.592 3.376 3.392 8.96 5.088 16.768 5.088 7.488 0 13.328-1.664 17.52-4.96 4.192-3.296 6.288-8.56 6.288-15.792 0-2.096-.192-4.032-.576-5.744z"
    />
  </svg>
);

export default SvgXlsWhite;
