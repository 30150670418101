// @flow

import React from 'react';
import { path, pathOr } from 'rambda';
import Button from 'components/Button';
import gql from 'graphql-tag';
import withQueryClient from 'components/hoc/withQueryClientService';
import { compose } from 'recompose';
import { connect } from 'formik';
import type { Style, Theme } from '../../types';
import View from '../../components/View';
import Icon from '../../components/Icon';
import Heading from '../../components/Heading';
import { commonStyles } from '../components/Common';
import withPopover from '../../components/hoc/withPopover';
import { serverValidationErrors } from '../../utils/forms';

const mutation = gql`
  mutation($data: CardInput!) {
    card(data: $data) {
      card {
        id: record
      }
    }
  }
`;

const styles = {
  myCardsWidget: (props, { shadows }: Theme) => ({
    width: 'calc(100% - 20px)',
    minWidth: '300px',
    maxWidth: '350px',
    margin: '10px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxShadow: shadows.normalAround
  }),
  myCardsHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  myCardsContentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  myCardsItemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  myCardsItemDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  myCardsItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0px'
  },
  myCardsDivider: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.prepaidProductsWidgetDivider,
    height: '2px',
    width: '100%'
  }),
  myCardsItemIcon: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.actualPriceActionsItemValue,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginRight: 10
  }),
  newCardButton: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.newCardButton,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '15px',
    fontWeight: 'bold',
    padding: '5px 25px'
  })
};

const PopoverView = withPopover({ withTipPosition: true })(View);

const getStatusIcon = status => (status === 'ACTIVE' ? 'check' : 'checkbox');
const getDiscountPackagesList = discountPackages => {
  let discountPackagesText = '';

  if (discountPackages) {
    discountPackages.forEach((discountPackage, index) => {
      const packageName = pathOr(undefined, 'package.name', discountPackage);
      if (packageName) {
        if (index > 0) {
          discountPackagesText = `${discountPackagesText}, ${packageName}`;
        } else {
          discountPackagesText = `${packageName}`;
        }
      }
    });
  }

  return discountPackagesText;
};

const MyCardsWidget = ({ props, theme, client, refetch, cardsSet }) => {
  const onCardDeactivate = cardData => {
    const variables = {
      data: {
        record: cardData.record,
        status: 'DELETED',
        code: cardData.code
      }
    };

    client
      .mutate({
        mutation,
        variables
      })
      .then(() => {
        refetch();
      })
      .catch(e => {
        const errors = serverValidationErrors(e) || {};
        errors.__submitError = 'Kartu sa nepodarilo deaktivovať';
      });
  };

  const myCardsItems =
    cardsSet &&
    cardsSet.map((cardNode, index) => {
      const cardData = path('node', cardNode) ? cardNode.node : null;

      if (cardData.status === 'DELETED') {
        return <span />;
      }

      const divider =
        index > 0 ? (
          <View
            key={`myCardsDivider${index}`}
            props={props}
            theme={theme}
            style={styles.myCardsDivider}
          />
        ) : null;

      return (
        <View key={`myCardsItem${index}`} style={styles.myCardsContentWrapper}>
          {divider && divider}
          <PopoverView
            Content={() => (
              <View style={{ flexDirection: 'column', padding: 30, maxWidth: 300 }}>
                <Heading style={commonStyles.tertiaryHeader} scale={1}>
                  {'Deaktivovať kartu'}
                </Heading>
                <View
                  style={{ marginTop: 15, width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Icon name="ban" scale={1} style={styles.myCardsItemIcon} />
                  <Heading style={commonStyles.tertiarySubHeader} scale={-0.5}>
                    {'POZOR!'}
                  </Heading>
                </View>
                <View style={{ marginTop: 10 }}>
                  <Heading style={commonStyles.tertiarySubHeader} scale={-0.5} showEllipsis={false}>
                    {
                      'Po deaktivácii karty nebude jej opätovná aktivácia možná a bude sa musieť osobne vyžiadať nová na prevádzke,  na ktorej bola pôvodná karta vydaná'
                    }
                  </Heading>
                </View>
                <View style={{ marginTop: 15 }}>
                  <Button
                    label="Deaktivovať"
                    scale={-0.5}
                    accent="secondary"
                    onClick={() => onCardDeactivate(cardData)}
                  />
                </View>
              </View>
            )}
            style={{ ...styles.myCardsItem, ':hover': { cursor: 'pointer', opacity: 0.8 } }}>
            <Icon name={getStatusIcon(cardData.status)} scale={1} style={styles.myCardsItemIcon} />
            <View style={styles.myCardsItemDetails}>
              <Heading style={commonStyles.tertiaryHeader} scale={1}>
                {cardData.holder ? `${cardData.holder.name} ${cardData.holder.surname}` : ''}
              </Heading>
              <Heading style={commonStyles.tertiarySubHeader} scale={-0.5}>
                {getDiscountPackagesList(cardData.userDiscountPackages)}
              </Heading>
            </View>
          </PopoverView>
        </View>
      );
    });

  return (
    <View style={styles.myCardsWidget}>
      <View style={styles.myCardsHeaderWrapper}>
        <Heading style={commonStyles.secondaryHeader} scale={1}>
          MOJE KARTY
        </Heading>
        {/* <Button style={styles.newCardButton} accent="plain" label="Nová karta" /> */}
      </View>
      <View style={styles.myCardsItemsWrapper}>{myCardsItems}</View>
      {/*      <View style={commonStyles.linksWrapper}>
        <Link style={commonStyles.links} to="/">
          <Text style={commonStyles.links}>Zobraziť zoznam kariet</Text>
        </Link>
      </View> */}
    </View>
  );
};

export default compose(
  connect,
  withQueryClient
)(MyCardsWidget);
