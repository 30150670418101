import React from 'react';

const SvgTransactions = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M7.12 10L2.5 13.5 7.12 17v-2.334H17.5v-2.333H7.12V10zm5.755 0L17.5 6.5 12.875 3v2.333H2.5v2.334h10.375v2.332z"
    />
  </svg>
);

export default SvgTransactions;
