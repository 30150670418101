// @flow
import * as React from 'react';
import invariant from 'invariant';
import Text, { Props as TextProps, getLineHeight } from './Text';
import { Theme } from '../types';

type Props = TextProps & {
  name: string
};

const cache = {};

const importAll = req => {
  req
    .keys()
    .filter(key => !key.endsWith('.js'))
    .forEach(key => {
      cache[key.substring(2)] = req(key).default;
    });
};

importAll(require.context('./icons/'));

const defaultStyle = ({ scale = 0, color, fontFamily = 'Roboto' }: TextProps, theme: Theme) => {
  const size = getLineHeight({ fontFamily, scale }, theme);

  return {
    display: 'inline',
    width: size,
    height: size,
    fontSize: size,
    color: color || 'inherit'
  };
};

const Icon = ({ name, style, ...props }: Props) => {
  const SvgIcon = cache[name];

  invariant(SvgIcon, `missing icon ${name}`);

  return (
    <Text {...props} style={[defaultStyle, style]}>
      <SvgIcon />
    </Text>
  );
};

export default Icon;
