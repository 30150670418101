import { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { pathOr } from 'rambda';
import { cookiesSetting, API_URL_COOKIE } from '../common/constants';

const ConfigProvider = ({ children, cookies }) => {
  useEffect(() => {
    axios
      .get('/appconfig.json')
      .then(response => {
        const apiUrl = pathOr(process.env.API_URL, 'data.api_url', response);
        const apiPort = pathOr(process.env.API_PORT, 'data.api_port', response);

        const urlValue = `${apiUrl}:${apiPort}`;
        cookies.set(API_URL_COOKIE, urlValue, cookiesSetting);
      })
      .catch(error => {
        console.error('Error getting the config data (api url), applying env variables: ', error);
        const urlValue = `${process.env.API_URL}:${process.env.API_PORT}`;
        cookies.set(API_URL_COOKIE, urlValue, cookiesSetting);
      });
  }, []);

  return children;
};

export default withCookies(ConfigProvider);
