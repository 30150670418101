import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../../providers/date';
import { CookiesProvider } from 'react-cookie';
import QueryClientProvider from 'providers/QueryClient';
import Fela from '../../providers/Fela';
import ConfigProvider from '../../providers/ConfigProvider';
import Apollo from '../../providers/Apollo';
import Routes from './Routes';
import defaultTheme from '../themes/default';

export default () => (
  <Fela defaultTheme={defaultTheme}>
    <CookiesProvider>
      <ConfigProvider>
        <QueryClientProvider>
          <Apollo>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </Apollo>
        </QueryClientProvider>
      </ConfigProvider>
    </CookiesProvider>
  </Fela>
);
