// @flow

import * as React from 'react';
import View from 'components/View';

const style = ({ width }) => ({ width, marginBottom: 10, alignItems: 'flex-start' });

const FormRow = ({ children, width }) => (
  <View style={style} width={width}>
    {children}
  </View>
);

export default FormRow;
