// @flow

import { webPagePath } from 'WebModule/app/routerPaths';
import FocusBody from 'components/FocusBody';
import Page from 'components/Page';
import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { path } from 'rambda';
import logoBlackWide from 'themes/images/logo-black-wide.png';
import jwtDecode from 'jwt-decode';
import { withCookies } from 'react-cookie';
import Text from 'components/Text';
import View from 'components/View';
import FocusContent from 'components/FocusContent';
import FocusHeader from 'components/FocusHeader';
import LoginBottomBar from 'components/LoginBottomBar';
import { DetailBottomBarConfirm } from 'components/DetailBottomBarItem';
import FormRow from 'components/FormRow';
import TextInput from 'components/TextInput';
import { NotificationBar } from 'components/DetailBottomBar';
import {
  AUTH_TOKEN,
  cookiesSetting,
  USERNAME,
  USERID,
  LOGOUT_MESSAGE
} from '../../common/constants';

const styles = {
  page: (props, { components: { FocusPage } }) => ({
    ...FocusPage.page,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-100px'
  }),
  text: (props, { components: { FocusPage } }) => ({
    ...FocusPage.text
  }),
  img: {
    marginLeft: 20,
    height: 20
  },
  error: (props, { components: { Login } }) => ({
    ...Login.error
  })
};

const loginQuery = gql`
  mutation LoginMutation($name: String!, $password: String!) {
    tokenAuth(username: $name, password: $password) {
      token
    }
  }
`;

const LoginButton = withCookies(
  ({ cookies, history, location, name, password, wasEnterHit, cleanEnterState }) => (
    <Mutation
      mutation={loginQuery}
      variables={{ name, password }}
      onCompleted={data => {
        cookies.remove(LOGOUT_MESSAGE);
        if (path('tokenAuth.token', data)) {
          const tokenPayload = jwtDecode(data.tokenAuth.token);

          cookies.set(AUTH_TOKEN, data.tokenAuth.token, cookiesSetting);
          cookies.set(USERID, tokenPayload.user_id, cookiesSetting);
          cookies.set(USERNAME, tokenPayload.username || 'prihlásený', cookiesSetting);
          const redirectPathname = path('state.from.pathname', location);

          history.push(redirectPathname || webPagePath);
        }
      }}>
      {(login, { error, loading }) => {
        if (loading) return <DetailBottomBarConfirm name="prihlasujem..." />;
        if (error)
          return (
            <>
              <Text style={styles.error}>Chyba</Text>
              <DetailBottomBarConfirm name="Prihlásiť sa" onClick={login} />
            </>
          );

        if (wasEnterHit) {
          login();
          cleanEnterState();
        }

        return <DetailBottomBarConfirm name="Prihlásiť sa" onClick={login} />;
      }}
    </Mutation>
  )
);

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.enterPress = this.enterPress.bind(this);
    this.cleanEnterState = this.cleanEnterState.bind(this);
  }

  state = {
    name: '',
    password: '',
    logoutMessage: undefined,
    wasEnterHit: undefined
  };

  componentDidMount() {
    const { cookies } = this.props;

    this.usernameInput.focus();
    document.addEventListener('keydown', this.enterPress, false);

    const logoutNotification = cookies.get(LOGOUT_MESSAGE);
    if (logoutNotification) {
      this.setState({ logoutMessage: logoutNotification });
      setTimeout(() => {
        cookies.remove(LOGOUT_MESSAGE);
      }, 4000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterPress, false);
  }

  updateParameter = (parameter, e) => {
    const { [parameter]: param } = this.state;
    if (e.target.value !== param) {
      this.setState({ [parameter]: e.target.value });
    }
  };

  enterPress(event) {
    if (event.key === 'Enter') {
      this.setState({ wasEnterHit: true });
    } else {
      this.setState({ wasEnterHit: false });
    }
  }

  cleanEnterState() {
    this.setState({ wasEnterHit: false });
  }

  render() {
    const { history, location, cookies } = this.props;
    const { name, password, logoutMessage, wasEnterHit } = this.state;

    return (
      <Page title="Login">
        <View isExpanded style={styles.page}>
          <View style={{ width: 500 }}>
            <FocusContent>
              <FocusHeader title="Login for WEB" subTitle="zadajte meno používateľa a heslo" />

              <FocusBody>
                <View isExpanded isVertical style={{ padding: 40, paddingBottom: 20 }}>
                  <FormRow>
                    <TextInput
                      label="Meno"
                      nativeProps={{
                        ref: c => {
                          this.usernameInput = c;
                        },
                        value: name,
                        onChange: e => this.updateParameter('name', e)
                      }}
                    />
                  </FormRow>

                  <FormRow>
                    <TextInput
                      label="Heslo"
                      nativeProps={{
                        type: 'password',
                        value: password,
                        onChange: e => this.updateParameter('password', e)
                      }}
                    />
                  </FormRow>
                </View>

                <LoginBottomBar>
                  <img src={logoBlackWide} alt="LoyalMAN" style={styles.img} />

                  <LoginButton
                    history={history}
                    location={location}
                    name={name}
                    password={password}
                    wasEnterHit={wasEnterHit}
                    cleanEnterState={this.cleanEnterState}
                  />
                </LoginBottomBar>
              </FocusBody>
              {logoutMessage && (
                <NotificationBar
                  text={logoutMessage}
                  clearFn={() => {
                    cookies.remove(LOGOUT_MESSAGE);
                    this.setState({ logoutMessage: undefined });
                  }}
                />
              )}
            </FocusContent>
          </View>
        </View>
      </Page>
    );
  }
}

export default withCookies(LoginPage);
