import React from 'react';

const SvgTrash = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M16 6.9H4v-2c0-.5.4-.9.8-.9h2v-.3c0-.9.7-1.6 1.6-1.6h3.1c.9 0 1.6.7 1.6 1.6V4h2c.5 0 .9.4.9.8v2.1zM8 3.7V4h4v-.3c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5zm6.7 14.2H5.3V7.8h9.5v10.1zm-7.6-8v5.8c0 .3.2.5.6.5.3 0 .6-.3.6-.5V9.9c0-.3-.2-.5-.6-.5-.4-.1-.6.2-.6.5zm2.3 0v5.8c0 .3.2.5.6.5.3 0 .6-.3.6-.5V9.9c0-.3-.2-.5-.6-.5-.3-.1-.6.2-.6.5zm2.4 0v5.8c0 .3.2.6.6.6.3 0 .6-.3.6-.6V9.9c0-.3-.2-.5-.6-.5-.4-.1-.6.2-.6.5z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTrash;
