import React from 'react';

const SvgUsers = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M10 10.008c1.578 0 2.805-1.637 2.805-3.33v-.532c0-1.694-1.227-2.896-2.805-2.896S7.195 4.452 7.195 6.146v.531c0 1.694 1.227 3.33 2.805 3.33zm-5.62.547c.45 0 1.686-.585 1.686-1.8v-.9c0-1.215-.89-1.8-1.686-1.8-.769 0-1.685.585-1.685 1.8v.9c0 1.215 1.258 1.8 1.685 1.8zm10.1 2.251c0-.009-.003-.018-.004-.026a2.148 2.148 0 0 0-.062-.311v-.003a1.91 1.91 0 0 0-.307-.628v-.001c-.244-.337-.611-.68-1.208-1.016-.315-.177-.454-.259-.65-.259-.31 0-.58.241-1.036.66h-.002l-.414.382c-.015.014-.462.646-.797.646s-.782-.632-.797-.646l-.415-.382h-.001c-.456-.419-.727-.66-1.037-.66-.195 0-.369.082-.65.259-.578.366-.963.678-1.206 1.015h-.001c0 .001 0 .003-.002.004-.146.202-.245.41-.304.623l-.002.006c-.027.101-.048.203-.06.309-.002.01-.005.018-.005.029-.013.11-.02.225-.02.343v2.475c0 .8.301 1.125 1.125 1.125h6.75c.83 0 1.125-.308 1.125-1.125V13.15c0-.118-.008-.232-.02-.344zm1.15-2.25c.45 0 1.686-.586 1.686-1.801v-.9c0-1.215-.89-1.8-1.686-1.8-.77 0-1.686.585-1.686 1.8v.9c0 1.215 1.259 1.8 1.686 1.8zM2.28 11.773A2.433 2.433 0 0 0 1 13.937v1.126c0 .816.275 1.124 1.125 1.124h2.25v-3.374l-.562-.563c-.596-.525-1.097-.724-1.533-.476zm2.095 1.039c.012.01 0-.268 0 0zM19 13.937c0-.618-.234-1.568-1.28-2.163-.436-.248-.938-.049-1.533.476l-.562.563v3.374h2.25c.85 0 1.125-.308 1.125-1.124v-1.126zm-3.375-1.124c0-.268-.013.01 0 0z"
    />
  </svg>
);

export default SvgUsers;
