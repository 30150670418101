import React from 'react';

const SvgSale = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M18.6 9.7l-1.5-1.5.6-2.1v-.4c-.1-.1-.2-.2-.3-.2L15.3 5l-.5-2.1c0-.1-.1-.2-.2-.3-.1-.1-.3-.1-.4 0l-2.3.4-1.5-1.6c-.1-.1-.2-.2-.4-.2-.1 0-.3.1-.4.2L8.2 3l-2.1-.6h-.4c-.2.1-.2.2-.3.4l-.5 2.1-2.1.5c-.1 0-.2.1-.3.2-.1.2-.1.3-.1.5L3 8.2 1.4 9.7c-.1.1-.1.2-.1.3 0 .1.1.3.2.4L3 11.9 2.4 14c0 .1 0 .3.1.4.1.1.2.2.3.2l2.1.5.5 2.1c0 .1.1.2.2.3.1.1.3.1.4 0l2.1-.6 1.5 1.5c.1.1.2.1.4.1s.3 0 .4-.1l1.5-1.5 2.1.6h.4c.1-.1.2-.2.2-.3l.5-2.1 2.1-.5c.1 0 .2-.1.3-.2.1-.1.1-.3 0-.4l-.6-2.1 1.5-1.5c.4-.2.4-.5.2-.7zm-2.4 1.7c-.2.2-.2.3-.2.5l.5 1.9-1.9.5c-.2 0-.3.2-.4.4l-.5 1.9-1.8-.6c-.2-.1-.4 0-.5.1L10 17.5l-1.4-1.3c0-.1-.2-.2-.3-.2h-.1l-1.9.5-.5-1.9c0-.2-.2-.3-.4-.4l-1.9-.5.5-1.9c0-.2 0-.4-.1-.5L2.5 10l1.4-1.4c.1-.1.2-.3.1-.5l-.5-1.8 1.9-.5c.2 0 .3-.2.4-.4l.5-1.9 1.9.5c.2.1.4 0 .5-.1L10 2.5l1.4 1.4c.1.1.3.2.5.1l1.9-.5.5 1.9c0 .2.2.3.4.4l1.9.5-.6 1.9c0 .2 0 .4.1.5l1.3 1.4-1.2 1.3zm-4.1-.9c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5c.1-.8-.6-1.5-1.5-1.5zm0 2.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5c0 .2-.2.5-.5.5zM9.6 8c0-.8-.7-1.5-1.5-1.5-.9-.1-1.6.6-1.6 1.5 0 .8.7 1.5 1.5 1.5S9.6 8.8 9.6 8zM7.5 8c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5zm4.9-1.5c-.2-.2-.6-.1-.7.1l-4.1 6.1c-.2.2-.1.6.1.7.2.2.6.1.7-.1l4.1-6.1c.2-.2.1-.5-.1-.7z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSale;
