import React from 'react';
import { render as renderReact } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './WebModule/app/Root';

const render = NextRoot =>
  renderReact(
    <AppContainer>
      <NextRoot />
    </AppContainer>,
    document.getElementById('root')
  );

render(Root);

if (module.hot) {
  module.hot.accept('./WebModule/app/Root', () => {
    // eslint-disable-next-line global-require
    const NextRoot = require('./WebModule/app/Root').default;
    render(NextRoot);
  });
}
