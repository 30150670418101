import * as React from 'react';
import View from 'components/View';

const style = (props, { components: { FocusBody } }) => ({
  flexDirection: 'column',
  ...FocusBody
});

const FocusBody = ({ children }) => <View style={style}>{children}</View>;

export default FocusBody;
