import ErrorMessage from 'components/ErrorMessage';
import ValidationError from 'components/ValidationError';
import { connect } from 'formik';
import gql from 'graphql-tag';
import * as React from 'react';
import View from 'components/View';
import { compose, onlyUpdateForKeys, withHandlers } from 'recompose';
import Autocomplete from 'components/Autocomplete';
import withQueryClient from 'components/hoc/withQueryClientService';
import { LabelForInput } from 'components/Label';

const query = gql`
  query($search: String!) {
    countries(any: $search, orderBy: ["name"], first: 20) {
      edges {
        node {
          id: record
          name
          code
        }
      }
    }
  }
`;

const CountriesSearch = ({ client, formik: { values }, onSelect, visibleLabel = true }) => (
  <View isVertical style={{ alignItems: 'flex-start', flexGrow: 1 }}>
    <View isExpanded style={{ flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
      {visibleLabel && <LabelForInput>Národnosť</LabelForInput>}
      <Autocomplete
        defaultValue={values.countryId}
        fetchItems={search =>
          search.length > 2 || (values.countryId && values.countryId.length > 2)
            ? client
                .query({
                  query,
                  variables: { search: search && search.length > 0 ? search : values.countryId }
                })
                .then(
                  ({
                    data: {
                      countries: { edges }
                    }
                  }) => edges
                )
            : Promise.resolve([])
        }
        getItemValue={({ node: { id } }) => id}
        format={({ node: { name } }) => `${name}`}
        placeholder="Vyhľadať krajinu"
        onSelect={onSelect}
        style={{ width: '100%' }}
      />
    </View>
    <View style={{ marginTop: 4 }}>
      <ErrorMessage name="countryId" component={ValidationError} />
    </View>
  </View>
);

export default compose(
  connect,
  onlyUpdateForKeys([]),
  withQueryClient,
  withHandlers({
    onSelect: ({ formik: { setFieldValue } }) => id => {
      setFieldValue('countryId', id);
    }
  })
)(CountriesSearch);
