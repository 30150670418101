// @flow
import React from 'react';
import { Style, Theme } from '../../types';
import View from '../../components/View';

type Props = {
  Content: React.Component,
  style: Style,
  contentStyle?: Style,
  onClose: () => void
};

const popoverStyle = {
  body: (props, { components: { Popover } }: Theme): Style => ({
    ...Popover.body,
    whiteSpace: 'nowrap',
    alignItems: 'center',
    zIndex: 1
  }),
  tip: (props, { components: { Popover } }: Theme): Style => ({
    width: 0,
    height: 0,
    borderLeft: '10px solid',
    borderRight: '10px solid',
    borderBottom: `10px solid`,
    marginTop: '-10px',
    ...Popover.tip
  }),
  content: (props, { components: { Popover } }): Style => ({
    ...Popover.content
  })
};

const Popover = ({ Content, style, onClose, contentStyle, showTip = true }: Props) => (
  <View isVertical style={[popoverStyle.body, style]}>
    {showTip && <View style={popoverStyle.tip} />}
    <View style={[popoverStyle.content, contentStyle]}>
      <Content onClose={onClose} />
    </View>
  </View>
);

export default Popover;
