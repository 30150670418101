// @flow

import React from 'react';
import type { Style, Theme } from '../../types';
import View from '../../components/View';
import Text from '../../components/Text';

export const commonStyles = {
  primaryHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.primaryHeader
  }),
  primarySubHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.primarySubHeader,
    paddingLeft: 5
  }),
  primarySubHeaderWithSpace: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.primarySubHeader,
    padding: '0px 10px 0px 5px'
  }),
  secondaryHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.secondaryHeader,
    whiteSpace: 'pre-wrap'
  }),
  tertiaryHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.tertiaryHeader,
    fontSize: '15px',
    lineHeight: '20px',
    whiteSpace: 'unset'
  }),
  tertiarySubHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.tertiaryHeader,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'none',
    whiteSpace: 'pre-wrap',
    width: '100%'
  }),
  numbersPrimaryHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.primaryHeader,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '25px'
  }),
  numbersSympolsPrimaryHeader: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.primaryHeader,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '40px'
  }),
  scrollView: {
    height: '100%'
  },
  linksWrapper: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '20px',
    height: '20px'
  },
  linksDivider: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.linksDivider,
    height: '12px',
    width: '1px',
    margin: '3px 10px'
  }),
  links: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.links,
    textDecoration: 'underline'
  }),
  inactiveBg: (props, theme: Theme): Style => ({
    ...theme.components.MainPage.inactiveBg
  })
};

export const Link = ({ children, to }) => (
  <View as={Text} nativeProps={{ to: `${to || '/'}` }}>
    {children}
  </View>
);

export const Button = ({ to, label, style }) => (
  <Link to={to}>
    <Text style={style}>{label}</Text>
  </Link>
);
