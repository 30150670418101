import React from 'react';

const SvgFastBackward = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M11.2 10l6.1-6.1c.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4l-.8-.8c-.1-.1-.2-.2-.4-.2-.1 0-.3.1-.4.2L8.6 9.6c-.1.1-.1.3-.1.4 0 .1.1.3.2.4l7.2 7.2c.1.1.2.2.4.2.1 0 .3-.1.4-.2l.8-.8c.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4l-6.3-6zm-5.9 0l6.1-6.1c.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4l-.8-.8c-.1-.1-.2-.2-.4-.2-.1 0-.3.1-.4.2L2.7 9.6c-.1.1-.2.3-.2.4 0 .1.1.3.2.4l7.2 7.2c.1.1.2.2.4.2.1 0 .3-.1.4-.2l.8-.8c.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4l-6.2-6z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFastBackward;
