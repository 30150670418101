// @flow
import React from 'react';
import { Style, Theme } from '../types';
import View from './View';

type Props = {
  Content: React.Component,
  ContentActions: any,
  style: Style,
  tipStyle: Style,
  onClose: () => void
};

const popoverStyle = {
  body: (props, { components: { Popover } }: Theme): Style => ({
    ...Popover.body,
    whiteSpace: 'nowrap',
    alignItems: 'center',
    zIndex: 1
  }),
  tip: (props, { components: { Popover } }: Theme): Style => ({
    width: 0,
    height: 0,
    borderLeft: '10px solid',
    borderRight: '10px solid',
    borderBottom: `10px solid`,
    marginTop: '-10px',
    ...Popover.tip
  }),
  bottomTip: (props, { components: { Popover } }: Theme): Style => ({
    width: 0,
    height: 0,
    borderLeft: '10px solid',
    borderRight: '10px solid',
    borderTop: `10px solid`,
    marginBottom: '-10px',
    borderTopColor: Popover.tip.borderBottomColor,
    ...Popover.tip
  }),
  content: (props, { components: { Popover } }): Style => ({
    ...Popover.content
  })
};

const Popover = ({
  Content,
  ContentActions = null,
  style,
  tipStyle,
  tipPosition = 'top',
  onClose
}: Props) => (
  <View isVertical style={[popoverStyle.body, style]}>
    {tipPosition === 'top' && <View style={[popoverStyle.tip, tipStyle]} />}
    <View style={popoverStyle.content}>
      <Content ContentActions={ContentActions} onClose={onClose} />
    </View>
    {tipPosition === 'bottom' && <View style={[popoverStyle.bottomTip, tipStyle]} />}
  </View>
);

export default Popover;
