import React from 'react';

const SvgDiscard = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M10 20c5.5 0 10-4.5 10-10S15.5 0 10 0C4.4 0 0 4.5 0 10s4.4 10 10 10zm-2.9-5.3l-1.9-1.9L8.1 10 5.3 7.2l1.9-1.9L10 8.1l2.9-2.9 1.9 1.9-2.9 2.9 2.8 2.9-1.9 1.9-2.8-2.9-2.9 2.8z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDiscard;
