// @flow
import Icon from 'components/Icon';
import { LabelForInput } from 'components/Label';
import Text, { defaultFontFamily, textStyle } from 'components/Text';
import View from 'components/View';
import * as React from 'react';
import type { Theme } from 'types';

export type BaseProps = {
  name?: string,
  defaultValue?: string,
  value?: string,
  fontFamily?: string,
  scale?: number,
  type?: string,
  placeholder?: string,
  disabled?: boolean,
  readOnly?: boolean,
  accept?: string,
  onChange?: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onKeyPress?: () => void
};

export type Props = BaseProps & {
  hasError?: boolean,
  expandHeight?: boolean,
  iconLeft?: string,
  iconRight?: string,
  labelRight?: string,
  label?: string,
  isMultiline?: boolean
};

const styles = {
  base: ({ isMultiline, readOnly, type }, { components: { TextInput } }) => ({
    display: 'inline-block', // default value, don't mess with input
    lineHeight: 'normal', // default value, don't mess with input
    backgroundColor: 'transparent',
    cursor: readOnly ? 'default' : undefined,
    maxWidth: '100%',
    textAlign: type === 'number' ? 'right' : 'left',
    ...TextInput.base,
    ...(isMultiline ? { height: '100%' } : null)
  }),
  icon: ({ disabled }, { baseSize, components: { TextInput } }: Theme) => ({
    width: baseSize * 10 + 2,
    alignItems: 'center',
    justifyContent: 'center',
    color: TextInput.base.color,
    pointerEvents: 'none',
    opacity: disabled ? 0.5 : 1
  }),
  centerLabel: ({ canWrapLabel }) => ({
    maxWidth: '100%',
    flexGrow: 1,
    alignItems: 'center',
    flexWrap: canWrapLabel ? 'wrap' : 'nowrap'
  }),
  label: ({ expandHeight }) => ({
    maxWidth: '100%',
    flexGrow: 1,
    height: expandHeight ? '100%' : undefined
  }),
  inputWithIcon: ({ isMultiline, iconLeft, iconRight }, { baseSize }: Theme) => ({
    flexGrow: 1,
    marginLeft: iconLeft ? -(baseSize * 10 + 2) : 0,
    marginRight: iconRight ? -(baseSize * 10 + 2) : 0,
    paddingLeft: iconLeft ? baseSize * 10 : baseSize * 4,
    paddingRight: iconRight ? baseSize * 10 : baseSize * 4,
    paddingVertical: isMultiline ? baseSize * 2 : undefined
  })
};

const InputPattern = ({
  iconLeft,
  iconRight,
  labelRight,
  style,
  styleBox,
  expandHeight,
  label,
  Base,
  canWrapLabel = true,
  isMultiline,
  ...props
}: Props) => (
  <View
    style={styleBox ? [styles.centerLabel, styleBox] : styles.centerLabel}
    canWrapLabel={canWrapLabel}>
    {label ? <LabelForInput>{label}</LabelForInput> : null}
    <View style={styles.label} expandHeight={expandHeight || isMultiline}>
      {iconLeft ? (
        <View style={styles.icon}>
          <Icon name={iconLeft} />
        </View>
      ) : null}
      <Base
        iconLeft={iconLeft}
        iconRight={iconRight || labelRight}
        expandHeight={expandHeight}
        fontFamily={defaultFontFamily}
        isMultiline={isMultiline}
        scale={0.5}
        {...props}
        style={[textStyle, style, styles.inputWithIcon, styles.base]}
      />
      {iconRight || labelRight ? (
        // eslint-disable-next-line react/destructuring-assignment
        <View style={styles.icon} disabled={props.disabled}>
          {iconRight ? (
            <Icon name={iconRight} />
          ) : (
            <Text color="inherit" weight="bold">
              {labelRight}
            </Text>
          )}
        </View>
      ) : null}
    </View>
  </View>
);

export default InputPattern;
