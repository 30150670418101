// @flow
import { homePath } from 'app/routerPaths';
import React from 'react';
// import { Link } from 'react-router-dom';
import { AUTH_TOKEN, cookiesSetting, USERNAME } from 'common/constants';
import { withCookies } from 'react-cookie';
import View from 'components/View';
import Text from 'components/Text';
// import Icon from 'components/Icon';
import withIsWideInfo from 'components/hoc/withIsWideInfo';
import ClickableOpacity from 'components/ClickableOpacity';
import gql from 'graphql-tag';
import withQueryService from 'components/hoc/withQueryService';
import { compose, path } from 'rambda';
import userPhoto from '../themes/images/userphoto.jpg';
import cardPic from '../themes/images/cardExample.png';
import type { Style, Theme } from '../../types';
// import DividerWithProps from '../../components/DividerWithProps';
import Button from '../../components/Button';
import withPopover from '../components/hoc/withPopover';
import { MyProfileContent } from './MainPagePopupsContent';

const query = gql`
  query {
    users(first: 2) {
      edges {
        node {
          id: record
          name
          surname
          profilePic
        }
      }
    }
    companies(first: 1) {
      edges {
        node {
          name
          color
          currency {
            name
          }
        }
      }
    }
  }
`;

const defaultFontFamily = 'Roboto';

const sidebarStyle = {
  sidebar: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.sidebar,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    userSelect: 'none',
    position: 'relative'
  }),
  cardPart: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.cardPart,
    flexShrink: 0,
    width: '100%',
    height: 325,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    userSelect: 'none',
    position: 'absolute',
    top: 70,
    paddingTop: 70
  }),
  userPhotoPart: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.userPhotoPart,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100px',
    marginTop: '-30px',
    paddingTop: '30px'
  }),
  userPhoto: {
    height: 100,
    width: 100,
    marginTop: 20,
    borderRadius: '100%',
    overflow: 'hidden',
    border: '2px solid #ffffff',
    zIndex: 1
  },
  userName: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.userName,
    fontFamily: defaultFontFamily,
    fontSize: theme.typo[defaultFontFamily].fontSize[2],
    // transition: `paddingBottom ${speed} ${coreEasing}`,
    fontWeight: 'bold'
  }),
  cardLogoWrapper: {
    position: 'relative'
  },
  cardLogo: {
    padding: '15px 0px',
    width: 210
    // transition: `margin-left ${speed} ${coreEasing}`
  },
  cardTexts: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.texts,
    fontFamily: defaultFontFamily,
    fontSize: theme.typo[defaultFontFamily].fontSize[-2],
    // transition: `paddingBottom ${speed} ${coreEasing}`,
    fontWeight: 'bold',
    lineHeight: '12px',
    textAlign: 'center'
  }),
  cardTextsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    transform: 'rotate(-6deg)',
    top: '45px',
    left: '20px',
    width: '140px'
  },
  textsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '190px'
  },
  texts: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.texts,
    fontFamily: defaultFontFamily,
    fontSize: theme.typo[defaultFontFamily].fontSize[0],
    // transition: `paddingBottom ${speed} ${coreEasing}`,
    fontWeight: 'bold',
    lineHeight: '15px',
    textAlign: 'center'
  }),
  linksPart: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.linksPart,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    userSelect: 'none',
    marginTop: '320px',
    justifyContent: 'space-between'
  }),
  divider: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.divider
  }),
  ordersLink: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.ordersLink,
    width: 70,
    height: 70,
    cursor: 'pointer',
    borderRadius: '100%',
    border: '2px solid #ffffff',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  buttonWithTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },

  buttonWithTitleTexts: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.texts,
    fontFamily: defaultFontFamily,
    fontSize: theme.typo[defaultFontFamily].fontSize[0],
    // transition: `paddingBottom ${speed} ${coreEasing}`,
    lineHeight: '15px',
    padding: '10px'
  }),
  menuPart: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.menuPart,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    userSelect: 'none',
    justifyContent: 'center',
    padding: '0px 30px 30px 30px'
  }),
  menuLink: {
    backgroundColor: 'transparent',
    borderRadius: '5px',
    lineHeight: 30,
    padding: '10px 10px !important'
  },
  bottomPart: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.bottomPart,
    flexShrink: 0,
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    userSelect: 'none'
  }),
  bottomPartLink: (props, theme: Theme): Style => ({
    ...theme.components.Sidebar.bottomPartLink,
    fontFamily: defaultFontFamily,
    fontSize: theme.typo[defaultFontFamily].fontSize[1]
    // transition: `paddingBottom ${speed} ${coreEasing}`
  })
};

const logout = cookies => {
  cookies.remove(AUTH_TOKEN, cookiesSetting);
  cookies.remove(USERNAME, cookiesSetting);
};

// const myProfile = () => {};

const UserName = ({ props, theme, userName }) => (
  <Text props={props} theme={theme} style={sidebarStyle.userName}>
    {userName || 'prihlásený'}
  </Text>
);

const Texts = ({ props, theme, firstLine, secondLine, isCardTexts }) => (
  <View style={isCardTexts ? sidebarStyle.cardTextsWrapper : sidebarStyle.textsWrapper}>
    <Text
      props={props}
      theme={theme}
      style={isCardTexts ? sidebarStyle.cardTexts : sidebarStyle.texts}>
      {firstLine || ''}
    </Text>
    <Text
      props={props}
      theme={theme}
      style={isCardTexts ? sidebarStyle.cardTexts : sidebarStyle.texts}>
      {secondLine || ''}
    </Text>
  </View>
);

/* const Divider = ({ props, theme }) => (
  <DividerWithProps
    props={props}
    theme={theme}
    style={sidebarStyle.divider}
    thick
    verticalPadding={30}
    horizontalPadding={35}
  />
);

const OrdersButton = ({ props, theme, title, to, onClick }) => (
  <View
    as={ClickableOpacity}
    nativeProps={{ onClick, tabIndex: -1 }}
    style={sidebarStyle.buttonWithTitle}>
    <OrdersLink to={to}>
      <Icon name="tableware" scale={8} />
    </OrdersLink>
    <Text props={props} theme={theme} style={sidebarStyle.buttonWithTitleTexts}>
      {title || ''}
    </Text>
  </View>
); */

/* const OrdersLink = ({ props, theme, children, to }) => (
  <View
    props={props}
    theme={theme}
    as={Link}
    nativeProps={{ to: `${to || '/'}` }}
    style={sidebarStyle.ordersLink}>
    {children}
  </View>
); */

const MenuLink = ({ children, to }) => (
  <View as={Text} nativeProps={{ to: `${to || '/'}` }}>
    {children}
  </View>
);

const BottomPartLink = ({ props, theme, label, to, onClick }) => (
  <View
    as={ClickableOpacity}
    style={sidebarStyle.bottomPart}
    nativeProps={{ onClick, tabIndex: -1 }}>
    <MenuLink to={to}>
      <Text props={props} theme={theme} style={sidebarStyle.bottomPartLink}>
        {label}
      </Text>
    </MenuLink>
  </View>
);
const Img = ({ style, src, alt }) => (
  <View style={style}>
    <img src={src} alt={alt} height="100%" width="100%" />
  </View>
);

const profilePopupContentStyle = {
  backgroundColor: 'transparent',
  position: 'fixed',
  top: '0',
  left: '0',
  transform: 'translate(calc(50vw - 50%), calc(50vh - 50%))',
  width: 500
};

const ProfileButton = withPopover({
  hideOnScroll: false,
  contentStyle: profilePopupContentStyle,
  floatOptions: {}
})(Button);

const Sidebar = withCookies(({ QueryService, cookies }) => (
  <View isVertical style={sidebarStyle.sidebar}>
    <QueryService variables={{}} query={query}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;

        const usersSet = path('users.edges', data) ? data.users.edges : null;
        const user = usersSet && usersSet[0] ? usersSet[0].node : null;
        const userName = user && `${user.name} ${user.surname}`;
        const profilePic = user && user.profilePic ? user.profilePic : userPhoto;

        const companiesSet = path('companies.edges', data) ? data.companies.edges : null;
        const company = companiesSet && companiesSet[0] ? companiesSet[0].node : null;
        const companyName = company && company.name;
        return (
          <>
            <View style={sidebarStyle.userPhotoPart}>
              <Img src={profilePic} alt="UserPhoto" style={sidebarStyle.userPhoto} />
            </View>
            <View isVertical style={sidebarStyle.cardPart}>
              <UserName isVertical userName={userName} />
              <View style={sidebarStyle.cardLogoWrapper}>
                <Texts firstLine={companyName} secondLine="" isCardTexts />
                <Img src={cardPic} alt="LoyalMAN Card" style={sidebarStyle.cardLogo} />
              </View>
              <Texts firstLine={companyName} secondLine="" isCardTexts={false} />
            </View>
            <View isVertical style={sidebarStyle.linksPart}>
              {/*              <Divider style={sidebarStyle.divider} />
              <OrdersButton title="Objednávky jedla" />
              <Divider style={sidebarStyle.divider} /> */}
            </View>
            <View isVertical style={sidebarStyle.menuPart}>
              <ProfileButton
                icon="users"
                style={sidebarStyle.menuLink}
                label="Môj profil"
                accent="transparent"
                showTip={false}
                Content={({ onClose }) => (
                  <>
                    <MyProfileContent title="Môj profil" onClose={onClose} />
                  </>
                )}
              />
              <Button
                icon="fast-forward"
                style={sidebarStyle.menuLink}
                label="Odhlásiť sa"
                accent="transparent"
                onClick={() => logout(cookies)}
              />
            </View>
            <BottomPartLink to={homePath} label="www.loyalman.sk" />
          </>
        );
      }}
    </QueryService>
  </View>
));

export default compose(
  withQueryService,
  withIsWideInfo
)(Sidebar);
