import React from 'react';

const SvgVoucher = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M6.541 7.838v-.44c0-.19-.036-.35-.143-.481a.537.537 0 0 0-.447-.197c-.194 0-.36.066-.466.197a.747.747 0 0 0-.175.481v.44c0 .19.073.348.179.475.107.128.262.19.464.19.194 0 .344-.062.448-.19.106-.127.14-.286.14-.476zm3.375 3.586c-.198 0-.354.065-.46.195a.734.734 0 0 0-.164.476v.44c0 .182.064.34.186.473a.585.585 0 0 0 .447.2c.236 0 .392-.06.476-.178.084-.117.121-.283.121-.495v-.44a.72.72 0 0 0-.157-.476.548.548 0 0 0-.45-.195zM1.75 4v12h16.5V4H1.75zm2.33 3.838v-.44c0-.498.177-.91.497-1.237.32-.327.784-.49 1.374-.49.597 0 1.039.163 1.358.49.32.327.463.739.463 1.236v.441c0 .497-.139.908-.458 1.23-.32.324-.764.486-1.354.486-.594 0-1.064-.164-1.386-.488a1.682 1.682 0 0 1-.494-1.228zm1.297 5.226l4.059-6.527.896.453-4.058 6.526-.897-.452zm6.376-.528c0 .501-.153.913-.47 1.236-.318.323-.77.484-1.359.484-.593 0-1.05-.162-1.377-.487-.325-.325-.486-.736-.486-1.233v-.441c0-.494.161-.904.485-1.23.324-.328.78-.49 1.37-.49.597 0 1.05.161 1.367.486.317.325.47.736.47 1.234v.441zm4.56 1.72h-1.23v-2.11h1.23v2.11zm0-3.202h-1.23V8.946h1.23v2.108zm0-3.272h-1.23v-2.11h1.23v2.11z"
    />
  </svg>
);

export default SvgVoucher;
