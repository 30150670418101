import React from 'react';

const SvgCredit = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M11 8.6c.4.4 1 .6 1.8.6.4 0 .7 0 1.1-.1l-.1-.9c-.3.1-.6.1-.9.1-.4 0-.8-.1-1-.4-.2-.3-.4-.6-.4-1.1H13v-.6h-1.6v-.6H13V5h-1.6c0-.5.2-.8.4-1.1.2-.3.6-.4 1-.4.3 0 .6.1.9.1l.1-.9c-.4-.1-.7-.1-1.1-.1-.7 0-1.3.2-1.8.6-.4.4-.7 1-.7 1.7h-.6v.6h.8v.6h-.8v.6h.8c0 .9.2 1.5.6 1.9zm-3.3 3.2l3 .9v.1c-.1.2-.2.3-.5.4-1 .2-2.6.1-2.6.1-.3 0-.5.2-.5.4 0 .3.2.5.4.5.1 0 1.7.1 2.8-.1 1.1-.2 1.4-1.1 1.3-1.7 0-.2-.1-.3-.3-.4L8 11c-1.7-.5-3.5.4-3.5.5l-3.3 2c-.2.1-.3.4-.2.6.1.1.2.2.4.2.1 0 .2 0 .2-.1l3.3-2c.1-.1 1.5-.8 2.8-.4zm11.4-.8c-.3-.5-1.1-1.3-2.3-.7-1.2.5-3.7 1.7-3.7 1.7-.2.1-.3.4-.2.6.1.2.4.3.6.2 0 0 2.5-1.2 3.7-1.7.3-.2.6-.2.9 0l-6.2 4.6c-.4.3-1 .5-1.5.4l-5.5-.9c-.1 0-.3 0-.4.1L3 16.5c-.2.2-.2.5-.1.7.1.1.2.2.4.2.1 0 .2 0 .3-.1l1.2-1 5.3.9c.8.1 1.6-.1 2.2-.5l6.6-4.9c.3-.3.3-.6.2-.8z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCredit;
