// @flow
import Icon from 'components/Icon';
import Text from 'components/Text';
import View from 'components/View';
import invariant from 'invariant';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { compose, omit } from 'rambda';
import React from 'react';
import { AutoSizer, InfiniteLoader } from 'react-virtualized';
import { lifecycle, withStateHandlers } from 'recompose';
import { sortableContainer, sortableElement, arrayMove } from 'react-sortable-hoc';
import RowRenderer from './RowRenderer';

const withAutoSizer = BaseComponent => props => (
  <AutoSizer>
    {({ width, height }) => <BaseComponent width={width} height={height} {...props} />}
  </AutoSizer>
);

export const defaultTableEnhancer = withAutoSizer;

export const withInfiniteLoading = BaseTable => ({ loadMoreRows, ...props }) => (
  <InfiniteLoader
    threshold={100}
    rowCount={9999} // should be enough for all scenarios
    isRowLoaded={({ index }) => !!props.data[index]}
    loadMoreRows={loadMoreRows}>
    {({ onRowsRendered, registerChild }) => (
      <BaseTable {...props} onRowsRendered={onRowsRendered} passRef={registerChild} />
    )}
  </InfiniteLoader>
);

const selectMultipleRows = ({ selectedRowIndexes }) => ({ index }) => {
  const selectedRowIndexesNext = selectedRowIndexes[index]
    ? omit(`${index}`, selectedRowIndexes)
    : { ...selectedRowIndexes, [index]: true };

  return {
    selectedRowIndexes: selectedRowIndexesNext,
    selectedRowHandlersIndexes: {}
  };
};

const toggleRows = onChange => (state, { history, location, match, data }) => ({ index }) => {
  if (onChange) {
    onChange(index, { history, location, match, data });
  }

  return {
    // always have one row selected
    selectedRowIndexes: { [index]: true },
    selectedRowHandlersIndexes: {}
  };
};

const selectMultipleRowHandlers = onChange => (
  { selectedRowHandlersIndexes },
  { history, location, match, data }
) => ({ index }) => {
  const selectedRowIndexesNext = selectedRowHandlersIndexes[index]
    ? omit(`${index}`, selectedRowHandlersIndexes)
    : { ...selectedRowHandlersIndexes, [index]: true };

  if (onChange) {
    onChange(Object.keys(selectedRowIndexesNext), { history, location, match, data });
  }

  return {
    selectedRowIndexes: {},
    selectedRowHandlersIndexes: selectedRowIndexesNext
  };
};

const toggleAllRowHandlers = onChange => (
  { selectedRowHandlersIndexes },
  { history, location, match, data }
) => ({ columnData: { dataLength } }) => {
  const isSelectedAllRows = Object.values(selectedRowHandlersIndexes).length === dataLength;
  const selectedRowIndexesNext = isSelectedAllRows ? {} : { ...Array(dataLength).fill(true) };

  if (onChange) {
    onChange(Object.keys(selectedRowIndexesNext), { history, location, match, data });
  }

  return {
    selectedRowIndexes: {},
    selectedRowHandlersIndexes: selectedRowIndexesNext
  };
};

export const selectableRowsTableEnhancer = ({
  enableHandlers,
  enableRows, // enableRows xor enableTogglableRows
  enableTogglableRows,
  onRowChange,
  onHandlerChange
}) => {
  let onRowClick;
  if (enableRows) {
    onRowClick = selectMultipleRows;
  } else if (enableTogglableRows) {
    onRowClick = toggleRows(onRowChange);
  }

  const stateHandlers = {
    clearAll: () => () => ({
      selectedRowHandlersIndexes: {},
      selectedRowIndexes: {}
    })
  };

  if (onRowClick) {
    stateHandlers.onRowClick = onRowClick;
  }

  if (enableHandlers) {
    stateHandlers.onRowCellClickHandler = selectMultipleRowHandlers(onHandlerChange);
    stateHandlers.onHeaderCellClickHandler = toggleAllRowHandlers(onHandlerChange);
  }

  return compose(
    withStateHandlers(
      ({ preselectedRowIndexes = {} }) => ({
        selectedRowIndexes: preselectedRowIndexes,
        selectedRowHandlersIndexes: preselectedRowIndexes
      }),
      stateHandlers
    ),
    lifecycle({
      componentDidUpdate({
        sortBy: prevSortBy,
        sortDirection: prevSortDirection,
        search: prevSearch,
        preselectedRowIndexes: prevPreselectedRowIndexes
      }) {
        const { sortBy, sortDirection, search, preselectedRowIndexes, clearAll } = this.props;

        if (sortBy !== prevSortBy || prevSortDirection !== sortDirection || search !== prevSearch) {
          clearAll();
        } else if (
          preselectedRowIndexes &&
          prevPreselectedRowIndexes &&
          !Object.keys(preselectedRowIndexes).length &&
          Object.keys(prevPreselectedRowIndexes).length
        ) {
          // if selected row indexes was cleared
          clearAll();
        }
      }
    })
  );
};

export const sortableEnhancer = BaseTable => {
  const SortableTable = sortableContainer(BaseTable);

  return class extends React.Component {
    state = {
      isMoving: false
    };

    onSortStart = () => {
      this.setState({ isMoving: true });
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
      const { data, onDndSort } = this.props;

      invariant(onDndSort, 'missing onDndSort callback');

      onDndSort(() => {
        this.setState({ isMoving: false });
        return arrayMove(data, oldIndex, newIndex);
      });
    };

    render() {
      const { data, rowRenderer = RowRenderer, ...props } = this.props;
      const { isMoving } = this.state;

      if (!this.sortableRowRenderer) {
        const SortableItem = sortableElement(rowRenderer);

        this.sortableRowRenderer = hoistNonReactStatic(
          ({ index, ...restProps }) => <SortableItem index={index} _index={index} {...restProps} />,
          rowRenderer
        );
      }

      return (
        <SortableTable
          {...props}
          lockAxis="y"
          lockToContainerEdges
          onSortStart={this.onSortStart}
          onSortEnd={this.onSortEnd}
          useDragHandle
          gridClassName="sortable-table-bg"
          helperClass="sortable-table-moving"
          rowRenderer={this.sortableRowRenderer}
          isMoving={isMoving}
          data={data}
        />
      );
    }
  };
};

const emptyTableStyles = {
  box: (
    { accent },
    {
      components: {
        Table: {
          Body: { Row, AlternateRow }
        }
      }
    }
  ) => ({
    backgroundColor: accent === 'alternate' ? AlternateRow.backgroundColor : Row.backgroundColor,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  }),
  icon: (props, { components }) => ({
    ...components.Text,
    marginRight: 10
  })
};

export const emptyTableEnhancer = ({ message, icon, accent = 'alternate' }) => BaseTable => ({
  data,
  loading,
  ...props
}) => {
  if (!loading && (!data || !data.length)) {
    return (
      <View style={emptyTableStyles.box} accent={accent}>
        {icon ? <Icon name={icon} scale={4} style={emptyTableStyles.icon} /> : null}
        <Text weight="bold">{message}</Text>
      </View>
    );
  }

  return <BaseTable data={data} {...props} />;
};
